// prettier-ignore
export default {
    deepsearch: "DeepSearch",
    about_us: "About Us",
    app: "Analytics",
    start_app: "Start DeepSearch",
    home: "Home",
    go_homepage: "Go Homepage",
    footer: "All contents of DeepSearch are protected by copyright law",
    valuesearch_footer: "All contents of ValueSearch are protected by copyright law",
    term_of_use: "Term of Use",
    privacy_policy: "Privacy Policy",
    valuation_service: "Valuation Service",
    news_room: "News Room",
    my_subscription: "MY",
    interested_industry: "Interested Industry",
    /* Side Menu */
    home_menu: "Home",
    search_query_menu: "Query",
    fs_menu: "Financial Statement",
    template_menu: "Template",
    function_menu: "Function",
    welcome_to_addin: "Welcome to{br}{0}",
    switching_language: "Switching Languages...",
    /* Login */
    login_description: "Log in to download and analyze vast amounts of financial/business/economy/document data from Excel.",
    /* domain picker */
    domain_selection: "Select Domain",
    domain_selection_description: "This account is joined to more than one domain.{br}Please select a domain to log in to.",
    back_to_login: "Back to login",
    /* Home Page */
    what_we_can_do: "What do we provide?",
    first_usecase: "<LINK>Search</LINK> and viewed all the data in Deepsearch platform (financial, company overview, market, etc.)",
    second_usecase: "<LINK>Download</LINK> financial statements within the last 10 years from 2,500 listed and 300,000 unlisted companies.",
    third_usecase: "You can easily download and analyze various types of document data such as news, disclosure, securities reports, IR, and patents.",
    fourth_usecase: "Use <LINK>templates</LINK> such as pre-made financial analysis and industrial analysis to quickly perform the desired analysis.",
    /* Download Page */
    financial_statement: "Financial Statement",
    search_company: "Search{br}Company",
    select_option: "Select{br}Option",
    setting_isneeded: "setting is required",
    error_fetching_fs: "Error fetching FS",
    error_fetching_available_fs: "Error fetching FS type data",
    no_fs_report_error: "No FS under this company",
    /* Step 1 */
    search_result_count: `{count, plural,
        =0 {0 company}
        one {1 company}
        other {{count, number} companies}} found`,
    select_result_count: `{count, plural,
        =0 {0 company}
        one {1 company}
        other {{count, number} companies}} selected`,
    list_no_more: "This is the end of the list",
    search_text_suggestion_1: "･ Check if the word is spelled correctly.",
    search_text_suggestion_2: "･ Check the spacing, if there is more than one word.",
    search_text_suggestion_3: "･ Check if you entered Korean in English or English in Korean.",
    max_search_limit: "You have selected maximum search limit",
    /* Step 2 */
    fs_option_selection: "F/S Options",
    inquired_fs: "Inquired F/S",
    multiple_selection: "multiple selection",
    consolidated_standalone_setting: "Consolidated / Standalone",
    consolidated: "Consolidated",
    standalone: "Standalone",
    consolidated_or_standalone: "Consolidated|Standalone",
    if_not_standalone: "If not, S.A",
    yearly_quarterly_setting: "Quarter / Yearly",
    accumulated_incremental_setting: "Accumulated / Incremental",
    accumulated: "Accumulate",
    incremental: "Incremental",
    show_data_setting: "Display Empty Values",
    show: "Show",
    hide: "Hide",
    duration: "F/S Duration",
    from: "From",
    until: "Until",
    report_selection: "Report Selection",
    compact_fs_form: "Compact F/S Form",
    detail_fs_form: "Detailed F/S Form",
    nice_compact_fs_form: "Nice Compact F/S Form",
    nice_detail_fs_form: "Nice Detailed F/S Form",

    compact_fs_form_description: "Provide compact financial account information.",
    detail_fs_form_description: "Provide detailed financial account information.",
    nice_compact_fs_form_description: "Provide compact financial account information based on NICE Kisvalue.",
    nice_detail_fs_form_description: "Provide detailed financial account information based on NICE Kisvalue",

    financial_statement_excel: "BS",
    income_statement_excel: "IS",
    caseflow_statement_excel: "CF",
    report_type_header: "Report Type",
    account_code_header: "Code",
    depth_header: "LV",
    account_subject_header: "Account Subject",
    /* Function Page */
    function_page_title: "DS Function Manual",
    function_search: "Search Function",
    /* Function Download */
    download_begin: "Your Download will begin shortly",
    download_ready: "Your file is ready. You can close this tab now.",
    download_error: "Error Occur. Please close the tab and download again",
    /* Template Download */
    template_page_title: "Data Cloud Template", 
    search_template: "Search template",
    /* Query Page */
    query_page_title: "Company Data Download",
    select_data: "Select Data",
    search_data: "Search Data",
    financial_data: "Financial",
    market_data: "Market",
    overview_data: "Overview",
    option_selection: "Option Selection", 
    use_option: "Add",  
    /* Screener Page */
    screener_page_title: "Company Screener",
    screener_address: "Address",
    screener_address_placeholer: "keyword: (예: 서울 강남구)",
    screener_address_label: "에 위치한 기업을 검색합니다.",
    screener_document: "Document",
    screener_document_placeholer: "키워드 입력: (예: 이차전지)",
    screener_document_label: "포함된 기업을 검색합니다.",
    screener_market: "Market Data",
    screener_financial: "Financial Data",
    screener_other: "Other",
    add_screener: "Add Screener", 
    update_screener: "Update Screener",
    screener_empty_result: "There is no matching company. Please delete or modify the conditions.",    
    /**
     * DATA STRING
     */
    score: "Score",
    date: "Date",
    prev_date: "Prev Date",
    entity_name: "Company Name",
    pbr: "PBR",
    per: "PER",
    no_result: "No result.",
    content: "content",
    keyword: "keyword",
    daterange: "period",
    all: "All",
    select: "select",
    date_from: "start date",
    date_to: "end date",
    search: "Search",
    news_section: "Section",
    news_section_full: "News Section",
    news_publisher: "the press",
    research_section: "report section",
    research_publisher: "research company",
    company_search: "Company Search",
    company_analysis: "Company Analysis",
    people_analysis: "People Analysis",
    thematic_analysis: "Thematic Analysis",
    document_search: "Document Search",
    bond_search: "Bond Search",
    sentiment_analysis: "Sentiment Analysis",
    event_study: "Event Study",
    event_study_re: "Event Study Re.",
    mind_map: "Mind Map",
    industry_analysis: "Industry Analysis",
    economic_indicator: "Economic Indicator",
    company_discovery: "Company Discovery",
    dashboard: "Dashboard",
    newsroom: "Newsroom",
    excel_add_in : "DeepSearch Excel Add-in",
    search_home: "Search Home",
    related_keyword: "related keyword",
    related_entities: "Related Entities",
    related_keyword_category: "related keyword category",
    related_keyword_range: "related keyword period",
    addtional_condition: "search requirement",
    time_elapsed: "time elapsed",
    company_summary: "Company summary",
    stock_change_rate: "Stock price change",
    volume: "volume",
    default_excel_name: "search results",
    default_excel_button_name: "search results download",
    download: "Download",
    download_pdf: "Download pdf",
    download_pdf_completed: "Download pdf completed",
    download_completed: "Download completed",
    trading_halted : "Trading halted",
    positive: "Positive",
    negative: "Negative",
    neutral: "Neutral",
    save: "Save",
    load: "Load",
    is_alive: "Status",
    normal: "Normal",
    stoppage: "Stoppage",
    business_closed: "Business closed",
    clear_all: "Clear All",
    previous: "Previous",
    no_stocks: "No. Stocks",
    no_shares: "No. Shares",
    total_marcaps: "Total MarCaps",
    average_per: "Average PER",
    firm_count: "Firm Count",
    average_change: "Average Change",

    news_company: "news company",
    disclosure_company: "public company",
    research_company: "reported company",
    ir_company: "IR company",
    alpha_company: "alpha company",
    investment_info: "Investment Information",
    report_problem: "Feedback on Quality",
    monitoring: "Monitoring",
    target_discovery: "Discovery",
    market_research: "Research",

    query: "Query",
    notes: "Notes",
    text: "Text",
    value: "Value",

    //기업
    company_status: "WatchList",
    event: "Event",
    event_analysis: "Event Analysis",
    company_event_analysis: "Stock price impact analysis",
    issue_stock: "Company Watchlist",
    source: "Sources",

    // 기업 data-coverage
    coverage_type: "Type",
    coverage_accumulated: "Accumulation",

    /**
     * TIME STRING
     */
    recent_1m: "1M",
    recent_3m: "3M",
    recent_1y: "1Y",
    recent_2y: "2Y",
    recent_3y: "3Y",
    recent_5y: "5Y",
    today: "Today",
    yesterday: "Yesterday",
    this_week: "This week",
    last_week: "Last week",
    this_month: "This month",
    last_month: "Last month",
    this_year: "YTD",
    last_year: "Last year",
    next_year: "Next year",
    three_months_ago: "3 Months ago",
    two_weeks_ago: "2 Weeks ago",
    one_week_ago: "1 Week ago",
    one_month_ago: "1 Month ago",

    /**
     * CATEGORY
     */
    industry: "Industry",
    company: "Company",
    bond: "Bond",
    financial: "Financial",
    market: "Market",
    command: "Command",
    document: "Document",
    example: "Example",
    favorites: "Favorites",
    history: "Recent Searches",
    recommend_search: "Recommend Searches",
    thematic: "Theme",
    publisher: "Publisher",
    bank_of_korea: "Bank of Korea",

    answer_tab: "Answer",
    discovery_tab: "Related Company",
    document_search_tab: "Document",
    company_search_tab: "Company",
    company_related_companies_tab: "{0} Related Companies",
    company_biz_area_search_tab: "Company(Business Area)",
    company_industry_search_tab: "Company(Industry)",
    company_biz_name_search_tab: "Company(Business name)",
    company_addr_search_tab: "Company(Address)",
    industry_search_tab: "Industry",
    people_search: "People Search",
    people_list: "People List",

    /**
     * MENU STRING
     */
    condition_init: "Reset",
    condition_refresh: "Reload",
    related_company_to_event: "Related company analyzing",
    setting: "Setting",
    related: "Related",
    related_document: "Related Documents",
    user: "User",
    organization: "Organization",
    admin: "Admin",
    company_search_more_info: "Search more information",
    company_founded: "Company founded",
    company_member: "Employee",
    related_company: "Related companies",
    related_summary_company: "Related Overview and Status",
    related_industry: "Related industry",
    affiliation_company: "Affiliation",
    select_from_saved_condition: "Select from saved condition",
    recommend_condition: "Recommended Conditions",
    recommend_event: "Recommended Events",
    user_search_condition: "User searching condition",
    save_condition: "[condition saved]",
    finish: "Finish",
    cancel: "Cancel",
    kospi_index: "KOSPI Index",
    kosdaq_index: "KOSDAQ Index",
    close_list: "[close list]",
    open_list: "[open list]",
    loading: "[loading]",
    expand: "Expand",
    collapse: "Collapse",
    symbol: "Symbol",
    confirm: "Confirm",
    next_help: "Next help",
    help: "Help",
    market_analysis: "Market Analysis",
    industry_company: "Industry Company",
    industry_name: "Industry Name",
    search_condition: "Current Condition",
    delete_selection: "Delete selections",
    delete: "Delete",
    delete2: "Delete",
    rename: "Rename",
    edit: "Edit",
    add_condition: "Condition added",
    general_condition: "General condition",
    subject: "Subject",
    search_keyword: "Search keyword",
    search_periods: "Search periods",
    document_information: "Document",
    market_information: "Market",
    employee_count: "Number of employees",
    male: "Male",
    female: "Female",
    age: "Age",
    age_of_ceo: "Age of CEO",
    profile: "Profile",
    company_periods: "company founded",
    company_size: "company size",
    company_listed: "company trading status",
    signup: "Sign up",
    deepsearch_login: "Login",
    login: "Sign in",
    logout: "Sign out",
    user_withdrawal: "Withdrawal",
    video_tip: "VIDEO TIP",
    necessary: "Necessary",
    view: "View",
    view_video: "View Video",
    ceo: "CEO",
    date_founded: "Company founded",
    birthdate: "Birthdate",
    company_type: "Company type",
    exchange: "Exchange",
    business_area: "Business area",
    industry_id: "Industry classification",
    recently_news: "Recently News",
    news: "News",
    news_1: "News 1",
    news_2: "News 2",
    news_3: "News 3",
    disclosure: "Disclosure",
    performance_disclosure: "Performance Disclosure",
    research: "Research",
    ir: "IR",
    alpha: "Alpha",
    patent: "Patent",
    change_rate: "Change rate",
    rank: "rank",
    research_name: "research company name",
    market_brief: "Daily Brief by research company : ",
    title: "Title",
    summary: "Summary",
    highlight: "Highlight",
    content_url: "Content URL",
    ratio: "ratio",
    detail_volume: "volume (current period/ prior period/ change)",
    more_document_info: "more related information",
    more_topic: "[More topic]",
    topic: "Topic",
    daily_event_analysis: "Daily event analysis",
    more_info: "More",
    more_expandation: "Expand Network",
    add_more_info: "+ Add with",
    show_only_unlisted_company: "List only unlisted companies",
    show_related_documents: "Show",
    change_ratio_top_10: "gainers (top 10)",
    change_ratio_bottom_10: "losers (top 10)",
    user_search: "Additional Analysis",
    add_search_condition: "[Add search condition]",
    close_search_condition: "[Close search condition]",
    close: "Close",
    company_information: "Company Information",
    index_company: "index company",
    company_overview: "Company Overview",
    financial_information: "Financial",
    price_information: "Market",
    related_subject: "Related Subject",
    suggested_keyword: "suggested keyword",
    result: "Result",
    reason: "Reason",
    input_interpretation: "Input Interpretation",
    chart: "Chart",
    multi_compare_chart: "chart",
    single_compare_chart: "Chart",
    trend: "Trend",
    news_trend: "News Trend",
    trending_topics: "Trending Topics",
    recent_topic: "Recent Topic",
    author: "Author",
    portfolio_analysis: "Portfolio Analysis",
    start_analysis: "Start analysis",
    analysis: "Analysis",
    retry: "Retry",
    related_news: "Related News",
    more: "More",
    pending: "Pending",
    using: "Using",
    choose: "Choose",
    free: "Free",
    suspended: "Suspended",
    canceled: "Canceled",
    ended: "Ended",
    state: "State",
    count: "Count",
    modify: "Modify",
    add: "Add",
    added: "Added",
    overwrite: "Overwrite",
    information: "Information",
    version: "Version",
    select_all: "Select All",
    total: "Total",
    sum: "Sum",
    growth_rate: "Growth Rate",
    average: "Average",
    max: "Max",
    min: "Min",
    unreported: "Unreported",
    company_position: "Position",
    career_experiences: "Career",
    page: "Page",
    rows: "rows",
    buy: "Buy",
    strong_buy: "Strong Buy",
    sell: "Sell",
    strong_sell: "Strong Sell",
    very_high: "Very high",
    high: "High",
    usually: "Usually",
    low: "Low",
    very_low: "Very low",
    quarterly: "Quarterly",
    yearly: "Yearly",
    company_share: "Company share",
    consensus_before: "Prev Consensus",
    consensus_after: "Latest Consensus",
    compared_to_month: `{month, plural,
        =0 {-}
        =1 {Compared to last month}
        other {Compared to {month} months ago}}`,
    three_months: "3 months",
    other: "Other",
    registration_date: "Registration date",
    info: "Information",

    //뉴스 카테고리
    category: "Category",
    economy: "Economy",
    tech: "Technology",
    politics: "Politics",
    society: "Society",
    culture: "Culture",
    world: "World",
    entertainment: "Entertainment",
    sports: "Sports",
    opinion: "Opinion",
    top: "Top",

    //MARKET DATA
    market_cap: "Market Cap",
    yesterday_volume: "Previous day volume",

    //재무정보
    financial_evaluation: "Financial evaluation",
    financial_statements: "Financial Statements",
    growth: "Growth",
    stability: "Stability",
    dividend: "Dividend",
    profitability: "Profitability",
    valuation: "Valuation",
    income_statement: "Comprehensive Income Statement",
    cashflow_statement: "Cash flow statement",
    consolidated_financal: "Consolidated",
    individual_financial: "Individual",
    sales: "Sales",
    revenues: "Revenues",
    revenues_growth: "Revenue Growth",
    operating_income: "Operating income",
    operating_income_ratio: "Operating income Ratio",
    net_income: "Net income",
    assets: "Assets",
    non_current_assets: "Non-current assets",
    tangible_assets: "Tangible Assets",
    investment_property: "Investment property",
    intangible_assets: "Intangible Assets",
    biological_assets: "Biological assets",
    long_term_investment_assets: "Long-term Investment Assets",
    trade_and_other_receivables: "Trade and other receivables",
    deferred_tax_assts: "Deferred Tax Assets",
    other_financial_assts: "Other financial assets",
    other_non_financial_assts: "Other non-financial assets",
    continuing_involvement_assts: "continuing involvement assets",

    current_assts: "Current assets",
    inventories_assts: "Inventories",
    short_term_investment_assets: "Short-term Investment Assets",
    current_tax_assts: "Current Tax Assets",
    rental_housing_assets: "Rental Housing Assets",
    cash_and_cash_equivalents: "Cash and cash equivalents",

    other_financial_industry_assets: "Other financial assets",
    consolidation_adjustments: "Consolidation adjustments",

    total_assets: "Total Assets",
    cash_equivalents: "Cash and Equivalents",
    liabilities: "Liabilities",
    liabilities_ratio: "Liabilities Ratio",
    leverage: "Leverage",
    equity: "Equity",
    majority_interest: "Majority interest",
    issued_capital: "Issued capital",
    retained_earnings: "Retained earnings",
    other_components_of_equity: "Other components of equity",
    capital_related_to_assets_held_for_sale: "Capital related to assets held for sale",
    non_controlling_interest: "Non-controlling interest",

    total_equity: "Total Equity",
    ordinary_profit: "Ordinary Profit",
    holding_ratio: "Holding Ratio",
    ownership_percentage: "Ownershop Percentage",
    prev_holding_ratio: "Previous Holding Ratio",
    changed_holding_ratio: "Changed Holding Ratio",
    total_holding_ratio: "Total Holding Ratio",
    real_holding_ratio: "Real Holding Ratio",
    total_debt: "Total debt",
    short_term_borrowings: "Short-term borrowings",
    long_term_borrowings: "Long-term borrowings",
    total_borrowings: "Total borrowings",
    forecasted_sales: "Sales",
    forecasted_operating_income: "Operating income",
    forecasted_net_income: "Net income",

    product_name: "Product name",
    product_sales_status: "Sales Status by Sector",
    /**
     * 기업 종류
     */
    corporation_company: "Corporation",
    join_venture_company: "Joint venture",
    partnership_company: "Partnership",
    limited_corporation_company: "Limited corporation",
    copartnership_company: "Copartnership",
    goverment_investment_organization: "Government institutional investor",
    individual: "Individual",
    school: "School",
    hospital: "Hospital",
    association: "Association",
    limited_liability_company: "Limited liability company",
    cooperative_society: "Cooperative society",
    join_stock_association: "Joint stock association",
    etc: "Etc",

    /**
     * 기업 크기
     */
    major_company: "Large company",
    small_company: "Small company",
    middle_company: "Middle company",

    /**
     * 기업 시장
     */
    kospi: "KOSPI",
    kosdaq: "KOSDAQ",
    konex: "KONEX",
    unlisted: "Unlisted",
    date_listed: "Listing date",

    /**
     * COMPANY AUDIT TYPE
     */
    is_external_audit: "External audit",
    external_audit: "Audit",
    non_external_audit: "Non-Audit",

    /**
     * 리포트
     */
    report_all: "Report : All",
    report_market: "Report : Market",
    report_strategy: "Report : Strategy",
    report_company: "Report : Company",
    report_industry: "Report : Industry",
    report_economy: "Report : Economy",
    report_bond: "Report : Bond",

    /**
     * 뉴스
     */
    news_all: "News : All",
    news_economy: "News : Economy",
    news_tech: "News : Technology",
    news_politics: "News : Politics",
    news_society: "News : Society",
    news_culture: "News : Culture",
    news_world: "News : World",
    news_entertainment: "News : Entertainment",
    news_opinion: "News : Opinion",

    /**
     * 변수 선택 마법사
     */
    data_select_wizard: "Data-Select Wizard",
    data_select_title_stage1: "Stage 1. Choose data type",
    data_select_title_stage2: "Stage 2. Choose variables",
    data_select_title_stage3: "Stage 3. Choose entities",
    data_select_title_stage4: "Stage 4. Choose date range",
    data_select_title_stage5: "Stage 5. Confirm the generated query",

    data_select_option_search_unlisted_companies: "Include unlisted companies",
    data_select_option_search_instruction:
        "Write a part of company name in the input field above and hit enter key.",

    data_select_datatype_market_index: "Market index",
    data_select_datatype_stock_prices: "Stock prices",
    data_select_datatype_firm_fundamentals: "Firm fundamentals",
    data_select_datatype_bank_of_korea: "Bank of Korea",

    data_select_button_next: "Next",
    data_select_button_finish: "Finish",
    data_select_button_previous: "Previous",
    data_select_filter_by_name: "filter by name",

    /**
     * 기업 검색
     */
    company_search_search_conditions: "Search Conditions",
    company_search_filters: "Filters",
    company_search_reset: "Reset",

    company_search_company_name: "Company Name",
    company_search_address: "Address",
    company_search_business_area: "Business Area",
    company_search_related_keyword: "Related Keyword",
    company_search_company_rid: "Company ID",
    company_search_business_rid: "Business ID",
    company_search_custom_option: "Custom Search Option",
    company_search_industry: "Industry",
    company_search_ceo_age: "CEO Age",
    company_search_employees: "Number of Employees",
    company_search_founded_date: "Founded Date",
    company_search_marcap: "Market Capitalization",
    company_search_listing_status: "Listing Status",
    company_search_listing_status_listed: "Listed Firms",
    company_search_listing_status_unlisted: "Unlisted Firms",
    company_search_listing_status_external_audit: "Non-Audit Firms",

    company_search_map: "Map",
    company_search_company_list: "Company List",

    type_industry_name: "Type industry name",

    total_matched_firm_count_message:
        "Total {1} firms are found including the non-audited firms without financial-statement information. Please click the More button to see the entire list.",

    /**
     * 채권 검색
     */
    select_bond_issuer: "Select Bond Issuer",
    bond_classification_list: "Bond Classification Code List",
    bond_issuer_list: "Bond Issuer List",
    search_bond_issuer_by_name: "Search Bond Issuer By Name",
    results_are_not_found: "Results are not found.",
    selected_bond_issuer_list: "Selected Bond Issuer List",
    related_company_info: "Related Company Financial Info",
    bond_issuance_history: "Debt Issuance Size History",
    bond_repayment_schedule: "Bond Repayment Schedule",
    bond_yield_curve: "Bond Yield Curve",
    bond_list: "Bond List",
    compare_yield_curves: "Compare yield curves",
    bond_yield_time_series_graph: "Yield Time-Series Graph",
    credit_spreads: "Credit Spreads",
    credit_spreads_time_series: "Credit Spreads Time-Series",
    principal_component_analysis: "Principal Component Analysis",
    maturity_in_years: "Maturity (Years)",

    /**
     * 이벤트 분석 Remastered
     */
    event_study_stage1_select_dates: "Event Study: Step 1. Define an Event and Select Dates",
    event_study_stage2_stock_analysis:
        "Event Study: Step 2. Stock Analysis and Portfolio Formation",
    event_study_stage3_stock_portfolio_analysis:
        "Event Study: Step 3. Stock Portfolio Analysis Results",
    event_study_stage2_bond_analysis: "Event Study: Step 2.  Bond Analysis",
    choose_next_step: "Choose the Next Step",
    stock_analysis: "Stock Analysis",
    bond_analysis: "Bond Analysis",
    stock_portfolio_analysis: "Stock Portfolio Analysis",
    save_selected_stock_portfolio: "Save Selected Stock Portfolio",

    load_previous_event_date_definitions: "Load Previous Event-Date Definitions",
    save_selected_event_dates: "Save the selected event dates",
    overwrite_previous_record: "Overwrite a previous record",
    save_as_new_record: "Save as a new record",
    event_dates_are_successfully_saved: "Event dates are successfully saved.",
    event_dates_are_successfully_loaded: "Event dates are successfully loaded.",

    // 이벤트 분석 - 날짜 선택
    how_to_select_event_dates: "How to Select Event Dates",
    how_to_select_event_dates_news_trends: "News Trends",
    how_to_select_event_dates_market_indicator: "Market Indicator",
    how_to_select_event_dates_curated_events: "Curated Events",
    how_to_select_event_dates_manual_select: "Manual Select",

    news_trend_search_keywords: "News Trend Search Keywords",
    news_trend_chart: "News Trend Chart",
    news_list_on_trend_dates: "News List on Trend Dates",

    market_indicator_list: "Market Indicator List",
    exchange_rate: "Exchange Rate",
    interest_rate: "Interest Rate",
    stock_market: "Stock Market",
    market_indicator_chart: "Market Indicator Chart",
    indicator_related_news_list: "Indicator-Related News List",
    news_search_keyword: "News Search Keyword",
    curated_event_list_view: "Curated Event List",
    event_date_manual_select: "Manually Select Event Dates",

    selected_event_date_list: "Selected Event Date List",
    stock_market_returns_on_event_dates: "Stock Market Returns on Event Dates",
    stock_market_index_on_surrounding_days: "Stock Market Index on Surrounding Days",
    exchange_rate_changes_on_event_dates: "Exhcnage Rate Changes on Event Dates",
    exchange_rate_trends_in_surrounding_days: "Exchange Rate Trends in Surrounding Days",
    govt_bond_yield_changes_on_event_dates: "Bond Yield Changes on Event Dates",
    govt_bond_yields_on_surrounding_days: "Bond Yields On Surrounding Days",
    major_issues_on_event_dates: "Major Issues On Event Dates",

    // 이벤트 분석 - 주식 분석
    select_stock_analysis_basis: "Select Stock Analysis Basis",
    stock_analysis_basis_related_firms: "Related Firms",
    stock_analysis_basis_industry: "Industry",
    stock_analysis_basis_indicator_correlation: "Correlation with Indicators",
    stock_analysis_basis_manual_select: "Manual Select",
    manual_select: "Manual Select",

    selected_stock_list: "Selected Stock List",
    stock_price_history: "Stock Price History",
    stock_price_around_event_dates: "Stock Price around Event Dates",

    related_firm_list: "Related Firm List",
    search_keywords_for_related_firms: "Search Keywords for Related Firms",
    related_firm_reasoning: "Reasoning behind Firm Relatedness",
    reasoning_source: "Source of Supporting Data",

    select_industry_category_level: "Select Industry Category Level",
    industry_category_level_10k_large: "Korea 10th Standard: Large",
    industry_category_level_10k_medium: "Korea 10th Standard: Medium",
    industry_category_level_10k_small: "Korea 10th Standard: Small",

    industry_list_and_summary: "Industry List and Summary Info",
    latest_market_info: "Latest Market Info",
    event_day_returns: "Event-Day Returns",
    cumulative_returns: "Cumulative Returns",
    industry_event_day_returns: "Industry Event-Day Returns",
    industry_cumulative_returns: "Industry Cumulative Returns",
    industry_aggregate_financial_info: "Industry Aggregate Financial Information",
    both_listed_and_unlisted: "Both Listed and Unlisted Firms",
    listed_firms_only: "Listed Firms Only",
    listed_firms_target: "Listed Firms Target",
    not_available_for_large_cateogy:
        "This component is available only for middle or small category levels.",
    industry_stock_list: "Industry Stock List",

    correlation_reference_indicator: "Reference Indicator for Correlation",
    stock_return_correlation: "Stock Return Correlation",
    correlation: "Correlation",
    securities_type: "Securities Type",
    marcap_rank: "Market Capitalization Rank",

    // 이벤트 분석 - 채권 분석
    select_bond_analysis_basis: "Select Bond Analysis Basis",

    bond_analysis_basis_issuer: "Bond Issuer",
    bond_analysis_basis_classification: "Bond Classification",
    bond_analysis_basis_industry: "Industry",

    bond_yield_changes_on_event_dates: "Bond Yield Changes on Event Dates",
    bond_type: "Bond Type",
    bond_count: "Bond Count",
    then_bond_count: "Then Bond Count",
    issuer_name: "Issuer Name",
    total_issue_size: "Total Issue Size",
    yield_change_bps: "Yield Change (bps)",
    average_ytm: "Average YTM",
    average_maturity: "Average Maturity",
    average_line: "Average Line",
    unlisted_estimate: "Unlisted estimate",

    outstanding_bonds_are_no_longer_found: "Outstanding Bonds Are No Longer Found",
    yield_curve_on_event_dates: "Yield Curve on Event Dates",
    yield_curve_change_on_event_dates: "Yield Curve Change On Event Dates",
    cumulative_yield_changes_around_evnet_dates: "Cumulative Yield Changes Around Evnet Dates",
    major_classification: "Major Classification",
    selected_bond_class_list: "Selected Bond Class List",
    bond_list_per_industry: "Bond List Per Industry",
    industry_code: "Industry Code",
    selected_bond_industry_list: "Selected Industry List",
    bond_issuer_list_within_class: "Bond Issuer List within Class",
    bond_issuer_list_within_industry: "Bond Issuer List within Industry",
    yield_difference_relative_to_evnet_dates: "Yield Difference Relative To Evnet Dates (bps)",

    /**
     * 산업 분석 Remastered
     */
    industry_identification_standard: "Industry Identification Standard",
    deepsearch_industry: "DeepSearch Industry",
    korean_standard_industry: "Korean Standard Industry",
    conglomerate: "Conglomerate",
    conglomerate_list: "Conglomerate List",
    conglomerate_name: "Conglomerate Name",
    owner: "Owner",
    chairman: "Chairman",
    member_count: "Member Count",
    primary_bank: "Primary Bank",
    conglomerate_or_chairman_name: "Conglomerate Or Chairman Name",
    conglomerate_details: "Conglomerate Details",
    mission: "Mission",
    main_business: "Main Business",
    main_company: "Main Company",
    user_query: "User Query",
    theme_list: "Theme List",
    firm_manual_select: "Firm Manual Select",
    manually_selected_firm_list: "Manually Selected Firm List",
    select_industry: "Select Industry",
    average_annual_growth_rates_over_past_years: "Average Annual Growth Rates over Past Years",
    historical_sentiment_chart: "Historical Sentiment Chart",
    sentiment_trend: "Sentiment Trend",
    sentiment_score: "Sentiment Score",
    news_count: "News Count",
    search_companies_by_name: "Search Companies By Name",
    primary_bank_market_share: "Market Share of Primary Banks",
    revenue_sum: "Sum of Revenues",
    asset_sum: "Sum of Assets",
    liability_sum: "Sum of Liabilities",
    all_industry: "All Industry",
    selected_firms_only: "Selected Firms Only",
    relevant_economic_indicators: "Relevant Economic Indicators",
    top_individual_compensations: "Top Individual Compensations",
    average_employee_compensations: "Average Employee Compensations",

    industry_consensus: "Industry Consensus by Analysts",
    field: "Field",
    statistic: "Statistic",
    mean: "Mean",
    median: "Median",
    firms_with_analyst_forecasts_are_not_found:
        "Firms with analyst forecasts are not found.",
    listed_firms_didnt_exist_in_the_given_sample_year:
        "Listed firms didn't exist in the given sample year",

    /**
     * 특허 분석
     */
    patent_analysis: "Patent analysis",
    patent_select_view_type: "Select analysis option",
    patent_view_type_keywords: "Keyword",

    /**
     * 뉴스룸
     */
    news_search: "News Search",
    date_range: "Date Range",
    date_range_mode_trending: "Trending",
    date_range_mode_recent: "Recent",
    date_range_mode_one_week: "One Week",
    date_range_mode_one_month: "One Month",
    date_range_mode_one_year: "One Year",
    date_range_mode_manual: "Manual",

    manual_date_range_modal_title: "Manual Date Range",

    type_and_enter: "Type & Enter",
    includeAnd: "Include (and)",
    includeOr: "Include (or)",
    exclude: "Exclude",

    major_topics: "Major Topics",
    all_news_articles: "All News Articles",

    sort_by_relevance: "Relevance",
    sort_by_score: "Issue Score",
    sort_by_date: "Date",

    sentiment: "Sentiment",

    none: "None",
    appointment: "Appointment",
    obituary: "Obituary",

    topic_list: "Topic List",
    news_list: "News List",
    doc_count: "Document Count",
    last_update: "Last update",
    seconds_ago: " seconds ago",
    minutes_ago: " minutes ago",
    hours_ago: " hours ago",

    ml_news_analysis: "News Analysis by Machine Learning",
    show_as_list: "Show as List",
    people: "People",
    regions: "Regions",
    brands: "Brands",
    organizations: "Organizations",

    selected_date_range: "Selected Date Range",
    search_selected_period: "Search for the Selected Period",

    add_to_favorites: "Add to Favorites",
    save_newsroom_favorites: "Add to Newsroom Favorites",
    save_as_new_setting: "Save as a new setting",
    overwrite_existing_setting: "Overwrite an existing setting",
    favorites_delete_message: "Would you like to delete the favorites setting?",
    added_to_favorites: "Added to favorites",
    deleted_from_favorites: "Deleted from favorites",

    matched_topics_are_not_found:
        "Matched results are not found. Please expand the sample period and try again.",

    launch_newsroom_mobile_app: "Launch Newsroom Mobile App",


    /**
     * 경제 지표 Economic Indicator
     */
    search_keyword_input: "Search Keyword Input",
    popular_search_keywords: "Popular Search Keywords",
    must_have: "Must Have",
    open_in_new_tab: "Open in a new tab",
    open_in_new_window: "Open in a new window",

    economic_indicator_source: "Source",
    country: "Country",
    capital_city: "Capital City",
    data_frequency: "Data Frequency",
    popularity: "Popularity",
    survey_institution: "Survey Institution",

    kosis: "Statistics Korea",
    fred_economic_data: "FRED Economic Data",
    imf: "IMF",
    oecd: "OECD",
    world_bank: "World Bank",
    deepsearch_own: "DeepSearch Own-Collected Data",

    time_series_data: "Time-Series Data",
    cross_section_data: "Cross-Section Data",
    sample_date: "Sample Date",
    data_value: "Data Value",
    data_list_of_the_same_category: "Data List of Same Category",
    select_all_in_the_same_category: "Select all in the same category",
    depth_per_category: "Depth Per Category",
    depth_x_format: "Level {depth}",
    related_news_articles: "Related News Articles",
    data_categories_are_not_selected: "Data categories are not selected.",

    search_by_name: "Search by name",
    select_within_category: "Select within category",

    input_data_series_name_to_search_for: "Type the data series name to search for and press enter",
    results_are_not_found_try_again: "Results are not found. Try again with different search keywords.",
    click_below_to_see_data_table: "Click below to see data table.",
    need_subscription_to_download_data: "Subscription is needed to download data.",

    count_per_source: "Data Series Count Per Source",
    reference_notes: "Reference Notes",

    chart_edit_tools: "Chart Edit Tools",
    data_series_list: "Data Series List",
    search_economic_indicator: "Search Economic Indicator",
    edit_chart_style: "Edit Chart Style",
    add_annotations: "Add Annotations",

    series_name: "Series Name",
    chart_legend: "Chart Legend",
    add_a_new_annotation: "Add a New Annotation",
    annotation_list: "Annotation List",

    stat_name: "Stat Name",
    difference_relative_to: "Difference relative to",
    growth_rate_relative_to: "Growth Rate relative to",
    relative_to_last_period: "Last Period",
    relative_to_last_month: "Last Month (m/m)",
    relative_to_last_quarter: "Last Quarter (q/q)",
    relative_to_last_year: "Last Year (y/y)",


    /**
     * 대시보드 추가 버튼 Save To Dashboard
     */
    save_to_dashboard: "Save to Dashboard",
    add_to_dashboard: "Add to Dashboard",
    added_to_dashboard: "Added to Dashboard.",
    remove_from_dashboard: "Remove from Dashboard",
    removed_from_dashboard: "Removed from Dashboard",


    /**
     * 문서 검색
     */
    document_search_title: "Title",
    document_search_has_the_words: "Has the words",
    document_search_doesnt_have: "Doesn't have",

    type_keywords_to_be_inlcuded_in_title: "Type the keywords to be inlcuded in title",
    type_keywords_to_be_inlcuded_in_contents: "Type the keywords to be inlcuded in contents",
    type_keywords_to_be_excluded_from_contents: "Type the keywords to be excluded from contents",

    document_search_trending_topics: "Trending Topics",
    document_search_trending_query: "Query",

    document_search_filters: "Filters",
    document_search_filter_categories: "Categories",
    document_search_filter_sections: "Sections",
    document_search_filter_date_range: "Date Range",
    document_search_filter_date_range_from: "From",
    document_search_filter_date_range_to: "To",
    document_search_filter_sentiments: "Sentiments",
    document_search_filter_companies: "Companies",
    industries: "Industries",
    document_search_filter_pubishers: "Publishers",

    document_search_date_range_ten_year_limit:
        "The date range for news search is limited to ten years.",

    document_search_popup_filter_clear: "Clear selections",
    document_search_popup_section_filter:
        "Section filter is available when only one of either News or Research is selected in the Category.",
    document_search_popup_aggregation_filter:
        "This filter is not available for the selected categories or sections.",

    /**
     * 검색 문서 종류
     */
    search_document_all: "Search document : All",
    search_document_news: "Search document : News",
    search_document_disclosure: "Search document : Disclosure",
    search_document_research: "Search document : Research",
    search_document_ir: "Search document : IR",
    search_document_alpha: "Search document : Alpha",

    /**
     * 검색 문서 기간
     */
    document_period_year: "Search Period : Recent 1 Year",
    document_period_2_year: "Search Period : Recent 2 Years",
    document_period_this_year: "Search Period : This Year",
    document_period_last_year: "Search Period : Last Year",
    document_period_before_last_year: "Search Period : The Year Before",
    document_period_user_setting: "Search Period : User Setting",

    /**
     * 이슈 종목 관련
     */
    volume_increase: "Volume Up",
    volume_decrease: "Volume Down",
    price_increase: "Price Up",
    price_decrease: "Price Down",
    total_increase: "Trading Value Up",
    total_decrease: "Trading Value Down",
    top_price_increase: "Maximum Price Up",
    top_price_decrease: "Maximum Price Down",
    top_volume_increase: "Top Stock Volume",
    top_market_cap_in_kospi: "Top Market Cap - KOSPI",
    top_market_cap_in_kosdaq: "Top Market Cap - KOSDAQ",

    change_in_shareholders_high: "Change in Shareholders High",
    government_shareholders_top_share: "Government Shareholders Top",
    government_shareholders_top_ratio_on_marketcap: "Government Shareholders Top ratio",
    government_shareholders_increase: "Government Shareholders Increase",
    government_shareholders_decrease: "Government Shareholders De뎀crease",
    government_shareholders_top_sales: "Government Shareholders Top Sales",
    government_non_shareholders_top_sales: "Government Non-Shareholders Top Sales",
    government_shareholders_top_volume: "Government Shareholders Top Volume",
    kdb_shareholders: "Korea Development Bank Shareholders",

    company_with_better_consensus: "Consensus Up",
    company_with_bad_consensus: "Consensus Down",
    company_target_price_better_consensus: "Target price Up",
    company_target_price_bad_consensus: "Target price Down",

    company_market_cap_large: "Market Cap (Over 1T)",
    company_market_cap_medium: "Market Cap (300B ~ 1T)",
    company_market_cap_small: "Market Cap (Below 300B)",

    shareholders_name: "Shareholders",
    company_name: "Company Name",
    org_name: "Organization",
    team_name: "Team Name",
    price: "price",
    powered_by: "powered by",

    /**
     * SOURCE STRING
     */
    market_source: "market source : Koscom, Thomson Reuters",
    company_source: "company source : NICE information service",
    document_source:
        "document source : the press(news), Financial Supervisory Service - DART (disclosure), research companies(analyst report), IR, SNEK Alpha ",

    /**
     * MARKET STATUS
     */
    market_pre: "Previous closed",
    market_open: "Open",
    market_closed: "Closed",

    stock_price_unit: "Stock price (won)",
    stock_count_unit: "Stock volume (shares)",
    employee_count_unit: "Number of employees",
    day_unit: "D",
    month_unit: "M",
    year_unit: "Y",
    unit: "Unit",
    one_won: "KRW",
    one_million_won: "1M KRW",
    person_count: "",

    /**
     * Format
     */

    format_news_trend: "{0} News trend (%)",
    format_stock_price: "{0} stock price",
    format_stock_volume: "{0} volume",
    format_stock_market_cap: "{0} market cap",

    format_number: `{value, plural,
        =0 {-}
        other {{value, number}}}`,

    format_million: `{value, plural,
        =0 {-}
        other {{value, number} M}}`,

    format_billion: `{value, plural,
        =0 {-}
        other {{value, number} B}}`,

    format_trillion: `{value, plural,
        =0 {-}
        other {{value, number} T}}`,

    format_price: `{price, plural,
        =0 {0 won}
        other {{price, number} won}}`,

    format_stock: `{stock, plural,
        =0 {-}
        other {{stock, number}}}`,

    format_change_rate: `{value} %`,

    format_event_analysis: "{name} event analysis",
    format_company_event_analysis: "{name} stock price impact analysis",

    /**
     * TIME FORMAT
     */
    // format_market_time: "Market time - MM DD HH:mm",
    format_birthday: "MM/DD/YYYY",
    format_date: "MMM DD, YYYY",
    format_date_month: "MMMM YYYY",
    format_document_date: "MM/DD/YYYY",
    format_time: "HH:mm A z",
    format_market_time: "MMM DD YYYY [at] HH:mm A z",
    format_financial_time: "MMM DD YYYY",

    format_related_document: "{0} related document",
    format_related_disclosure: "{0} related disclosure",
    format_related_news: "{0} related news",
    format_related_research: "{0} related analyst report",
    format_related_ir: "{0} related IR",
    format_related_company: "{0} related company",

    /**
     * Message String key
     */
    message_no_result: "No result.",
    message_searching: "Searching...",
    message_no_search_data: "No data searched.",
    message_error_search_data:
        "Failed to get the results from the server. Please try again later, or request a change in the search query.",
    message_fail_to_analysis: "No result found.",

    //INPUT_INTERPRETATION
    message_same_name_list: `* There are total {count, plural,
        =0 {0 company}
        one {1 company}
        other {{count, number} companies}} companies with the same name as {name}. `,

    message_company_search_count: `Total {count, plural,
        =0 {0 company}
        one {1 company}
        other {{count, number} companies}} found`,

    message_company_limit_search_count: `{count, plural,
        =0 {0 company}
        one {1 company}
        other {{count, number} companies}} in {total, plural,
            =0 {0 company}
            one {1 company}
            other {{total, number} companies}}`,
    message_company_limit_search_info: `Only up to 1000 results are displayed. To see the full list, export the data into Excel, or change the search criteria to make the results less than 1000`,

    message_document_search_count: `Total {count, plural,
        =0 {0 document}
        one {1 document}
        other {{count, number} found}}`,
    message_company_count: `{count, plural,
        =0 {0}
        one {1}
        other {{count, number}}}`,

    message_document_count: `{count, plural,
        =0 {0}
        one {1}
        other {{count, number}}}`,

    message_more_document_count: `{count, plural,
        =0 {0}
        one {1}
        other {{count, number} +}}`,

    message_more_count: `{count, plural,
        =0 {0 more}
        one {1 more}
        other {{count, number} more}}`,

    message_since_document_count: `{count, plural,
            =0 {0}
            one {1}
            other {{count, number}}} \n ({since}~)`,

    message_tooltip_score_5:
        "LEVEL 5 ( score : {0} ) : The company discloses its ongoing business through annual reports and IR, and it is clearly publicized by analyst reports and news.",
    message_tooltip_score_4:
        "LEVEL 4 ( score : {0} ) : The company discloses its ongoing business through annual reports and IR, and it is indicated by analyst reports.",
    message_tooltip_score_3:
        "LEVEL 3 ( score : {0} ) : The company discloses its ongoing business through annual reports and IR.",
    message_tooltip_score_2:
        "LEVEL 2 ( score : {0} ) : The company discloses its ongoing business through annual reports, and it has been published in the news.",
    message_tooltip_score_1:
        "LEVEL 1 ( score : {0} ) : The company discloses its ongoing business through annual reports.",

    portfolio_guide:
        "DeepSearch helps users organize their portfolio based on chosen enterprise search criteria and easily see the past performance of the portfolio.\nWith regard to portfolio, users can use benchmark Index, analysis period, allocation-method, the number of companies, rebalancing cycle, and terms of transaction cost.\nAllocation method supports same weight, market cap weight, and users’ search condition weight. The same weighting is used to equalize the individual weightings of constituents of the portfolio, the market capitalization to the total market capitalization, and the users’ search condition to constitute the stock according to the proportion of the result of users’ condition. For example, if the search condition is determined by the user search condition and the search condition is an AI-related company, the weight of individual company is determined by the AI scores.\nThe portfolio consists of up to 30 companies, and the selection of companies is determined in the order that best meets the first condition chosen. For example, if a user searches for a sales increase, the top 30 companies in sales are selected.\nFor transaction costs, users can specify the transaction cost for each rebalancing cycle. For example, if the transaction cost is 1%, the portfolio total is 100, and the rebalancing percentage is 10%, then the sum of transaction costs is 100 * 10% * 1%.",

    currency_format: "won",
    stock_format: "shares",
    related_score: "score",
    /**
     * GUIDE STRING
     */
    message_wrong_input: "Improper result received from the server.",
    message_no_choose_condition: "No search condition selected.",
    message_common_search_input: "Company, industry, topics, Person or other queries",
    message_duplicated_data: "This data is already added.",
    message_add_data: "{0} added.",
    message_not_found_data: "Not found {0}.",
    message_drag_chart_document_period: "Drag the chart to see only document information for a specific time period.",
    message_sentiment_trend_period: "It shows the trend of affirmation for the company during the set period. The positive score is calculated as the share of positive news and negative news per month for the company.",

    keyword_news_trend: "{0} news trend (%)",
    keyword_price: "{0} stock price",
    keyword_volume: "{0} volume",
    keyword_market_cap: "{0} market cap",

    //포트폴리오
    portfolio: "Portfolio",
    portfolio_entities: "Asset allocation",
    portfolio_symbol_weight: "Weight",

    portfolio_summary: "Portfolio summary",
    portfolio_benchmark: "Benchmark",
    portfolio_raw_returns: "Average annual rate of returns",
    portfolio_stdev_raw_returns: "Standard deviation(simple return)",

    portfolio_excess_returns: "Average annual excess return",
    portfolio_stdev_excess_returns: "Standard deviation(excess return)",
    portfolio_category: "Category",

    portfolio_sharpe_ratio: "Sharpe ratio",
    portfolio_capm_alpha: "CAPM(alpha)",
    portfolio_capm_beta: "CAPM(beta)",

    portfolio_max_drawdown: "Maximum drawdown(MDD)",
    /**
     * 포트폴리오 분석 옵션
     */
    portfolio_option_benchmark_kospi: "Benchmark : KOSPI",
    portfolio_option_benchmark_kosdaq: "Benchmark : KOSDAQ",

    /**
     * 포트폴리오 분석 기간
     */
    portfolio_option_period_1_year: "Analysis Period : Recent 1YR",
    portfolio_option_period_3_year: "Analysis Period : Recent 3YR",
    portfolio_option_period_5_year: "Analysis Period : Recent 5YR",
    portfolio_option_period_10_year: "Analysis Period : Recent 10YR",

    /**
     * 포트폴리오 구성 기업 개수
     */
    portfolio_option_company_count_10: "Number of Universe (MAX) : 10",
    portfolio_option_company_count_20: "Number of Universe (MAX) : 20",
    portfolio_option_company_count_30: "Number of Universe (MAX) : 30",

    /**
     * 포트폴리오 구성 방법
     */
    portfolio_option_weight_method_equal: "Allocation Method : Same Weight",
    portfolio_option_weight_method_value: "Allocation method : Market Cap",
    portfolio_option_weight_method_custom: "Allocation method : User's Condition",

    /**
     * 포트폴리오 거래 비용
     */
    portfolio_option_transaction_cost_00: "Transaction Cost : NONE",
    portfolio_option_transaction_cost_01: "Transaction Cost : 0.1%",
    portfolio_option_transaction_cost_05: "Transaction Cost : 0.5%",
    portfolio_option_transaction_cost_10: "Transaction Cost : 1.0%",
    portfolio_option_transaction_cost_15: "Transaction Cost : 1.5%",
    portfolio_option_transaction_cost_20: "Transaction Cost : 2.0%",

    /**
     * 포트폴리오 리밸런싱 주기
     */
    portfolio_option_rebalancing_daily: "Rebalancing : Daily",
    portfolio_option_rebalancing_monthly: "Rebalancing : Monthly",
    portfolio_option_rebalancing_quarterly: "Rebalancing : Quarterly",
    portfolio_option_rebalancing_semi_annual: "Rebalancing : Semi-Annually",
    portfolio_option_rebalancing_annual: "Rebalancing : Annually",
    portfolio_option_rebalancing_annual_june: "Rebalancing : End of June Every Year",
    portfolio_option_rebalancing_no: "Rebalancing : NONE",

    /**
     * Login 관련 신규 텍스트
     */
    message_unauthorized_error: "This account does not have permission to use the DeepSearch Excel add-in.(Available for Enterprise Plan or Premium Plan) Please contact us through channelTalk",
    message_login_error: "Your account or password is incorrect.",
    message_login_authentication_error: "Account information could not be found. The account you entered has not been signed up or has been pre-authenticated.",
    message_password_no_match_error: "Passwords do not match.",
    message_password_match_error: "Passwords do match.",
    message_do_change: "Do you want to change?",
    message_can_not_change_email: "This email can not be changed.",
    message_changed_email: "Your email has changed.",
    message_changed_password: "Your password has been changed.",
    message_input_password: "Please enter a password.",
    message_not_found_user: "User's data could not be found.",
    need_login_message: "This service requires {0} login.",

    /**
     *  지 관련 신규 텍스트
     */
    // 홈페이지 메뉴
    about: "About",
    product: "Products",
    promotion: "Promotion",
    press: "Press",
    blog: "Blog",
    notice: "Notice",
    notice_event: "Notice/Event",
    contact: "Contact",
    change_password: "Change Password",
    find_password: "Find password",
    current_password: "Current Password",
    change_email: "Change Email",
    email: "Email",
    phone: "Phone",
    address: "Address",
    zipcode: "Zipcode",
    password: "Password",
    new_password: "New Password",
    password_confirm: "Confirm Password",
    change: "Change",
    connect_with_facebook: "Connect with facebook",
    overview: "Overview",
    function: "Function",
    features: "Features",
    business_rid: "Business Number",
    company_rid: "Corporate Number",
    tel: "Tel",
    fax: "Fax",
    website: "Website",
    land_lot_ko: "Land Address",
    road_name_ko: "Road Address",
    address_road_name: "Road Address",
    request_free_trial: "Request a free trial",
    request_demo: "Request demo",
    introduce_service_pdf: "Introduce Service (PDF)",

    message_deepsearch_overview:
        "DeepSearch Analytics makes it easy to collect, analyze and manage data in decision making.\nThis process is all you need to do is just enter a few keywords related to the topic you are analyzing.",
    /**
     * DeepSearch Proudct
     */
    message_deepsearch_introdution:
        "DeepSearch provides financial big data and differentiated search and analysis service in a user-friendly manner.",
    /**
     * DDI Product
     */
    ddi: "DDI",
    ddi_full: "DeepSearch Data Intelligence",
    ddi_case: "Case",
    message_ddi_introdution:
        "With DeepSearch Data Intelligence(DDI), you can minimize the time and cost of acquiring and managing financial data.",

    /**
     * SNEK Product
     */
    snek: "SNEK",
    snek_research: "Sell-side Research",
    snek_ir: "Investor Relations (IR) books",

    message_snek_introdution:
        "SNEK is an investment research platform that helps you have an in-depth analysis of your company of interest.",
    /**
     * Alerts Product
     */
    alerts: "Alerts",
    ddi_alerts: "DDI Alerts",

    message_ddi_alerts_introdution:
        "Deepsearch highlights risk keywords and displays related documents with those keywords. This enables users to prevent risks in advance and make timely decisions to reduce the size of investment or the size of loan.",

    /**
     * Index Product
     */
    index: "Index",
    index_mega_trends_50: "DeepSearch MegaTrend 50",
    references: "References",

    message_index_introdution:
        "With DeepSearch's powerful search capabilities, users can build their own Index.",
    message_index_sub_introdution: "DeepSearch currently publishes portfolios including 50 topics.",
    /**
     * Careers
     */
    recruit: "Recruit",
    recruit_apply: "Apply",
    recruit_list: "List",

    /*
     * Chart controls
     */
    chart_dropdown_companies: "Companies",
    chart_dropdown_2nd_axis: "2nd axis",

    /**
     * Dashboard
     */
    dashboard_default_group: "Default Group",
    dashboard_default_tile_kospi_index: "KOSPI Index",
    dashboard_default_tile_kosdaq_index: "KOSDAQ Index",
    dashboard_default_tile_call_rates: "Call Rates",
    dashboard_default_tile_krw_usd_xr: "USD to KRW Exchange Rates",

    dashboard_group: "Dashboard Group",
    dashboard_group_list_add_group: "Add a new group",
    dashboard_group_list_add_empty_group: "Add an empty group",
    dashboard_group_list_add_default_home_group: "Add a default home group",
    dashboard_group_list_add_securities_template: "Add a template for securities",

    dashboard_grid_menu_add_new_tile: "Add a new tile",
    dashboard_grid_menu_add_favorite_securities: "Add favorite securities",
    dashboard_grid_menu_add_favorite_industry: "Add favorite industry",
    dashboard_grid_menu_add_trending_topics: "Add trending topics",
    dashboard_grid_menu_add_favorite_news: "Add favorite news",
    dashboard_grid_menu_data_select_wizard: "Data-select wizard",
    dashboard_grid_menu_write_query_directly: "Write a DeepSearch query directly",

    trending_topic_tile_is_added: "Trending topic tile is added.",
    document_types: "Document Types",

    dashboard_grid_menu_rename_group: "Rename this dashboard group",
    dashboard_grid_menu_delete_group: "Delete this dashboard group",

    dashboard_focus_menu_back_to_groups: "Back to dashboard groups",
    dashboard_focus_menu_edit_tile: "Edit the tile",
    dashboard_focus_menu_delete_tile: "Delete this tile",
    new_tab: "New Tab",
    start_page: "Start page",
    error: "Error",

    related_articles: "Related Articles",

    dashboard_groups_control_rename: "Rename",
    dashboard_groups_control_delete: "Delete",
    dashboard_groups_control_adding_new_group: "Adding a new group...",

    dashboard_rename_group_modal_message: "Please write a new group name.",
    dashboard_delete_group_modal_message: "Do you really want to delete the group?",

    dashboard_write_query_modal_title: "Write a DeepSearch query for a new tile",
    dashboard_write_query_modal_message: "Please write a tile name and a DeepSearch query below.",
    dashboard_write_query_modal_tile_name: "Tile Name",
    dashboard_write_query_modal_deepsearch_query: "DeepSearch Query",

    star_button_add_to_dashboard: "Add to Dashboard",
    add_to_dashboard_completed: '"{0}" has added to "{1}" Dashboard group.',

    /**
     * Contact
     */
    contact_us: "Contact Us",
    name: "Name",
    email_address: "E-mail address",
    message: "Message",
    send: "Send",
    message_contact_us: "Get in touch with us.",
    contact_email: "contact@deepsearch.com",
    contact_phone: "+82-70-8711-3338",
    contact_address: "204, Gangnam Building, Seocho-daero 396, Seocho-gu, Seoul",

    /**
     * ADMIN
     */
    lang: "Language",
    account: "Account",
    add_user: "Add User",
    add_organization: "Add Organization",
    active: "Active",
    enable: "Enabled",
    disable: "Disabled",
    updated_date: "Updated Date",
    created_date: "Created Date",
    deleted_date: "Deleted Date",
    last_active_date: "Last Active Date",
    last_login_date: "Last SignIn Date",
    password_changed_date: "Password Changed Date",
    create: "Create",
    create_account_completed: "Account creation completed",
    create_organization_completed: "Organization creation completed",
    search_user_data_placeholder: "Search within results and search user",
    search_org_data_placeholder: "Search within results and search organization",
    my_info: "My info",
    upgrade: "Upgrade",
    plan_upgrade: "Plan upgrade",
    user_info: "User Information",
    user_data: "User Data",
    usage_status: "Usage Status",
    usage: "Usage",
    usage_limit: "Usage limit",
    export_excel_company: "Download Company Excel",
    export_excel_document: "Download Document Excel",
    export_excel_portfolio: "Download Portfolio Excel",
    export_content_usage: "Contents Download Usage",
    export_excel_remain: "Download Remaining Excel Count",
    export_excel_target_count: "Download target count",
    export_excel_org_usage_message:
        "The Excel download limit is updated on {0} and is managed on an enterprise basis.",
    export_excel_usage_message: "The Excel download limit is updated on {0}",
    export_excel_empty_message:
        "There are not enough downloadable excel count remaining. Unlimited downloads are available for premium upgrades.",
    member_seat_license: "Member Seat License",
    user_max_sessions: "User Max Sessions",
    pro_access: "PRO access",
    premium_access: "PREMIUM access",
    non_external_audit_access: "Non-exempt financial information authority",
    subscription_term: "Term",
    subscription_status: "Subs Status",
    auto_cancel: "Auto Cancel",
    addable_subscription_item: "Addable Subscription Item",
    subscription_modify: "Modify Subscription Term",
    subscription_delete: "Delete Subscription",
    trial_to_subscription: "Change To Subscription",
    account_manager: "Account Manager",

    trial: "TRIAL",
    licensed_subscription: "Subscription",
    metered_subscription: "Measured Rate Subscription",
    start_date: "Start date",
    end_date: "End date",
    unlimit: "Unlimit",
    end_trial_message: "{0}, the TRIAL period expires.",
    end_subs_message: "{0}, period expires.",
    no_subscription_menu_block_message: "Monthly subscription is required to perform {0}.",
    contact_inquiry: "Contract Inquiry",
    delete_user_from_organization_message: "Remove from group?",
    scheduled_to_end: "Scheduled to end",
    scheduled_to_end_after_days: "Scheduled to end after {0} days",
    actived_seat: "Actived User",
    member_seat_change_message: "Are you sure you want to change {0}?",
    seat_changed_message: "Seat has changed.",
    no_seat_message: "Not enough Seat.",
    expired_message: "Your subscription has expired.",
    expired_detail_message:
        "Please extend the subscription period or contact the person in charge below for the formal service.",
    empty_subscription_message: "No available Subscription.",
    message_not_enough_subscription_item: "You have used all of your {0} pass.",
    go_home: "Move to Home",
    metered_subscription_blind_message:
        "Users who use the volume license can check the data using Excel download.",
    no_subscription_blind_message:
        "You do not have access to view search results. Please click on the top right Usage button in the upper right corner to confirm the contract status.",
    account_plan: "Plans",
    extending_plan : "Extending the plan",

    /**
     * mail document list
     */
    mail_list: "Mail List",
    added_documents_mail: "Added documents in Mailing",
    add_document_to_maillist: "A document has been added to the mailing list.",
    remove_document_from_maillist: "The document has been deleted from the mailing list.",
    error_document_to_maillist: "It's already been added.",
    selected_industry_list: "Selected Industry List",
    industry_overview: "Industry Overview",
    industry_pie_chart_title: "Pie Chart of Firm Fundamentals",
    industry_bar_chart_title: "Bar Chart of Firm Fundamentals",
    industry_peer_comparison_chart_title: "Firm Value Estimates by Peer Comparison",
    industry_past_history_title: "Past History of Firm Fundamentals",
    analyst_report: "Analyst Reports",
    positive_news: "Positive News",
    negative_news: "Negative News",
    all_news: "All News",
    securities: "Securities",
    keywords: "Keywords",
    securities_list: "Securities List",
    news_feed_in: "News Feed in {0}",
    selected_securities_sentiment_analysis: "Sentiment Analysis of the Selected Securities",
    selected_topic_sentiment_analysis: "Sentiment Analysis for Selected Topics",
    mind_map_graph: "Mind Map Network Graph",
    spawn_conditions: "Spawn Conditions",
    trending_keywords: "Trending Keywords",
    related_keywords: "Related Keywords",
    similar_keywords: "Similar Keywords",
    video_help_message: "Video Tips Click 'Help' on the left menu.",
    dont_show_again: "Do not show again",
    selected_company: "Selected Companies",
    selected_companies: `{name} {count, plural,
        =0 {}
        one {and {count} Company}
        other {and {count} Companies}}`,
    selected_filter: "Sort Order",
    go_up: "Go Up",
    fundamentals_comparison: "Fundamentals Comparison",
    user_custom: "User Custom",
    add_subject_message: "Add your subject.",
    trending_topic_company: "Trending Topic Company",
    peer_relative_to: "Relative to",
    topic_base: "Trending Topic Base",
    market_base: "Market Base",
    shareholders_base: "Shareholders Base",
    consensus_base: "Consensus Base",
    shareholding_base: "Shareholding Base",
    shareholder_company: "Shareholder company",
    holding_value: "Holding Value",
    current_price: "Current",
    target_price: "Target",
    prev_target_price: "Previous Target",
    target_price_rage: "Target price rage",
    price_change_ratio: "Change",
    transaction_price: "Transaction",
    competition_section: "Competition Section",
    stock_price_trend_competitors: "Stock price trend with competitors",
    competitors_portfolio: "Competitors portfolio",
    remove_cache: "Reset local data",
    message_remove_cache: "Cache removal is complete.",
    message_not_found_industry: "Not found {0} industry.",
    company_history: "History",
    business_goal: "Business Areas",
    company_related_firms_and_business: "Related Companies and Business Areas",
    shareholders_info: "Shareholders Info",
    comperitor_comparison_analysis: "Competitor Comparison Analysis",
    common_stock: "Common Stock",
    preferred_stock: "Preferred Stock",
    company_branches_info: "Company branches",
    dividend_info: "Dividend Info",
    dividend_rate: "Dividend Rate",
    company_shares_n_dividend_info: "Company Shareholders and Dividend",
    company_employee_info: "Employees Info",
    company_employee_count_info: "Number of Employees",
    dart_based_company_employee_info: "Average salary and number of employees",
    permanent_employee: "Permanent",
    contract_employee: "Contract",
    average_tenure: "Average tenure",
    average_salary: "Average salary",
    total_annual_salary: "Total annual salary",
    dart_based_company_executive_info: "Board of Directors and Average compensation",
    company_directors_compensation: "Directors compensation",
    non_registered_executives_included: "Non-registered executives included",
    list_of_top_payers_by_individual: "List of top payers by individual",
    company_directors_info: "Directors List",
    company_executive_info: "Executives Info",
    company_executive_count: "Executive Count",
    job_description: "Job description",
    gender: "Gender",
    relation: "Relation",
    compensation: "Compensation",
    total_compensation: "Total compensation",
    avg_compensation: "Average compensation",
    tenure_duration: "Tenure duration",
    tenure_end: "Tenure end",
    company_borrowings_info: "Borrowings Details",
    company_related_firms: "Subsidiaries and Affiliates",
    shareholder_related_company: "Shareholder Companies",
    message_no_borrowings_info: "No borrowings info in {0}.",
    message_shareholder_related_company:
        "If shareholders are individuals, they may be of the same name.",
    message_inaccurate_shareholder_related_company: "The list of companies whose stocks are held by a specific person may not be processed with the same name, and the data may not be accurate.",
    research_consensus: "Research Consensus",
    forecasted_financial_information: "Research Consensus ( Financial )",
    research_consensus_average: "Research Consensus average",
    research_consensus_info: "Research Consensus",
    target_price_and_opinion: "Target price and Opinion",
    investment_opinion: "Investment Opinion",
    prev_investment_opinion: "Previous Investment Opinion",
    collect_into_industry: "View industry analysis",
    more_search_results: "More Search Results",
    shareholders_change_rate: "Shareholders change rate",
    upper_shareholders_related_network: "Shareholders Network",
    pin_to_top: "Pin to Top",
    presumptive_agency: "Presumptive agency",
    presumptive_date: "Presumptive date",
    presumptive_year: "Presumptive year",
    latest_estimate: "Latest estimate",
    last_estimate: "Last estimate",
    sales_growth_rate: "Sales growth rate over the last three years",
    last_year_profitability_ratio: "Last year's operating margin",
    last_year_liabilities_ratio: "Last year's debt ratio",
    dividend_yield: "Dividend Yield",
    freq: "Freq",
    frequency: "Frequency",
    keyword_freq: "Keyword frequency",
    business_summary: "Business summary",
    business_status: "Business status",
    three_month_stock_return: "3-month stock return",

    /**
     * VALUATION
     */
    business_area_selection: "Business Area Selection",
    enter_business_keywords: "Enter business keywords",
    keyword_relationship: "Keyword relationship",
    document_relationship: "Document relationship",
    business: "Business",
    business_domain: "Business",
    industry_category: "Industry",
    report: "Report",
    revenue: "Revenue",
    selected_recommend_condition: "Selected Conditions",
    selected_keywords: "Selected Keywords",
    recommended_condition: "Condition",
    valuation_selected_companies: "Selected Companies",
    industry_analysis_more: "Industry Analysis",
    beta_calculation_more: "Beta Calculation",
    user_company_selection: "Add Company",
    user_add_condition: "Additional Conditions",
    enter_keyword: "Enter Keyword",
    reset: "Reset",
    valuation_recommend_condition: "Recommended search conditions",
    enter_condition: "Enter condition",
    address_placeholder: "Enter Address",
    direct_input: "Direct Input",
    and: "AND",

    company_age: "Company Age",
    new_company: "New",
    one_three_years: "1-3 Years",
    three_seven_years: "1-3 Years",
    seven_ten_years: "1-3 Years",
    ten_plus_years: "1-3 Years",

    related_disclosure: "Related Disclosure",
    related_ir: "Related IR",
    related_research: "Related Reports",
    related_patent: "Related Patents",

    valuation_income_statement: "Income Statement",
    valuation_search_results: "Results",
    previous_info: "Previous information",
    financial_accounts: "Financial Accounts",
    book_value: "Book value",
    interest_baring_debt: "Interest Baring Debt",
    premium_analysis: "Premium",

    /**
    * Beta Calculation
    */
    beta_calculation: "Beta Calculation",
    company_selection: "Company Selection",
    beta_calculation_criteria: "Beta Calculation Criteria",
    yeild_based: "Yield",
    select_period: "Select Period",
    interest_liability_base_year: "Interest Liability Base Year",
    total_trading_dates: "Trading Days",
    apply_beta_calculation: "Apply Beta Calculation",
    view_yield_scatter_plot: "View Yield Scatter Plot",
    standard_index: "Index",
    // interest_liability_one_million_won: "Interest liabilities",
    // market_cap_one_million_won: "Market Cap",
    analysis_period: "Analysis Period",
    yeild_calculation_criteria: "Yeild Calculation Criteria",
    reference_date: "Reference Date",

    download_all_financial_statements: "Download all financial statements Excel",

    /**
     * Billing
     */
    purchase: "Purchase",
    payment_history: "Payment history",
    card_number: "Card number",
    expiry_date: "Expiry date",
    card_first_2_digit: "2 digits before the card password",
    enter_card_info: "Enter card information",
    message_safe_payment_info: "Payment information is transmitted securely using SSL protocol.",
    message_omit_dash: "- Omit and enter",
    message_already_registered_card: "This card is already being used by another account. Please enter another card.",
    birthday_or_business_rid: "6 digits of birth date (individual) / 10 digits of business number (corporation)",
    choose_plan: "Choose Plan",
    free_for_a_month: "One month free trial",
    signup_for_free: "Signup for free",
    join : "Join",
    cancel_subscription: "Cancel subscription",
    recover_subscription: "Resume Subscription",
    start_subscription: "Start subscription",
    subscription_info: "Subscription information",
    view_history: "View history",
    data_download: "Data Download",
    payment_method: "Payment method",
    payment_card: "Payment Card",
    card_change: "Card change",
    card_registeration: "Card registration",
    admission: "OK",
    pay_date: "Pay date",
    next_pay_date: "Next pay date",
    pay_amount: "Pay amount",
    remark: "Remark",
    receipt: "Receipt",
    no_payment_info: "There is no payment information.",
    failure: "Failure",
    payment_required: "Payment required",
    no_card_info: "No card information",
    change_plan: "Change plan",
    cancel_choose_plan: "Cancel plan selection",
    card_owner_name: "Card owner name",
    format_input_length_error: "You must enter a value of at least {0} digits.",
    contact_biz_inquiry: "Sales team inquiry",

    //People
    show_all_target_search_result: "View all {0} results",
    owned_share: "Owned share",
    name_search: "Name search",
    company_name_search: "Company name search",
    message_company_name_search: "Search by company name",
    choose_company_first: "Please choose a company first",
    find_name_first: "Please search for a name first",

    //Search
    quick_guide: "DeepSearch Quick Guide",
    message_deepsearch_guide: "We provide a guide that can be used in a variety of DeepSearch.",
    main_search_placeholder: "Please enter a search term.",
    top_search_placeholder: "Search",
    view_related_document: "View Related Documents",
    close_related_document: "Close Related Documents",
    wikipedia : "Wikipedia",

    //Bookmark
    bookmark : "Bookmark",
    add_bookmark : "Add Bookmark",
    remove_bookmark : "Remove Bookmark",
    message_add_bookmark : "Added to bookmarks",
    message_remove_bookmark : "Removed to bookmark",
    message_empty_bookmark : "No bookmarks have been added",
    message_invalid_date : "The set date is not correct",

};
