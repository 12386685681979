import { IComputeModel, IComputeProfile } from "../../../../model/compute/IComputeModel";
import {
  IDataFramePodModel,
  IDataPodModel,
  IDocumentSearchPodModel,
  IHTTPRequestPodModel,
  IInputPodModel,
  IPodModel,
  ISearchResultPodModel,
} from "../../../../model/compute/IPods";
import _DataPod from "./pods/_DataPod";
import _InputPod from "./pods/_InputPod";
// import {
//   _App,
//   _AppList,
//   _AuthPod,
//   _Organization,
//   _OrganizationList,
//   _OrganizationMemeberList,
//   _Plan,
//   _PlanList,
//   _Subscription,
//   _SubscriptionItem,
//   _SubscriptionItemList,
//   _SubscriptionList,
//   _User,
//   _UserList,
//   _UserStash,
// } from "./pods/datapods/_AuthPods";
import _DataFramePod from "./pods/datapods/_DataFrame";
import _DocumentSearch from "./pods/datapods/_DocumentSearch";
import _HTTPRequest from "./pods/datapods/_HTTPRequest";
import _PortfolioAnalysis from "./pods/datapods/_PortfolioAnalysis";
import _PortfolioReturns from "./pods/datapods/_PortfolioReturns";
import _SearchResultPod from "./pods/datapods/_SearchResult";
import _SingleValue from "./pods/datapods/_SingleValue";
import _TextList from "./pods/datapods/_TextList";
import {
  _App,
  _AppList,
  _AuthPod,
  _Organization,
  _OrganizationList,
  _OrganizationMemeberList,
  _Plan,
  _PlanList,
  _Subscription,
  _SubscriptionItem,
  _SubscriptionItemList,
  _SubscriptionList,
  _User,
  _UserList,
  _UserStash,
} from "./pods/datapods/_AuthPods";

class _Profile implements IComputeProfile {
  private _data: any;
  constructor(data: any) {
    this._data = data;
  }

  get compile(): number {
    return this._data.compile;
  }

  get interpreter(): number {
    return this._data.interpreter;
  }

  get pods(): number {
    return this._data.pods;
  }

  get total(): number {
    return this._data.total;
  }
}

export default class _Compute implements IComputeModel {
  private _query: string;
  private _data: any;
  private _error: any;
  constructor(query: string, data: any, error?: any) {
    this._query = query;
    this._data = data;
    this._error = error;
  }

  get pods(): IPodModel[] {
    if (this._data && this._data.pods) {
      return this._data.pods.map((p: any) => {
        switch (p.class) {
          /**
           * Input Pod
           */
          case "Input":
            return new _InputPod(p, this._query);

          /**
           * Data
           */
          case "Result:DataFrame":
            return new _DataFramePod(p, this.inputPod);
          case "Result:DocumentSearchResult":
            return new _DocumentSearch(p, this.inputPod);
          case "Result:TextList":
            return new _TextList(p, this.inputPod);
          case "Result:SingleValue":
            return new _SingleValue(p, this.inputPod);
          case "Result:PortfolioAnalysis":
            return new _PortfolioAnalysis(p, this.inputPod);
          case "Result:PortfolioReturns":
            return new _PortfolioReturns(p, this.inputPod);
          /**
           * Auth Pod
           */
          case "Result:User":
            return new _User(p, this.inputPod);
          case "Result:UserStash":
            return new _UserStash(p, this.inputPod);
          case "Result:UserList":
            return new _UserList(p, this.inputPod);
          case "Result:Organization":
            return new _Organization(p, this.inputPod);
          case "Result:OrganizationList":
            return new _OrganizationList(p, this.inputPod);
          case "Result:OrganizationMemberList":
            return new _OrganizationMemeberList(p, this.inputPod);
          case "Result:Subscription":
          case "Result:SubscriptionTemplate":
            return new _Subscription(p, this.inputPod);
          case "Result:SubscriptionTemplateList":
            return new _SubscriptionList(p, this.inputPod);
          case "Result:SubscriptionItem":
          case "Result:SubscriptionTemplateItem":
            return new _SubscriptionItem(p, this.inputPod);
          case "Result:SubscriptionItemList":
            return new _SubscriptionItemList(p, this.inputPod);
          case "Result:Plan":
            return new _Plan(p, this.inputPod);
          case "Result:PlanList":
            return new _PlanList(p, this.inputPod);
          case "Result:Application":
            return new _App(p, this.inputPod);
          case "Result:ApplicationList":
            return new _AppList(p, this.inputPod);
          case "Result:HTTPResponse":
            return new _HTTPRequest(p, this.inputPod);

          /**
           * Search
           */
          case "Result:UnifiedSearchResult":
          case "Result:UnifiedSuggestResult":
            return new _SearchResultPod(p, this.inputPod);
          case "Result:DBStatsResult":
          case "Result:JSON":
          case "Result:BondYieldResults":
          default:
            return new _DataPod(p, this.inputPod);
        }
      });
    } else {
      return [];
    }
  }

  get input(): string {
    return this._query || "";
  }

  set input(q: string) {
    this._query = q;
  }

  get inputPod(): IInputPodModel {
    if (this._data && this._data.pods) {
      const pods = this._data.pods
        .filter((p: any) => p.class === "Input")
        .map((p: any) => new _InputPod(p, this._query));
      if (pods.length > 0) {
        return pods[0];
      }
    }
    return new _InputPod(null, this._query);
  }

  get dataframePod(): IDataFramePodModel | null {
    const pods = this.pods.filter((pod: IPodModel) => pod instanceof _DataFramePod);
    if (pods.length > 0) {
      return pods[0] as IDataFramePodModel;
    } else {
      return null;
    }
  }

  get searchPod(): ISearchResultPodModel | null {
    const pods = this.pods.filter((pod: IPodModel) => pod instanceof _SearchResultPod);
    if (pods.length > 0) {
      return pods[0] as ISearchResultPodModel;
    } else {
      return null;
    }
  }

  get httpPod(): IHTTPRequestPodModel | null {
    const pods = this.pods.filter((pod: IPodModel) => pod instanceof _HTTPRequest);
    if (pods.length > 0) {
      return pods[0] as IHTTPRequestPodModel;
    } else {
      return null;
    }
  }

  get authPod(): IDataPodModel | null {
    const pods = this.pods.filter((pod: IPodModel) => pod instanceof _AuthPod);
    if (pods.length > 0) {
      return pods[0] as IDataPodModel;
    } else {
      return null;
    }
  }
  get documentPod(): IDocumentSearchPodModel | null {
    const pods = this.pods.filter((pod: IPodModel) => pod instanceof _DocumentSearch);
    if (pods.length > 0) {
      return pods[0] as IDocumentSearchPodModel;
    } else {
      return null;
    }
  }

  get dataPod(): IDataPodModel | null {
    const pods = this.pods.filter((pod: any) => pod instanceof _DataPod);
    if (pods.length > 0) {
      return pods[0] as IDataPodModel;
    } else {
      return null;
    }
  }

  get profile(): IComputeProfile {
    return new _Profile(this._data.profile);
  }

  get error(): any {
    return this._error;
  }

  get errorMessage(): string {
    let msg = this._error.message;
    const resp = this._error.response;
    if (resp && resp.data && resp.data.data) {
      const pod = resp.data.data.pods[0];
      try {
        msg = pod.subpods[0].content.data[0];
      } catch (e) {}
    }
    return msg;
  }

  get data(): any {
    return this._data;
  }
}
