import { AppInfo } from ".";

const SHINHAN_INVEST: AppInfo = {
  uid: "993015990105280512",
  name: "신한금융투자",
  displayName: "신한금융투자",
  appId: "deepsearch-shinhaninvest",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["shinhaninvest.deepsearch.com", "alpha-shinhaninvest.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "https://www.shinhaninvest.com/siw/common/images/shinhan.ico",
  appIcon: "https://www.shinhaninvest.com/siw/common/images/shinhan.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.shinhaninvest.com",
  logoUrl: "/public/partners/shinhan-financial-ko.png",
  title_en: "신한금융투자 - DeepSearch | Data for your decisions",
  title_ko: "신한금융투자 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
  restricted: {
    sidebar: ["/template"],
  },
};

export default SHINHAN_INVEST;
