// import DangTalk from "app-config/dangtalk";
// import DeepSearch from "app-config/deepsearch";
// import Nice from "app-config/nice-kisline";
// import KB from "app-config/kb";
// import SHINHAN_INVEST from "app-config/shinhaninvest";
// import SAMSUNG_SECURITIES from "app-config/samsungsecurities";
// import MIDASASSET from "app-config/midasasset";
// import KAIST from "app-config/kaist";
// import SIMONE from "app-config/simonefg";
// import SSU from "app-config/ssu";
// import DB_FI from "app-config/db-fi";
// import SACC from "app-config/sacc";
// import LAGUNAI from "app-config/lagunai";
// import NICE_KISLINE from "app-config/kisline";
// import MIRAEASSET from "app-config/miraeasset";

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/

const DeepSearch = {
  uid: "735579309804228608",
  name: "DEEPSEARCH",
  displayName: "DeepSearch",
  appId: "deepsearch",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["www.deepsearch.com", "alpha-www.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://medium.com/deepsearch",
  favico: "/favicon.ico",
  appIcon: "/images/apple-homescreen.png",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.deepsearch.com",
  logoUrl: "",
  title_en: "DeepSearch | Data for your decisions",
  title_ko: "DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
  keywords: [],
};

// const env = {
//   ...process.env,
//   // ...window._deepsearch._env_,
// };
export const STAGE = process.env.REACT_APP_STAGE || window?.__RUNTIME_CONFIG__?.REACT_APP_STAGE || "develop";

export const DEBUG_MODE = STAGE != "production";

export const BACKEND_STAGE =
  process.env.REACT_APP_BACKEND_STAGE || window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_STAGE || "develop";

export const DEEPSEARCH_QUERY_BASE64_ENCODING = false;
export const VERSION_NAME = "3.0";
export const VERSION_NUMBER = "06";
export const DEEPSEARCH_APP_VERSION = `${VERSION_NAME}.${VERSION_NUMBER}`;

export const SIGNUP_CHECK = true;
export const TIER_CHECK = true;
export const BILLING_CHECK = true;

/**
 * NICE - NICE Service
 * OTHER - 'NICE' 이외의 모든 값은 기본 DeepSearch 서비스.
 */

export const TYPE = {
  DEEPSEARCH: DeepSearch,
  NICE: null,
  DANGTALK: null,
  KB: null,
  SHINHAN_INVEST: null,
  SAMSUNG_SECURITIES: null,
  MIDASASSET: null,
  KAIST: null,
  SIMONE: null,
  SSU: null,
  DB_FI: null,
  SACC: null,
  LAGUNAI: null,
  NICE_KISLINE: null,
  MIRAEASSET: null,
};

// function getType() {
//   const host = window.location.host;
//   if (host) {
//     const vender = Object.values(TYPE).find((e) => e.hosts.find((h) => host.startsWith(h)));
//     if (vender) {
//       return vender;
//     }
//   }
//   return TYPE.DEEPSEARCH;
// }

/**
 * Config 내의 TYPE 값을 이용하여 지정.
 */
export const App = DeepSearch;

export const NAVER_MAP_CLIENT_ID = "kfyjzus1yo";

export const BLOG = {
  // MEDIUM: "http://blog.deepsearch.com",
  NAVER: "https://blog.naver.com/deepsearch_inc",
  MEDIUM: "https://medium.com/deepsearch",
  YOUTUBE: "https://www.youtube.com/channel/UC9mN1RKrvAz37SGtbUairfg",
  EXCEL_ADD_IN: "https://www.notion.so/Add-In-9fb950147ed44a529b3c2669e1b3da04",
};

const HELP = {
  GITBOOK_GUIDE: "https://help.deepsearch.com/web/",
  GITBOOK_VERISON: "https://help.deepsearch.com/web/whats/version-info",
};

/**
 *
 *
 *
 * DeepSearch 서비스 관련 설정 값 시작.
 *
 */
const DEEPSEARCH_TRIAL_FORM_URL = "https://forms.gle/BRinWeKotGt6LjKa7";
const DEEPSERACH_PRODUCT_DEMO = {
  DDI: "https://forms.gle/3rp5jAXQJGCerAHb7",
  DP: "https://forms.gle/Z3VRXJyRDVFfgAuk8",
  Index: "https://forms.gle/u5n4gD6jMW7DewwC8",
  Valuation: "https://forms.gle/HyfZsvDtfKN83ZCY7",
};
const DEEPSEARCH_PRODUCT_MENUAL = {
  DDI: "https://help.deepsearch.com/ddi",
  DP: "https://help.deepsearch.com/dp",
};
/**
 *
 *
 * DeepSearch 서비스 관련 설정 값 끝.
 *
 */

/**
 *
 * NICE 서비스 관련 설정 값 시작.
 *
 */
const KISLINE = "kisline.com";
const KISLINE_DEEPSEARCH = "deepsearch.kisline.com";
const KISLINE_UAT = "kluat.kisline.com";

/**
 * KISLINE API HOST
 */
// const KISLINE_API_HOST = "https://www.kisline.com/deepsearch/api";
const KISLINE_DDI_API_HOST = "https://www.kisline.com/deepsearch";

/**
 * KISLINE DEEPSEARCH API HOST
 */
// const KISLINE_DEEPSEARCH_API_HOST = "http://deepsearch.kisline.com/api";
const KISLINE_DEEPSEARCH_DDI_API_HOST = "http://deepsearch.kisline.com";

/**
 * UAT API HOST
 */
// const KISLINE_UAT_API_HOST = "https://kluat.kisline.com/deepsearch/api";
const KISLINE_UAT_SNEK_API_HOST = "https://kluat.kisline.com/deepsearch";

// const NICE_API_HOST = (function () {
//   let hostName = window.location.hostname;
//   if (hostName === KISLINE_DEEPSEARCH) {
//     return KISLINE_DEEPSEARCH_API_HOST;
//   } else if (hostName === KISLINE_UAT) {
//     return KISLINE_UAT_API_HOST;
//   } else if (hostName === KISLINE) {
//     return KISLINE_API_HOST;
//   }
//   return KISLINE_API_HOST;
// })();

const NICE_DDI_API_HOST = (function () {
  let hostName = window.location.hostname;
  if (hostName === KISLINE_DEEPSEARCH) {
    return KISLINE_DEEPSEARCH_DDI_API_HOST;
  } else if (hostName === KISLINE_UAT) {
    return KISLINE_UAT_SNEK_API_HOST;
  } else if (hostName === KISLINE) {
    return KISLINE_DDI_API_HOST;
  }
  return KISLINE_DDI_API_HOST;
})();

export const NICE_MAIN_PAGE = "https://www.kisline.com"; //NICE 계정 연동 실패 시 이동 할 페이지
export const NICE_LOGIN_PAGE = "https://www.kisline.com/cm/CM0100M01GE00.nice"; //NICE 계정 연동 실패 시 이동 할 페이지
export const CDN_HOST_NICE = "https://www.kisline.com/deepsearch/cdn/";
export const CDN_HOST_SNEK = "https://cdn-haystack.snek.ai/";
/**
 *
 * NICE 서비스 관련 설정 값 끝.
 *
 */

// const DEEPSEARCH_API_HOST_MAP = {
//   // production: "https://api.deepsearch.com",
//   // develop: "https://alpha-api.deepsearch.com",
//   // "develop-staging": "https://api.dswalk.com",
//   // local: "http://localhost:9000",
//   production: "https://api.deepsearch.com",
//   develop: "https://alpha-api.deepsearch.com",
//   "develop-staging": "https://api.dswalk.com",
//   local: "https://api.dswalk.com",
// };

const DDI_API_HOST_MAP = {
  production: "https://api.ddi.deepsearch.com",
  develop: "https://api.ddi.deepsearch.com",
  "develop-staging": "https://api.ddi.dswalk.com",
  local: "http://localhost:8300",
};

// const DEEPSEARCH_API_HOST = (function () {
//   switch (App) {
//     case TYPE.NICE:
//       return NICE_API_HOST;
//     default:
//       const localStage = localStorage.getItem("local.stage");
//       if (localStage) {
//         return DEEPSEARCH_API_HOST_MAP[localStage] || DEEPSEARCH_API_HOST_MAP[BACKEND_STAGE];
//       }
//       return process.env.DEEPSEARCH_API || DEEPSEARCH_API_HOST_MAP[BACKEND_STAGE];
//   }
// })();

const DEEPSEARCH_API_HOST = window?.__RUNTIME_CONFIG__?.DEEPSEARCH_API_URL || "https://alpha-api.deepsearch.com";

const DDI_API_HOST = (function () {
  switch (App) {
    case TYPE.NICE:
      return NICE_DDI_API_HOST;
    default:
      const localStage = localStorage.getItem("local.stage");
      if (localStage) {
        return DDI_API_HOST_MAP[localStage] || DDI_API_HOST_MAP[BACKEND_STAGE];
      }
      return process.env.DDI_API || DDI_API_HOST_MAP[BACKEND_STAGE];
  }
})();

/**
 * DEEPSEARCH API
 */
const API_VERSION = 1;
export const DEEPSEARCH_API = `${DEEPSEARCH_API_HOST}/v${API_VERSION}/compute`;
export const DEEPSEARCH_COMPILE_API = `${DEEPSEARCH_API_HOST}/v${API_VERSION}/compile`;
export const DEEPSEARCH_EXPORT_API = `${DEEPSEARCH_API_HOST}/v${API_VERSION}/export`;

/**
 * SNEK API
 */
const DDI_API_VERSION = 1;
export const SNEK_HAYSTACK_API = `${DDI_API_HOST}/haystack/v${DDI_API_VERSION}`;

export const SNEK_WAVELET_SYMBOL_API = (function () {
  // TODO : wavelet staging api 에 동작하지 않는 상태라 분기처리.
  if (DDI_API_HOST === DDI_API_HOST_MAP["develop-staging"]) {
    return `${DDI_API_HOST_MAP["develop"]}/wavelet/v${DDI_API_VERSION}/symbols/_search`;
  }
  return `${DDI_API_HOST}/wavelet/v${DDI_API_VERSION}/symbols/_search`;
})();

const RESOURCE_ROOT = App === TYPE.NICE ? "/deepsearch/" : "/";

function getPublicResource(res) {
  return `${RESOURCE_ROOT}${res}`;
}

function changeBackendStage(value) {
  if (value === "develop" || value === "develop-staging" || value === "local") {
    localStorage.setItem("local.stage", value);
    window.location.reload();
  }
}

const Config = {
  DEBUG_MODE, // 사용
  App, // 사용
  TYPE,
  NAVER_MAP_CLIENT_ID,
  NICE_MAIN_PAGE,
  NICE_LOGIN_PAGE,
  CDN_HOST_NICE,
  CDN_HOST_SNEK,
  DEEPSEARCH_QUERY_BASE64_ENCODING,
  DEEPSEARCH_API,
  DEEPSEARCH_API_HOST,
  DEEPSEARCH_EXPORT_API,
  DEEPSEARCH_COMPILE_API,
  SNEK_HAYSTACK_API,
  SNEK_WAVELET_SYMBOL_API,
  getPublicResource,
  changeBackendStage,
  HELP,
  VERSION_NAME,
  VERSION_NUMBER,
  DEEPSEARCH_APP_VERSION, // 사용
  DEEPSEARCH_TRIAL_FORM_URL,
  DEEPSERACH_PRODUCT_DEMO,
  DEEPSEARCH_PRODUCT_MENUAL,
  SIGNUP_TEST: SIGNUP_CHECK,
  BILLING_TEST: BILLING_CHECK,
  BLOG,
  TIER_CHECK,
  STAGE,
  BACKEND_STAGE,
};

if (DEBUG_MODE && process.env.NODE_ENV === "test") {
  console.log("STAGE:", STAGE);
  console.log("BACKEND_STAGE:", BACKEND_STAGE);
}

// DISABLE CONSOLE
window.console.debug = () => {};
if (process.env.NODE_ENV === "test") {
  window.console.log = () => {};
}

export default Config;
