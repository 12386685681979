import { AppInfo } from "../app-config";
import DangTalk from "../app-config/dangtalk";
import DB_FI from "../app-config/db-fi";
import DeepSearch from "../app-config/deepsearch";
import KAIST from "../app-config/kaist";
import KB from "../app-config/kb";
import NICE_KISLINE from "../app-config/kisline";
import LAGUNAI from "../app-config/lagunai";
import MIDASASSET from "../app-config/midasasset";
import Nice from "../app-config/nice-kisline";
import SACC from "../app-config/sacc";
import SAMSUNG_SECURITIES from "../app-config/samsungsecurities";
import SHINHAN_INVEST from "../app-config/shinhaninvest";
import SIMONE from "../app-config/simonefg";
import SSU from "../app-config/ssu";
import Config from "./Config";
import MIRAEASSET from "../app-config/miraeasset";
import STONEBRIDGE from "../app-config/stonebridge";
import MYANGEL from "../app-config/myangel";
import TRUEFRIEND from "../app-config/truefriend";
import HUNET from "../app-config/hunet";
import VALUESEARCH from "../app-config/valuesearch";
import KAKAOPAYSEC from "../app-config/kakaopaysec";
import SHINHAN_VC from "../app-config/shinhan-vc";
import PWC from "../app-config/pwc";
import LAIC from "../app-config/laic";

const DOMAIN_TYPE = {
  NICE: Nice,
  DEEPSEARCH: DeepSearch,
  DANGTALK: DangTalk,
  KB: KB,
  SHINHAN_INVEST: SHINHAN_INVEST,
  SAMSUNG_SECURITIES: SAMSUNG_SECURITIES,
  MIDASASSET: MIDASASSET,
  KAIST: KAIST,
  SIMONE: SIMONE,
  SSU: SSU,
  DB_FI: DB_FI,
  SACC: SACC,
  LAGUNAI: LAGUNAI,
  NICE_KISLINE: NICE_KISLINE,
  MIRAEASSET: MIRAEASSET,
  STONEBRIDGE: STONEBRIDGE,
  MYANGEL: MYANGEL,
  TRUEFRIEND: TRUEFRIEND,
  HUNET: HUNET,
  VALUESEARCH: VALUESEARCH,
  KAKAOPAYSEC: KAKAOPAYSEC,
  SHINHAN_VC: SHINHAN_VC,
  PWC: PWC,
  LAIC: LAIC,
};

class $DomainConfig {
  private _appId: string | null;
  private _appInfo: AppInfo | null;

  constructor() {
    if (localStorage.getItem("ds_domain")) {
      this._appId = localStorage.getItem("ds_domain");
      const app = Object.values(DOMAIN_TYPE).find((val) => {
        return val.appId == localStorage.getItem("ds_domain");
      });
      this._appInfo = app;
    } else {
      this._appId = Config.App.appId;
      const app = Object.values(DOMAIN_TYPE).find((val) => {
        return val.appId == localStorage.getItem("ds_domain");
      });
      this._appInfo = app;
    }
  }
  get appId() {
    return this?._appId ?? Config.App.appId;
  }
  set appId(appId: string) {
    this._appId = appId;
  }

  get appInfo() {
    return this?._appInfo ?? DeepSearch;
  }

  set appInfo(appInfo: AppInfo) {
    this._appInfo = appInfo;
  }

  setAppInfoById(id: string) {
    const app = Object.values(DOMAIN_TYPE).find((val) => {
      return val.appId == id;
    });
    this._appInfo = app;
    localStorage.setItem("ds_domain", id);
  }
}
const DomainConfig = new $DomainConfig();
export default DomainConfig;
