import _DataPod from "../_DataPod";
import { ITextListPodModel } from "../../../../../../model/compute/IPods";

export default class _TextList extends _DataPod implements ITextListPodModel {
  get toString() {
    if (this.content) {
      return JSON.stringify(this.content.data);
    } else {
      return JSON.stringify(this.content);
    }
  }

  get data() {
    if (this.content) {
      return this.content.data;
    } else {
      this.content;
    }
  }
}
