import {
  IAccountingStandardsModel,
  IDataPodModel,
  IInputPodModel,
  IMarketStatusPodModel,
  IResultSizeWarningPodModel,
  ISubPodModel,
} from "../../../../../model/compute/IPods";

import _Pod from "./_Pod";
import _AccountingStandards from "./subpods/_AccountingStandards";
import _MarketStatus from "./subpods/_MarketStatus";
import _ResultWarning from "./subpods/_ResultWarning";

export default class _DataPod extends _Pod implements IDataPodModel {
  inputPod: IInputPodModel;
  constructor(data: any, inputPod: IInputPodModel) {
    super(data);
    this.inputPod = inputPod;
  }
  get marketStatusPod(): IMarketStatusPodModel | null {
    const pods = this.subpods.filter((pod: ISubPodModel) => pod instanceof _MarketStatus);
    if (pods && pods.length > 0) {
      return pods[0] as IMarketStatusPodModel;
    }
    return null;
  }

  get resultSizeWarningPod(): IResultSizeWarningPodModel | null {
    const pods = this.subpods.filter((pod: ISubPodModel) => pod instanceof _ResultWarning);
    if (pods && pods.length > 0) {
      return pods[0] as IResultSizeWarningPodModel;
    }
    return null;
  }

  get accountingStandardsPod(): IAccountingStandardsModel | null {
    const pods = this.subpods.filter((pod: ISubPodModel) => pod instanceof _AccountingStandards);
    if (pods && pods.length > 0) {
      return pods[0] as IAccountingStandardsModel;
    }
    return null;
  }
}
