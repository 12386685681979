import { AppInfo } from ".";

const KAIST: AppInfo = {
  uid: "995430111161487360",
  name: "KAIST",
  displayName: "카이스트",
  appId: "deepsearch-kaist",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["kaist.deepsearch.com", "alpha-kaist.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "https://kaist.ac.kr/pcms/common/images/favicon.ico",
  appIcon: "https://kaist.ac.kr/pcms/common/images/favicon.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://kaist.ac.kr/kr/",
  logoUrl: "https://kaist.ac.kr/site/kr/img/layout/logo.svg",
  title_en: "KAIST - DeepSearch | Data for your decisions",
  title_ko: "KAIST - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default KAIST;
