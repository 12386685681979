import AuthModel from "./AuthModel";
import SubscriptionModel, { SubscriptionStatus } from "./billing/SubscriptionModel";

export default class CustomerModel extends AuthModel {
  get customerName(): string {
    return "";
  }

  get subscriptions(): SubscriptionModel[] {
    let subs: SubscriptionModel[] = [];
    if (this._data.subscriptions) {
      subs = this._data.subscriptions.map((s: any) => new SubscriptionModel(s, this));
    }
    return subs;
  }

  get enabledSubscriptions(): SubscriptionModel[] {
    return this.subscriptions.filter((subs) => subs.enabled);
  }

  get subscriptionsInUse(): SubscriptionModel[] {
    return this.enabledSubscriptions.filter(
      (subs) =>
        subs.status === SubscriptionStatus.NORMAL ||
        subs.status === SubscriptionStatus.SCHEDULED_TO_END ||
        subs.status === SubscriptionStatus.OVERDUE
    );
  }

  get hasSubscription(): boolean {
    let has = false;
    if (this.subscriptions.filter((subs) => subs.enabled).length > 0) {
      const items = this.subscriptions[0].items;
      has = items.length > 0;
    }
    return has;
  }

  get hasTrial(): boolean {
    return this.subscriptions.filter((subs) => subs.enabled).filter((subs) => subs.isTrial).length > 0;
  }

  get trialEndDate(): string | null {
    let date = null;
    const subsList = this.subscriptions.filter((subs) => subs.enabled).filter((subs) => subs.isTrial);
    if (subsList.length > 0) {
      date = subsList[0].trialEndDate;
    }
    return date;
  }

  get type(): "user" | "org" {
    return "user";
  }
}
