import { AppInfo } from ".";

const KB: AppInfo = {
  uid: "992850884243361792",
  name: "KB은행",
  displayName: "KB은행",
  appId: "deepsearch-kb",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["kbstar.deepsearch.com", "alpha-kbstar.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "https://www.kbstar.com/openimg/favi_iphone_n201512.png",
  appIcon: "https://www.kbstar.com/openimg/favi_iphone_n201512.png",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.kbstar.com",
  logoUrl: "https://oimg1.kbstar.com/img/ocommon/2015/kb_logo.png",
  title_en: "KB - DeepSearch | Data for your decisions",
  title_ko: "KB - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default KB;
