const moment = require("moment").default || require("moment");
import Locale from "../../resources/Locale";

export default class AuthModel {
  protected _data: any;
  constructor(data: any) {
    this._data = data;
  }

  get uid(): string {
    return this._data.uid_str;
  }

  get name(): string {
    return this._data.name;
  }

  get updatedDate(): string {
    return this._convertDate(this._data.updated_at);
  }

  get createdDate(): string {
    return this._convertDate(this._data.created_at);
  }

  get createdAt(): string {
    return this._data.created_at;
  }

  get deletedDate(): string {
    if (this._data.deleted_at) {
      return this._convertDate(this._data.deleted_at);
    }
    return this._data.deleted_at;
  }

  get enabled(): boolean {
    return this._data.enabled;
  }

  get data(): any {
    if (this._data.data) {
      return this._data.data;
    }
    return {};
  }

  get attributes(): any {
    return this._data.attributes;
  }

  protected _convertDate(value: string): string {
    if (value) {
      return moment(value).format(`${Locale?.format_date?.value ?? ""} ${Locale.format_time.value}`);
    }
    return "-";
  }
}
