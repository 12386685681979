import { AppInfo } from ".";

const ValueSearch: AppInfo = {
  uid: "1097195668298141696",
  name: "ValueSearch",
  displayName: "ValueSearch",
  appId: "deepsearch-valuesearch",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["valuesearch.deepsearch.com", "alpha-valuesearch.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "",
  favico: "https://www.niceinfo.co.kr/images/common/nice_logo.gif",
  appIcon: "https://www.niceinfo.co.kr/images/common/nice_logo.gif",
  helpUrl: "https://intelligent-drifter-3c5.notion.site/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://intelligent-drifter-3c5.notion.site/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.kisline.com",
  excelAddInList: [],
  logoUrl: "/public/partners/valuesearch-logo.png",
  title_en: "ValueSearch - DeepSearch | Data for your decisions",
  title_ko: "ValueSearch- DeepSearch | Data for your decisions",
  storeName: "딥서치",
  manage: "false",
};
export default ValueSearch;
