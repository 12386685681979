// import PapaParse from "papaparse";
// import Config from "../resources/Config";

import ManufacturingIFRS from "../csv/fs/ifrs/manufacturing.csv";
import BankIFRS from "../csv/fs/ifrs/bank.csv";
import SecuritiesFirmIFRS from "../csv/fs/ifrs/securities-firm.csv";
import LifeInsuaranceIFRS from "../csv/fs/ifrs/life-insurance.csv";
import NonLifeInsuaranceIFRS from "../csv/fs/ifrs/non-life-insurance.csv";
import SavingBankIFRS from "../csv/fs/ifrs/saving-bank.csv";
import GeneralFinancialIFRS from "../csv/fs/ifrs/general-financial.csv";
import TrustManagerIFRS from "../csv/fs/ifrs/trust-manager.csv";
import LeaseIFRS from "../csv/fs/ifrs/lease.csv";
import CreditCardIFRS from "../csv/fs/ifrs/credit-card.csv";
import NewTechFinanceIFRS from "../csv/fs/ifrs/new-tech-finance.csv";
import InstallmentFinancingIFRS from "../csv/fs/ifrs/installment-financing.csv";
import FinancialHoldingsIFRS from "../csv/fs/ifrs/financial-holdings.csv";

export interface StatementMap {
  reportId: string;
  depth: string;
  accountName: string;
  signed: number;
  index: number;
}

export interface StatementMapWrapper {
  [account: string]: StatementMap;
}

export interface ConsensusMap {
  revenue: string;
  netIncome: string;
  operatingIncome: string;
}

/**
 * 업종마다 재무상태표 Report id 가 다름
 */
export const FinancialStatementByReportId: { [typeId: string]: string } = {
  "11": "BalanceSheet",
  A1: "BalanceSheet",
  B1: "BalanceSheet",
  C1: "BalanceSheet",
  D1: "BalanceSheet",
  E1: "BalanceSheet",
  F1: "BalanceSheet",
  G1: "BalanceSheet",
  H1: "BalanceSheet",
  I1: "BalanceSheet",
  J1: "BalanceSheet",
  K1: "BalanceSheet",
  Z1: "BalanceSheet",

  // Income Statement

  "12": "Income",
  A2: "Income",
  B2: "Income",
  C2: "Income",
  D2: "Income",
  E2: "Income",
  F2: "Income",
  G2: "Income",
  H2: "Income",
  I2: "Income",
  J2: "Income",
  K2: "Income",
  Z2: "Income",

  // Cashflow Statement

  "16": "CashFlow",
  A4: "CashFlow",
  B4: "CashFlow",
  C4: "CashFlow",
  D4: "CashFlow",
  E4: "CashFlow",
  F3: "CashFlow",
  G4: "CashFlow",
  H4: "CashFlow",
  I4: "CashFlow",
  J4: "CashFlow",
  K4: "CashFlow",
  Z4: "CashFlow",
};

const FinancialStatementsMapIFRS: { [typeId: string]: string[][] } = {
  "11": ManufacturingIFRS,
  A1: BankIFRS,
  B1: SecuritiesFirmIFRS,
  C1: LifeInsuaranceIFRS,
  D1: NonLifeInsuaranceIFRS,
  E1: SavingBankIFRS,
  F1: GeneralFinancialIFRS,
  G1: TrustManagerIFRS,
  H1: LeaseIFRS,
  I1: CreditCardIFRS,
  J1: NewTechFinanceIFRS,
  K1: InstallmentFinancingIFRS,
  Z1: FinancialHoldingsIFRS,

  // Income Statement

  "12": ManufacturingIFRS,
  A2: BankIFRS,
  B2: SecuritiesFirmIFRS,
  C2: LifeInsuaranceIFRS,
  D2: NonLifeInsuaranceIFRS,
  E2: SavingBankIFRS,
  F2: GeneralFinancialIFRS,
  G2: TrustManagerIFRS,
  H2: LeaseIFRS,
  I2: CreditCardIFRS,
  J2: NewTechFinanceIFRS,
  K2: InstallmentFinancingIFRS,
  Z2: FinancialHoldingsIFRS,

  // Cashflow Statement

  "16": ManufacturingIFRS,
  A4: BankIFRS,
  B4: SecuritiesFirmIFRS,
  C4: LifeInsuaranceIFRS,
  D4: NonLifeInsuaranceIFRS,
  E4: SavingBankIFRS,
  F3: GeneralFinancialIFRS,
  G4: TrustManagerIFRS,
  H4: LeaseIFRS,
  I4: CreditCardIFRS,
  J4: NewTechFinanceIFRS,
  K4: InstallmentFinancingIFRS,
  Z4: FinancialHoldingsIFRS,
};

const ConsensusMapIFRS: { [typeId: string]: ConsensusMap } = {
  "12": {
    revenue: "1000",
    operatingIncome: "5000",
    netIncome: "8200",
  },
  A2: {
    revenue: "1200",
    operatingIncome: "5000",
    netIncome: "8290",
  },
  B2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  C2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  D2: {
    revenue: "1100",
    operatingIncome: "6000",
    netIncome: "8110",
  },
  E2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  F2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  G2: {
    revenue: "4000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  H2: {
    revenue: "4000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  I2: {
    revenue: "4050",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  J2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  K2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
  Z2: {
    revenue: "1000",
    operatingIncome: "6000",
    netIncome: "8200",
  },
};

export default class StatementMapProvider {
  static getFinancialStatementKey(reportIds: string[]): string | undefined {
    const statementMap = FinancialStatementsMapIFRS;
    const foundReportId = reportIds.find((id) => {
      return Object.keys(statementMap).find((fId: string) => fId === id);
    });
    return foundReportId;
  }

  static getFinancialStatementAccount(reportId: string, documentType: number): StatementMapWrapper {
    const statementMap = FinancialStatementsMapIFRS;
    const path = statementMap[reportId];
    if (path == null) {
      return null;
    }

    return this.getStatementMapWrapper(reportId, path, documentType);
  }

  static getConsensusStatement(reportId: string): ConsensusMap {
    return ConsensusMapIFRS[reportId];
  }

  static getStatementMapWrapper(reportId: string, result: string[][], column: number): StatementMapWrapper {
    const accountWrapper: StatementMapWrapper = {};
    result = result.filter((row) => row[0] == reportId && row[column] == "O");

    result.forEach(
      (row, i) =>
        (accountWrapper[`${row[1]}`] = {
          reportId: row[0],
          depth: row[3],
          accountName: row[5],
          signed: parseInt(row[6]),
          index: i,
        })
    );

    return accountWrapper;
  }
}
