interface Polarity {
  label: number;
  name: string;
  score: number;
}

interface Industry {
  label: number;
  name: string;
  score: number;
}
export default class DocumentModel {
  private _doc: any;
  /**
   *
   * @param {HayStack Document} doc
   */
  constructor(doc: any) {
    this._doc = doc;
  }

  get title(): string {
    return this._doc.title;
  }

  get author(): string {
    return this._doc.author;
  }

  get category(): string {
    return this._doc.category;
  }
  get section(): string {
    return this._doc.section;
  }
  get content_url(): string {
    return this._doc.content_url;
  }
  get contentUrl(): string {
    return this._doc.content_url;
  }
  get publisher(): string {
    return this._doc.publisher;
  }
  get createdAt(): string {
    return this._doc.created_at;
  }
  get created_at(): string {
    return this._doc.created_at;
  }
  get updatedAt(): string {
    return this._doc.updated_at;
  }
  get content(): string {
    return this._doc.content;
  }
  get securities(): any[] {
    return this._doc.securities;
  }
  get securitiesNames(): string[] {
    return this.securities.map((s: any) => s.name);
  }
  get securitiesSymbols(): string[] {
    return this.securities.map((s: any) => s.symbol);
  }
  get image_urls(): string {
    return this._doc.image_urls;
  }
  get clusteredDocs(): DocumentModel[] {
    return this._doc.clustered_docs;
  }
  get uid(): string {
    return this._doc.uid_str;
  }
  get highlight(): string {
    if (this._doc.highlight && this._doc.highlight.content) {
      return this._doc.highlight.content;
    }
    return this._doc.highlight;
  }

  get industry(): Industry | null {
    if (this._doc.industry) {
      return {
        label: this._doc.industry.label,
        name: this._doc.industry.name,
        score: this._doc.industry.score,
      };
    }
    return null;
  }

  get polarity(): Polarity | null {
    if (this._doc.polarity) {
      return {
        label: parseInt(this._doc.polarity.label, 10),
        name: this._doc.polarity.name,
        score: parseInt(this._doc.polarity.score, 10),
      };
    }
    return null;
  }
}
