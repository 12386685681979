import { IDocumentSearchPodModel } from "../../../../../../model/compute/IPods";
import DocumentsWrapperModel from "../../../../../../model/document/DocumentsWrapperModel";
import _DataPod from "../_DataPod";

export default class _DocumentSearch extends _DataPod implements IDocumentSearchPodModel {
  get document(): DocumentsWrapperModel | null {
    if (this.content) {
      return new DocumentsWrapperModel(this.content.data);
    }
    return null;
  }
}
