import { IInputPodModel } from "../../../../../model/compute/IPods";
import { TagModel, TagName } from "../../../../../model/compute/TagModel";
import _Pod from "./_Pod";
import CompanyModel from "../../../../../model/company/CompanyModel";

export default class _InputPod extends _Pod implements IInputPodModel {
  private _input: string;
  constructor(pod: any, input: string) {
    super(pod);
    this._input = input;
  }

  get input(): string {
    return this.content ? this.content.input : this._input;
  }

  get tagList(): TagModel[] {
    if (this.content) {
      const parser = new DOMParser();
      const rootNode = parser.parseFromString(this.content.detailed_interpretation.tagged, "text/html");
      const nodes = Array.from(rootNode.getElementsByTagName("*"));
      const tags = nodes
        .filter((node) => node.localName !== "html" && node.localName !== "head" && node.localName !== "body")
        .map((node) => new TagModel(node));
      if (tags.length > 0) {
        return tags;
      } else {
        return [
          {
            name: TagName.Keyword,
            value: this.rawTagged,
            type: "text",
            _node: null,
            fullValue: null,
            symbol: null,
          },
        ];
      }
    } else {
      return [
        {
          name: TagName.Keyword,
          value: this._input,
          type: "error",
          _node: null,
          fullValue: null,
          symbol: null,
        },
      ];
    }
  }

  get rawTagged(): any {
    if (this.content) {
      return this.content.detailed_interpretation.tagged;
    } else {
      return null;
    }
  }

  get propertyList(): string[] {
    return this.tagList.filter((tag) => tag.name === TagName.Property).map((tag) => tag.value || "");
  }

  get entityList(): string[] {
    return this.tagList.filter((tag) => tag.name === TagName.Entity).map((tag) => tag.value || "");
  }

  get dateRangeList(): string[] {
    return this.tagList.filter((tag) => tag.name === TagName.DateRange).map((tag) => tag.value || "");
  }
  get keywordList(): string[] {
    return this.tagList.filter((tag) => tag.name === TagName.Keyword).map((tag) => tag.value || "");
  }
  get functionList(): string[] {
    return this.tagList.filter((tag) => tag.name === TagName.Function).map((tag) => tag.value || "");
  }
  get dateList(): string[] {
    return this.tagList.filter((tag) => tag.name === TagName.Date).map((tag) => tag.value || "");
  }

  get companyList(): CompanyModel[] {
    let list: CompanyModel[] = [];
    // let names: any[] = [];
    this.tagList
      .filter((tag) => tag.name === TagName.Entity && tag.symbol)
      .forEach((tag) => {
        // if (names.indexOf(tag.value) < 0) {
        // 	names.push(tag.value);
        if (tag.value && tag.symbol) {
          list.push({
            name: tag.value,
            symbol: tag.symbol,
            entity_name: tag.value,
          });
        }
        // }
      });
    return list.sort((a: CompanyModel, b: CompanyModel) => {
      if (a.symbol.startsWith("KRX") && b.symbol.startsWith("KRX")) {
        return 0;
      } else if (a.symbol.startsWith("KRX") && !b.symbol.startsWith("KRX")) {
        return 1;
      } else {
        return -1;
      }
    });
  }
}
