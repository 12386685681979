import { IPodModel, ISubPodModel } from "../../../../../model/compute/IPods";
import _AccountingStandards from "./subpods/_AccountingStandards";
import _MarketStatus from "./subpods/_MarketStatus";
import _ResultWarning from "./subpods/_ResultWarning";

export default class _Pod implements IPodModel {
  private _data: any;
  constructor(data: any) {
    this._data = data;
  }

  get class(): string | null {
    return this._data ? this._data.class : null;
  }

  get content(): any | null {
    return this._data ? this._data.content : null;
  }

  get position(): number {
    return this._data ? this._data.position : -1;
  }

  get data(): any {
    return this._data;
  }

  get title(): string | null {
    return this._data ? this._data.title : null;
  }

  get subpods(): ISubPodModel[] {
    if (this.data) {
      return this._data.subpods.map((p: any) => {
        switch (p.class) {
          case "Footnote:MarketStatus":
            return new _MarketStatus(p);
          case "Footnote:AccountingStandards":
            return new _AccountingStandards(p);
          case "Warning:ResultSetSizeLimitExceeded":
            return new _ResultWarning(p);
          default:
            return new _Pod(p);
        }
      });
    } else {
      return [];
    }
  }

  get toString(): string {
    return JSON.stringify(this.content);
  }

  get hasNone(): boolean {
    if (this.content && this.content.data) {
      const data = this.content.data;
      if (data === "None") {
        return true;
      } else if (data.length && data[0] === "None") {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
