import { AppInfo } from ".";

const PWC: AppInfo = {
  uid: "1109879959557640192",
  name: "pwc",
  displayName: "삼일회계법인",
  appId: "deepsearch-pwc",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["pwc.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "",
  favico: "https://www.pwc.com/etc.clientlibs/pwc/clientlibs/css_common/resources/image/favicon.ico",
  appIcon: "https://www.pwc.com/etc.clientlibs/pwc/clientlibs/css_common/resources/image/favicon.ico",
  helpUrl: "https://intelligent-drifter-3c5.notion.site/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://intelligent-drifter-3c5.notion.site/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.pwc.com/kr",
  logoUrl:
    "https://www.pwc.com/etc.clientlibs/pwc/clientlibs/rebrand-clientlibs/components-colors/resources/images/slim-header-v2/PwC-logo.svg",
  title_en: "Samil PwC - DeepSearch | Data for your decisions",
  title_ko: "Samil PwC - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default PWC;
