import { AppInfo } from ".";

const DeepSearch: AppInfo = {
  uid: "1064551561486798848",
  name: "truefriend",
  displayName: "한국투자증권",
  appId: "deepsearch-truefriend",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["truefriend.deepsearch.com", "alpha-truefriend.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "",
  favico: "http://truefriend.com/favicon.ico",
  appIcon: "http://truefriend.com/favicon.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.truefriend.com",
  logoUrl: "https://www.truefriend.com/inc/img/gnb/logo.png",
  title_en: "한국투자증권 - DeepSearch | Data for your decisions",
  title_ko: "한국투자증권 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default DeepSearch;
