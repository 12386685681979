import { IDataFrame, IDataFramePodModel, IInputPodModel, IMetadata } from "../../../../../../model/compute/IPods";

import _DataPod from "../_DataPod";

export class _DataFrame implements IDataFrame {
  content: any;

  constructor(content: any) {
    this.content = content;
  }

  get columns(): string[] | null {
    if (this.content) {
      return this.content.columns;
    }
    return null;
  }

  get result(): any {
    if (this.content) {
      return this.content.data;
    }
    return null;
  }

  get dataTypes(): string[] | null {
    if (this.content) {
      return this.content.dtypes;
    }
    return null;
  }

  get index(): string[] | null {
    if (this.content) {
      return this.content.index;
    }
    return null;
  }

  getDataList(column: string | string[]): any[] {
    if (this.result) {
      if (typeof column === "string") {
        return this.result[column];
      } else {
        let dataList: any = null;
        column.forEach((c) => {
          if (!dataList && this.result[c]) {
            dataList = this.result[c];
          }
        });
        return dataList || [];
      }
    }
    return [];
  }
  getDataListByPos(pos: number): any[] {
    if (this.result && this.columns && this.columns.length > pos) {
      return this.result[this.columns[pos]];
    }
    return [];
  }
  getData(column: string | string[], index: number, defaultValue: any) {
    if (column) {
      let dataList: any = null;
      if (typeof column === "string") {
        dataList = this.getDataList(column);
      } else {
        column.forEach((c) => {
          if (!dataList) {
            dataList = this.getDataList(c);
          }
        });
      }
      if (dataList && dataList.length > index && dataList[index] !== undefined && dataList[index] !== null) {
        return dataList[index];
      }
    }
    return defaultValue;
  }
  getDataByPos(pos: number, index: number, defaultValue: any) {
    if (this.result && this.columns && this.columns.length > pos) {
      const columnIndex = this.columns[pos];
      const columnData = this.result[columnIndex];
      if (columnData && columnData[index] !== undefined && columnData[index] !== null) {
        return columnData[index];
      }
    }
    return defaultValue;
  }
}

class Metadata implements IMetadata {
  category_list: string[];
  category_count: number;
  macroeconomic_series: boolean;
  _result: any;

  constructor(inputPod: IInputPodModel, result: any) {
    this._result = result;
    this.category_count = 0;
    this.category_list = [];
    this.macroeconomic_series = false;

    if (result && result.data) {
      let columns = result.columns;
      //category list
      const ignoreColumns = ["date", "symbol", "entity_name"];
      this.category_list = columns.filter((f: string) => {
        if (ignoreColumns.indexOf(f) < 0) {
          const dataList = result.data[f].filter((e: any) => typeof e === "number");
          if (dataList.length === 0) {
            return false;
          }
          return true;
        }
        return false;
      });
      this.category_count = this.category_list.length;
      // check whether it is macroeconomic time series data
      const macroeconomics_sources = ["한국은행"];
      for (let source of macroeconomics_sources) {
        if (inputPod.rawTagged.indexOf(source) >= 0) {
          this.macroeconomic_series = true;
          break;
        }
      }
    }
  }

  _removeDuplicates(arr: any[]) {
    var obj: any = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] != null) {
        obj[arr[i]] = true;
      }
    }
    for (var key in obj) {
      if (key) ret_arr.push(key);
    }
    return ret_arr;
  }

  get isHistoricalData() {
    if (this.dateList.length > 1 && this.dateList.length !== this.symbolList.length) {
      return true;
    }
    return false;
  }

  get symbolList() {
    if (this._result && this._result.data) {
      const data = this._result.data;
      if (data.hasOwnProperty("symbol")) {
        return this._removeDuplicates(data["symbol"]);
      }
    }
    return [];
  }

  get dateList() {
    if (this._result && this._result.data) {
      const data = this._result.data;
      if (data.hasOwnProperty("date")) {
        return this._removeDuplicates(data["date"]);
      }
    }
    return [];
  }

  get isFiscalYearDates() {
    if (this.dateList.length > 0) {
      return true;
      //   return DSUtil.isFiscalYearDates(this.dateList);
    }
    return false;
  }

  get fiscalYearDateList() {
    return this.dateList;
    // return DSUtil.convertToFiscalYearDates(this.dateList);
  }

  get yearDateList() {
    return this.dateList;
    // return DSUtil.convertToFiscalYearDates(this.dateList, true);
  }
}

export default class _DataFramePod extends _DataPod implements IDataFramePodModel {
  private dataframe: IDataFrame;
  constructor(data: any, inputPod: IInputPodModel) {
    super(data, inputPod);
    this.dataframe = new _DataFrame(this.content);
  }

  get metaData(): IMetadata {
    return new Metadata(this.inputPod, this.content);
  }

  get companyList(): any[] {
    if (this.result && this.result.symbol) {
      let list: any[] = [];
      let symbols: any[] = [];
      this.result.symbol.forEach((symbol: string, index: number) => {
        if (symbols.indexOf(symbol) < 0) {
          symbols.push(symbol);
          list.push({
            symbol: symbol,
            name: this.result.entity_name[index],
          });
        }
      });
      return list;
    }
    return [];
  }
  get symbolList(): string[] {
    return this.companyList.map((company) => company.symbol);
  }

  get columns(): string[] | null {
    return this.dataframe.columns;
  }

  get result(): any {
    return this.dataframe.result;
  }

  get dataTypes(): string[] | null {
    return this.dataframe.dataTypes;
  }

  get index(): string[] | null {
    return this.dataframe.index;
  }

  getDataList(column: string): any[] {
    return this.dataframe.getDataList(column);
  }
  getDataListByPos(pos: number): any[] {
    return this.dataframe.getDataListByPos(pos);
  }
  getData(column: string | string[], index: number, defaultValue: any) {
    return this.dataframe.getData(column, index, defaultValue);
  }
  getDataByPos(pos: number, index: number, defaultValue: any) {
    return this.dataframe.getDataByPos(pos, index, defaultValue);
  }
}
