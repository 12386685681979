import _DataPod from "../_DataPod";
import { ISingleValuePodModel } from "../../../../../../model/compute/IPods";

export default class _SingleValue extends _DataPod implements ISingleValuePodModel {
  get value() {
    if (this.content && this.content.data) {
      return this.content.data;
    }
    return null;
  }

  get toString() {
    if (this.content) {
      return JSON.stringify(this.content.data);
    } else {
      return JSON.stringify(this.content);
    }
  }
}
