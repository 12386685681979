const moment = require("moment").default || require("moment");
import Locale from "../../../resources/Locale";
import AuthModel from "../AuthModel";
import CustomerModel from "../CustomerModel";
import { Tier } from "./item/ProductPlanModel";
import SubscriptionItemModel from "./item/SubscriptionItemModel";

/**
    pending: 아직 시작되지 않은 구독(미래에 시작될 구독. 시작 시점은 billing_cycle_anchor에 지정된 날짜)
    normal: 현재 진행 중인 구독
    suspended: enabled=False and canceled_at != null일 때로 구독이 잠시 정지된 상태 (현재 시점에서는 운영자가 정지시킨 경우 외엔 없음)
    canceled: 구독이 끝나기 전에 운영자에 의해 취소된 상태
    ended: 구독 기간이 만료된 상태 (subscription_end_at >= now)
    scheduled_to_end: 조만간 만료 예정인 구독 (5일 이내 만료)
 */

type Status = {
  id: string;
  name: string;
};

export enum Billing {
  Menual = "manual",
  Automatic = "automatic",
  Delegated = "delegated",
  Apple = "apple",
}

export const SubscriptionStatus: { [name: string]: Status } = {
  PENDING: {
    id: "pending",
    name: Locale.pending.value,
  },
  NORMAL: {
    id: "normal",
    name: Locale.using.value,
  },
  SUSPENDED: {
    id: "suspended",
    name: Locale.suspended.value,
  },
  CANCELED: {
    id: "canceled",
    name: Locale.canceled.value,
  },
  SCHEDULED_TO_END: {
    id: "scheduled_to_end",
    name: Locale.scheduled_to_end.value,
  },
  OVERDUE: {
    id: "overdue",
    name: Locale.payment_required.value,
  },
  ENDED: {
    id: "ended",
    name: Locale.ended.value,
  },
};

export interface InAppReceipt {
  productId: string;
  transactionId: string;
  expiresDate: string;
  purchasedDate: string;
  quantity: number;
  lastestReceipt: string;
  version: string;
  createdDate: string;
  environment: string;
}

export interface InAppStatus {
  id: string;
  platform: string;
  receipt: InAppReceipt;
}

export default class SubscriptionModel extends AuthModel {
  customer?: CustomerModel;
  constructor(data: any, c?: CustomerModel) {
    super(data);
    this.customer = c;
  }

  get nickName(): string {
    let name = null;
    if (this.customer) {
      name = this.customer.customerName;
    }
    if (this.isTrial) {
      name = `${this.tier ? `${this.tier.name} - ${Locale.trial.value}` : Locale.trial.value} (${name})`;
    } else if (this.isPeriodic) {
      name = `${this.tier ? this.tier.name : Locale.licensed_subscription.value} (${name})`;
    } else {
      name = `${this.tier ? this.tier.name : Locale.metered_subscription.value} (${name})`;
    }
    return name;
  }

  get billing(): Billing {
    return this._data.billing;
  }

  get cycleAnchor(): string {
    return this._data.billing_cycle_anchor;
  }

  get startDate(): string {
    return this._convertDate(this.cycleAnchor);
  }

  get endDate(): string {
    if (this.isTrial) {
      return this.trialEndDate;
    }
    return this._convertDate(this._data.subscription_end_at);
  }

  get periodStartedDate(): string {
    return this._convertDate(this._data.period_started_at);
  }

  get periodEndDate(): string {
    return this._convertDate(this._data.period_end_at);
  }

  get periodStartedAt(): string {
    return this._data.period_started_at;
  }

  get periodEndAt(): string {
    return this._data.period_end_at;
  }

  get canceldDate(): string {
    return this._convertDate(this._data.canceled_at);
  }

  get autoCancel(): boolean {
    return this._data.cancel_at_period_end;
  }

  get items(): SubscriptionItemModel[] {
    let items: SubscriptionItemModel[] = [];
    if (this._data.items) {
      items = this._data.items.map((item: any) => new SubscriptionItemModel(item));
    }
    return items;
  }

  get name() {
    let name = this._data.name;
    if (!name) {
      const sort = this.items.sort((a, b) => {
        if (a.plan.tier && b.plan.tier) {
          return b.plan.tier.value - a.plan.tier.value;
        }
        return 0;
      });
      if (sort.length > 0 && sort[0].plan.tier) {
        name = sort[0].plan.tier.name;
      }
    }
    return name;
  }

  get tiers(): Tier[] {
    const tiers: Tier[] = [];
    this.items
      .sort((a, b) => {
        if (a.plan.tier && b.plan.tier) {
          return b.plan.tier.value - a.plan.tier.value;
        }
        return 0;
      })
      .forEach((item) => {
        if (item.plan.tier) {
          tiers.push(item.plan.tier);
        }
      });
    return tiers;
  }
  get tier(): Tier | null {
    if (this.tiers.length > 0) {
      return this.tiers[0];
    }
    return null;
  }

  get isTrial(): boolean {
    return this._data.is_trial;
  }

  get isPeriodic(): boolean {
    return this._data.is_periodic;
  }

  get trialEndDate(): string {
    return this._convertDate(this._data.trial_end_at);
  }

  get subscriptionEndAt() {
    if (this.isTrial) {
      return this._data.trial_end_at;
    }
    return this._data.subscription_end_at;
  }

  get subscriptionEndDate() {
    return this._convertDate(this.subscriptionEndAt);
  }

  get subscriptionRemainDays(): number | null {
    if (this.subscriptionEndAt) {
      const today = moment();
      const endDate = moment(this.subscriptionEndAt);
      return Math.floor(moment.duration(endDate.diff(today)).asDays()) + 1;
    }
    return null;
  }

  get subscriptionTotalDays(): number | null {
    if (this.cycleAnchor && this.subscriptionEndAt) {
      const startDate = moment(this.cycleAnchor);
      const endDate = moment(this.subscriptionEndAt);
      return Math.floor(moment.duration(endDate.diff(startDate)).asDays()) + 1;
    }
    return null;
  }

  get status(): Status | null {
    let status = null;
    if (this._data.status) {
      for (let key in SubscriptionStatus) {
        if (SubscriptionStatus[key].id === this._data.status) {
          status = SubscriptionStatus[key];
          break;
        }
      }
    }
    return status;
  }

  get canUpgrade(): boolean {
    return (
      this.customer !== null && this.customer !== undefined && this.customer.type === "user" && this.items.length < 9
    );
  }

  get downgradeAt(): string | null {
    if (this.attributes && this.attributes["v2:downgrade_to"]) {
      return `${this.attributes["v2:downgrade_to"]}`;
    }
    return null;
  }

  get isDowngrading(): boolean {
    if (this.downgradeAt) {
      return true;
    }
    return false;
  }

  get isPaidByUser() {
    return this.billing === Billing.Delegated || this.billing === Billing.Apple;
  }

  get paidInApp() {
    return this.billing === Billing.Apple;
  }

  get inAppState(): InAppStatus {
    const receipt = this._data.data.receipt;
    const innerReceipt = receipt.receipt;
    const inApp = innerReceipt.in_app[0];

    const $receipt: InAppReceipt = {
      productId: inApp.product_id,
      environment: receipt.environment,
      lastestReceipt: receipt.latest_receipt,
      createdDate: innerReceipt.receipt_creation_date,
      version: innerReceipt.application_version,
      purchasedDate: inApp.purchase_date,
      expiresDate: inApp.expires_date,
      quantity: inApp.quantity,
      transactionId: inApp.transaction_id,
    };
    const status: InAppStatus = {
      id: this._data.data.bundle_id,
      platform: this._data.data.platform,
      receipt: $receipt,
    };
    return status;
  }

  get isOrg() {
    return this.customer ? this.customer.type === "org" : false;
  }
}
