import Config from "../../resources/Config";

import APIService, { ApiPromise } from "../APIService";
import translateModel from "../../model/function/Translate";

const STAGE = Config.BACKEND_STAGE;

const PAPAGO_API_HOST = {
  production: "https://www.deepsearch.com/api/translation",
  develop: "https://app.dswalk.com/api/translation",
  local: "https://app.dswalk.com/api/translation",
};

const PAPAGO_URL = PAPAGO_API_HOST[STAGE];

export default class TranslateAPIService {
  static translate = (params: translateModel) => {
    const api = APIService.get(PAPAGO_URL, params);
    return new ApiPromise((resolve: any, reject: any) => {
      api
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    }, api.cancelSource);
  };
}
