export const DETAIL_FS_FORM_COLUMN = 7;
export const COMPACT_FS_FORM_COLUMN = 9;
export const NICE_DETAIL_FS_FORM_COLUMN = 11;
export const NICE_COMPACT_FS_FORM_COLUMN = 13;

interface documentInfo {
  documentName: string;
  documentDescription: string;
  documentColumn: number;
}
export const document_choice_data: documentInfo[] = [
  {
    documentName: "detail_fs_form",
    documentDescription: "detail_fs_form_description",
    documentColumn: DETAIL_FS_FORM_COLUMN,
  },
  {
    documentName: "compact_fs_form",
    documentDescription: "compact_fs_form_description",
    documentColumn: COMPACT_FS_FORM_COLUMN,
  },
  {
    documentName: "nice_detail_fs_form",
    documentDescription: "nice_detail_fs_form_description",
    documentColumn: NICE_DETAIL_FS_FORM_COLUMN,
  },
  {
    documentName: "nice_compact_fs_form",
    documentDescription: "nice_compact_fs_form_description",
    documentColumn: NICE_COMPACT_FS_FORM_COLUMN,
  },
];

// reportId,accountId,parentAccountId,LV,accountName,name,연결전체,전체사용,연결약식_DeepSearch,별도약식_DeepSearch2,연결약식_나이스,약식사용_나이스
// 0, 1, 2, 3, 4, 5;
