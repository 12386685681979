import moment from "moment";
import Locale from "../../../resources/Locale";
import { ProductPlan } from "./item/ProductPlanModel";
import SubscriptionItemModel from "./item/SubscriptionItemModel";
import SubscriptionModel, { Billing, SubscriptionStatus } from "./SubscriptionModel";

class UnlimitSubscriptionItemModel extends SubscriptionItemModel {
  get itemId() {
    return ProductPlan.UNLIMIT_FAKE_EXCEL.id;
  }
  get quantity() {
    return 99999999999;
  }

  get usage() {
    return 0;
  }

  get plan(): any {
    return {};
  }

  get name() {
    return ProductPlan.UNLIMIT_FAKE_EXCEL.name;
  }

  get amount() {
    return 9999999999;
  }

  get months() {
    return 12;
  }
}

export default class UnlimitSubscriptionModel extends SubscriptionModel {
  get cycleAnchor() {
    return "";
  }

  get startDate() {
    return this._convertDate(moment().format("YYYY-MM-DD"));
  }

  get endDate() {
    return this._convertDate(moment().add(this.items[0].months, "months").format("YYYY-MM-DD"));
  }

  get canceldDate() {
    return "-";
  }

  get autoCancel() {
    return false;
  }

  get items() {
    return [new UnlimitSubscriptionItemModel(null)];
  }

  get name() {
    return Locale.unlimit.value;
  }

  get isTrial() {
    return false;
  }

  get trialEndDate() {
    return "-";
  }

  get subscriptionEndAt() {
    return null;
  }

  get status() {
    return SubscriptionStatus.NORMAL;
  }

  get enabled() {
    return true;
  }

  get isPeriodic() {
    return true;
  }

  get nickName() {
    return this.name;
  }

  get periodEndDate() {
    return "";
  }

  get periodStartedAt() {
    return "";
  }

  get billing(): Billing {
    return Billing.Menual;
  }
}
