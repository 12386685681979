import AuthModel from "../../AuthModel";
import ProductPlanModel from "./ProductPlanModel";

export default class SubscriptionItemModel extends AuthModel {
  get itemId(): string {
    return this.plan.productId;
  }

  get quantity(): number {
    if (this._data.quantity !== undefined) {
      return this._data.quantity;
    }
    return 0;
  }

  get usage(): number {
    if (this._data.current_usage) {
      return this._data.current_usage;
    }
    return 0;
  }

  get remain(): number {
    if (this.unlimit) {
      return 9999;
    }
    return this.quantity - this.usage;
  }

  get plan(): ProductPlanModel {
    return new ProductPlanModel(this._data.plan);
  }

  get name(): string {
    let name = this._data.name;
    if (!name && this.plan) {
      return this.plan.localizationName;
    }
    return name;
  }

  get amount(): number {
    return this.plan.amount;
  }

  get months(): number {
    return this.plan.months;
  }

  get currency(): string {
    return this.plan.currency;
  }

  get unlimit(): boolean {
    return this.quantity === -1;
  }
}
