import { AppInfo } from ".";

const SACC: AppInfo = {
  uid: "997977711790133248",
  name: "세화회계법인",
  displayName: "세화회계법인",
  appId: "deepsearch-sacc",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["sacc.deepsearch.com", "alpha-sacc.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "",
  appIcon: "",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "http://www.sacc.co.kr/",
  logoUrl: "/public/partners/sacc-logo.png",
  title_en: "세화회계법인 - DeepSearch | Data for your decisions",
  title_ko: "세화회계법인 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default SACC;
