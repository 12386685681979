import { AppInfo } from ".";

const DangTalk: AppInfo = {
  uid: "958095652255043584",
  name: "DANGTALK",
  displayName: "D.RESEARCH",
  appId: "deepsearch-dangtalk",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["dangtalk.deepsearch.com", "dev-dangtalk.deepsearch.com", "alpha-dangtalk.deepsearch.com"],
  chTalkPluginKey: "89db6215-d306-492b-9b15-ea0524b9f39e",
  blogUrl: "https://blog.naver.com/rinbi0",
  favico: "/dresearch_favicon.ico",
  appIcon: "/dresearch_favicon.ico",
  helpUrl: "https://www.notion.so/56e4643f44f24ff3b34e555f9d06676b",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://dangtalk.deepsearch.com",
  homepage: "https://dresearch.kr/",
  logoUrl: "/public/partners/d-research-logo.png",
  title_en: "디리서치 AI 인공지능 기업분석보고서 – 기업을 알아야 취업이 보인다.",
  title_ko: "디리서치 AI 인공지능 기업분석보고서 – 기업을 알아야 취업이 보인다.",
  storeName: "디리서치 로그인",
  excelAddInList: [],
};

export default DangTalk;
