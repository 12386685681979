import { IMarketStatusPodModel } from "../../model/compute/IPods";
const moment = require("moment").default || require("moment");
import { useEffect, useState } from "react";
import * as Rx from "rxjs";
import DSUtil from "../../utils/DSUtil";

import { IComputeModel } from "../../model/compute/IComputeModel";
import Config from "../../resources/Config";
import APIService, { ApiPromise, Cache, Params } from "../APIService";
import _Compute from "./response/compute/_Compute";

interface Request {
  input: string;
  headers: Params;
}

const MarketStatusSubject = new Rx.ReplaySubject<IMarketStatusPodModel>(1);
var lastMarketPod: IMarketStatusPodModel | null = null;
const MAX_CONTENT_LENGTH = 2048;

export const createApi = (apiPromise: ApiPromise<any>): ApiPromise<any> => {
  return new ApiPromise((resolve: any, reject: any) => {
    apiPromise
      .then((response: any) => {
        // if (response && response.data && response.data.success) {
        //     if (response.data.data.pods) {
        //         response.data.data.pods.forEach((pod: any) => checkPod(pod));
        //     }
        // }
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  }, apiPromise.cancelSource);
};

export default class ComputeAPIService {
  static makeRequest(input: string): Request {
    let request: Request = { input: input, headers: {} };
    const token = localStorage.getItem("token");
    if (Config.DEEPSEARCH_QUERY_BASE64_ENCODING) {
      request = {
        input: btoa(encodeURIComponent(input)),
        headers: {
          "X-DeepSearch-Encoded-Input": "true",
        },
      };
    }
    if (token) {
      request = {
        ...request,
        headers: {
          ...request.headers,
          Cookie: `"_ds_token=${token};"`,
          max_result_size: "50000",
          "X-DeepSearch-User-Token": token,
        },
      };
    }
    return request;
  }

  /**
   * @param {string} input
   * @param {object} options {
   *      max_result_size : 1000
   * }
   */
  static compute(input: string, options?: Params | any, needDelegateHandler?: boolean): ApiPromise<IComputeModel> {
    const request = this.makeRequest(input);
    const params = {
      input: request.input,
      max_result_size: "50000",
      ...options,
    };
    if (needDelegateHandler) {
      const apiPromise = APIService.post(Config.DEEPSEARCH_API, params, request.headers);
      return createApi(apiPromise);
    } else {
      const apiPromise = APIService.post(Config.DEEPSEARCH_API, params, request.headers);
      return new ApiPromise((resolve: any, reject: any) => {
        createApi(apiPromise)
          .then((response: any) => {
            if (response && response.data && response.data.success) {
              const compute = new _Compute(input, response.data.data);
              if (compute.dataPod && compute.dataPod.marketStatusPod) {
                this.publishMarketState(compute.dataPod.marketStatusPod);
              }
              resolve(compute);
            } else {
              resolve(new _Compute(input, null, response));
            }
          })
          .catch((error: any) => {
            reject(new _Compute(input, null, error));
          });
      }, apiPromise.cancelSource);
    }
  }

  static computeWithCache(
    input: string,
    cache: Cache,
    options?: Params | any,
    needDelegateHandler?: boolean
  ): ApiPromise<IComputeModel> {
    const request = this.makeRequest(input);
    const params = {
      input: request.input,
      max_result_size: "50000",
      ...options,
    };
    if (needDelegateHandler) {
      const apiPromise = APIService.cacheGet(Config.DEEPSEARCH_API, params, request.headers, null, cache);
      return createApi(apiPromise);
    } else {
      let apiPromise: any = null;
      const isLargeContent =
        JSON.stringify(Config.DEEPSEARCH_API).length + JSON.stringify(params).length > MAX_CONTENT_LENGTH;
      if (DSUtil.isIE() || isLargeContent) {
        apiPromise = APIService.post(Config.DEEPSEARCH_API, params, request.headers, options);
      } else {
        apiPromise = APIService.cacheGet(Config.DEEPSEARCH_API, params, request.headers, null, cache);
      }
      return new ApiPromise((resolve: any, reject: any) => {
        createApi(apiPromise)
          .then((response: any) => {
            if (response && response.data && response.data.success) {
              const compute = new _Compute(input, response.data.data);
              if (compute.dataPod && compute.dataPod.marketStatusPod) {
                this.publishMarketState(compute.dataPod.marketStatusPod);
              }
              resolve(compute);
            } else {
              resolve(new _Compute(input, null, response));
            }
          })
          .catch((error: any) => {
            reject(new _Compute(input, null, error));
          });
      }, apiPromise.cancelSource);
    }
  }

  static compile(input: string): ApiPromise<IComputeModel> {
    const request = this.makeRequest(input);
    const params = {
      input: request.input,
    };
    const apiPromise = APIService.post(Config.DEEPSEARCH_COMPILE_API, params, request.headers);
    return new ApiPromise((resolve: any, reject: any) => {
      createApi(apiPromise)
        .then((response: any) => {
          if (response && response.data && response.data.success) {
            const compute = new _Compute(input, response.data.data);
            if (compute.dataPod && compute.dataPod.marketStatusPod) {
              this.publishMarketState(compute.dataPod.marketStatusPod);
            }
            resolve(compute);
          } else {
            resolve(new _Compute(input, null, response));
          }
        })
        .catch((error: any) => {
          reject(new _Compute(input, null, error));
        });
    }, apiPromise.cancelSource);
  }

  /**
   * @param {string} app
   * @param {string} query
   */
  static auth(app: string, query: string): ApiPromise<IComputeModel> {
    const request = this.makeRequest(query);
    const params = {
      input: request.input,
    };
    request.headers["X-DeepSearch-App-ID"] = app;
    const apiPromise = APIService.post(Config.DEEPSEARCH_API, params, request.headers, undefined, true);
    return new ApiPromise((resolve: any, reject: any) => {
      apiPromise
        .then((response: any) => {
          if (response && response.data && response.data.success) {
            resolve(new _Compute(query, response.data.data));
          } else {
            resolve(new _Compute(query, null, response));
          }
        })
        .catch((error: any) => {
          reject(new _Compute(query, null, error));
        });
    }, apiPromise.cancelSource);
  }

  /**
   *
   * @param {string} input
   */
  static export(input: string): ApiPromise<any> {
    const request = this.makeRequest(input);
    const params = {
      input: request.input,
      ts: Date.now(),
    };
    return APIService.post(Config.DEEPSEARCH_EXPORT_API, params, request.headers, {
      responseType: "blob",
    });
  }

  static marketStatusObservable() {
    return MarketStatusSubject;
  }

  static clear() {
    MarketStatusSubject.complete();
  }

  static publishMarketState(marketPod: IMarketStatusPodModel) {
    if (lastMarketPod) {
      if (lastMarketPod.referenceTime && marketPod.referenceTime) {
        const diff = moment(lastMarketPod.referenceTime).diff(moment(marketPod.referenceTime));
        if (diff < 0) {
          lastMarketPod = marketPod;
        }
        MarketStatusSubject.next(lastMarketPod);
      } else {
        MarketStatusSubject.next(marketPod);
      }
    } else {
      lastMarketPod = marketPod;
      MarketStatusSubject.next(marketPod);
    }
  }
}

export const useCompute = (
  input: string,
  dependencies: any[],
  options?: Params | any,
  needDelegateHandler?: boolean
) => {
  const [compute, setCompute] = useState<IComputeModel | null>(null);
  useEffect(() => {
    const api = ComputeAPIService.compute(input, options, needDelegateHandler);
    api.then((c) => setCompute(c)).catch((c) => setCompute(c));
    return () => {
      api.cancel();
    };
    // eslint-disable-next-line
  }, [...dependencies]);

  return compute;
};

export const useComputeWithCache = (
  input: string,
  cache: Cache,
  dependencies: any[],
  options?: Params | any,
  needDelegateHandler?: boolean
) => {
  const [compute, setCompute] = useState<IComputeModel | null>(null);
  useEffect(() => {
    const api = ComputeAPIService.computeWithCache(input, cache, options, needDelegateHandler);
    api.then((c) => setCompute(c)).catch((c) => setCompute(c));
    return () => {
      api.cancel();
    };
    // eslint-disable-next-line
  }, [...dependencies]);
  return compute;
};
