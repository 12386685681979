import { ProductPlan } from "../billing/item/ProductPlanModel";
import SubscriptionModel from "../billing/SubscriptionModel";
import CustomerModel from "../CustomerModel";

export default class OrganizationModel extends CustomerModel {
  get customerName(): string {
    return this.name;
  }

  get displayName(): string {
    return this._data.display_name;
  }

  get email(): string {
    return this._data.email;
  }

  get subscriptions(): SubscriptionModel[] {
    let subs: SubscriptionModel[] = [];
    if (this._data.subscriptions) {
      subs = this._data.subscriptions.map((s: any) => new SubscriptionModel(s, this));
    }
    // /**
    //  * Subscription이 없을 경우 무제한이다.
    //  */
    // if (subs.length === 0) {
    //     subs.push(new UnlimitSubscriptionModel(null, this));
    // }
    return subs;
  }

  get seatMemberSubscriptionItem() {
    const subs = this.subscriptions.filter((subs) => subs.enabled);
    /**
     * Subscription이 없을 경우 무제한이다.
     */
    if (subs.length > 0) {
      for (let i = 0; i < subs.length; i++) {
        const sub = subs[i];
        for (let j = 0; j < sub.items.length; j++) {
          const item = sub.items[j];
          if (item.itemId === ProductPlan.MEMBER_SEAT_LICENSE.id) {
            return item;
          }
        }
      }
    }
    return null;
  }

  get type(): "user" | "org" {
    return "org";
  }
}
