import { ApiPromise } from "../network/APIService";
import { IComputeModel } from "../model/compute/IComputeModel";
import ComputeAPIService from "../network/api/ComputeAPIService";
import computeParser from "../utils/ComputeParser";
import TranslateAPIService from "../network/api/TranslateAPIService";
import translateModel from "../model/function/Translate";

import StatementMapProvider, { StatementMapWrapper } from "../services/StatementMapProvider";
import { NICE_DETAIL_FS_FORM_COLUMN } from "../taskpane/pages/DownloadPage/step_two/document_choice_data";

import Locale from "../resources/Locale";

interface IBatchEntry {
  operation: string;
  args: object;
  resolve: (data: any) => void;
  reject: (error: Error) => void;
}

const MAX_BATCH_SIZE = 50;
const PAUSE_TIME = 3500;

const _batch: IBatchEntry[] = [];
let _isBatchedRequestScheduled = false;
const cached_keys: string[] = [];

// export async function _popOperation(operation: string, args: object) {
//   const invocationEntry: IBatchEntry = {
//     operation: operation, // e.g. sum
//     args: args,
//     resolve: undefined,
//     reject: undefined,
//   };

//   const index: number = _batch.indexOf(invocationEntry);
//   _batch.splice(index, 1);
// }

export const _pushOpreation = (operation: string, args: object) => {
  const invocationEntry: IBatchEntry = {
    operation: operation, // e.g. sum
    args: args,
    resolve: undefined,
    reject: undefined,
  };

  // Create a unique promise for this invocation,
  // and save its resolve and reject functions into the invocation entry.
  const promise = new Promise((resolve, reject) => {
    invocationEntry.resolve = resolve;
    invocationEntry.reject = reject;
  });

  const key = buildCacheKey(operation, args);

  if (cached_keys.includes(key)) {
    const res = _processSingleRequest(operation, args);
    if (res.result) {
      return res?.result?.then((result) => {
        return result;
      });
    }
  }
  // Push the invocation entry into the next batch.
  _batch.push(invocationEntry);

  // If a remote request hasn't been scheduled yet,
  // schedule it after a certain timeout, e.g. 100 ms.

  if (!_isBatchedRequestScheduled) {
    _isBatchedRequestScheduled = true;
    setTimeout(_makeRemoteRequest, PAUSE_TIME);
  }

  // Return the promise for this invocation.
  return promise;
};

async function _makeRemoteRequest() {
  // Copy the shared batch and allow the building of a new batch while you are waiting for a response.
  // Note the use of "splice" rather than "slice", which will modify the original _batch array
  // to empty it out.

  while (_batch.length > 0) {
    const batch_size = _batch.length > MAX_BATCH_SIZE ? MAX_BATCH_SIZE : _batch.length;
    const batchCopy = _batch.splice(0, batch_size);
    _isBatchedRequestScheduled = false;

    // Build a simpler request batch that only contains the arguments for each invocation.
    const requestBatch = batchCopy.map((item) => {
      return { operation: item.operation, args: item.args };
    });

    // Make the remote request.
    _fetchFromRemoteService(requestBatch).then((responseBatch) => {
      // Match each value from the response batch to its corresponding invocation entry from the request batch,
      // and resolve the invocation promise with its corresponding response value.
      responseBatch.forEach((response, index) => {
        if (response.error) {
          batchCopy[index].reject(new Error(response.error));
        } else {
          batchCopy[index].resolve(response.result);
        }
      });
    });
    await pause(PAUSE_TIME);
  }
}

async function _fetchFromRemoteService(requestBatch: Array<{ operation: string; args: object }>): Promise<any[]> {
  // Simulate a slow network request to the server;
  return requestBatch.map((request) => {
    const { operation, args } = request;
    return _processSingleRequest(operation, args);
  });
}

const _processSingleRequest = (operation: string, args: object) => {
  try {
    if (operation === "query") {
      let input: string = args["input"];

      const api = ComputeAPIService.computeWithCache(input, {
        maxAge: 12,
        unit: "hour",
      });
      return {
        result: new ApiPromise((resolve) => {
          api
            .then((compute: IComputeModel) => {
              // console.log(encodeURIComponent(operation + input + "&locale=" + Locale.language));
              // store.setItem(key, compute);
              const result = handleQueryComputeResult(compute, args);
              const key = buildCacheKey(operation, args);
              if (!cached_keys.includes(key)) {
                cached_keys.push(key);
              }

              resolve(result);
            })
            .catch(() => {
              resolve([["올바르지 않은 검색어입니다."]]);
            });
        }, api.cancelSource),
      };
    } else if (operation === "fs") {
      const account: string = args["account"];
      const FSQuery: string = args["FSQuery"];
      const api = ComputeAPIService.computeWithCache(FSQuery, {
        maxAge: 12,
        unit: "hour",
      });
      // const key = buildCacheKey(operation, args);
      return {
        result: new ApiPromise((resolve) => {
          api
            .then((compute: IComputeModel) => {
              const { data } = computeParser(compute.dataPod);

              const reportAccountWrapper: StatementMapWrapper = StatementMapProvider.getFinancialStatementAccount(
                data[1][4],
                NICE_DETAIL_FS_FORM_COLUMN
              );

              const parsed_data = data.filter((row) => {
                return row[4] === account.substring(0, 2) && row[5] === account.substring(2);
              });

              if (parsed_data.length > 0) {
                const key = buildCacheKey(operation, args);
                if (!cached_keys.includes(key)) {
                  cached_keys.push(key);
                }

                resolve(parsed_data[0][7]);
              } else if (reportAccountWrapper.hasOwnProperty(account.substring(2))) {
                resolve("0");
              } else {
                resolve("올바르지않은 계정정보입니다.");
              }
            })
            .catch(() => {
              resolve("올바르지않은 검색어입니다.");
            });
        }, api.cancelSource),
      };
    } else if (operation === "translate") {
      const post_body: translateModel = args["post_body"];
      const api = TranslateAPIService.translate(post_body);
      return {
        result: new ApiPromise((resolve) => {
          api
            .then((response: any) => {
              const {
                data: { translatedText },
              } = response;
              // store.setItem(key, translatedText);
              resolve(translatedText);
            })
            .catch(() => {
              resolve([["올바르지 않은 검색어입니다."]]);
            });
        }, api.cancelSource),
      };
    } else {
      return {
        error: `Operation not supported: ${operation}`,
      };
    }
  } catch (error) {
    return {
      error: `Operation failed: ${operation}`,
    };
  }
};

const buildCacheKey = (operation: string, args: object) => {
  if (operation === "query") {
    const input: string = args["input"] ?? "";
    return encodeURIComponent(operation + input + "&locale=" + Locale.language);
  } else if (operation === "fs") {
    const input: string = args["FSQuery"] ?? "";
    return encodeURIComponent(operation + input + "&locale=" + Locale.language);
  } else if (operation === "translate") {
    const input: translateModel = args["post_body"] ?? "";
    return encodeURIComponent(operation + JSON.stringify(input) + "&locale=" + Locale.language);
  } else {
    return "";
  }
};

const handleQueryComputeResult = (compute: IComputeModel, args: object) => {
  const { data } = computeParser(compute.dataPod);
  const rows: number = args["rows"] ?? 0;
  const cols: number = args["cols"] ?? 0;

  let height: number = args["height"];
  let width: number = args["width"];

  if (height == null) {
    height = data.length;
  } else {
    height = rows + height;
  }

  if (width == null) {
    width = data[0].length;
  } else {
    width = cols + width;
  }

  const parsedData: string[][] = data.slice(rows, height).map((row) => row.slice(cols, width));
  return parsedData;
};

function pause(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
