// prettier-ignore
export default {
    deepsearch: "딥서치",
    about_us: "회사소개",
    app: "Analytics",
    start_app: "딥서치 시작하기",
    home: "홈",
    go_homepage: "홈페이지로 돌아가기",
    valuation_service: "가치평가 서비스",
    footer: "DeepSearch의 모든 컨텐츠는 저작권법에 의거 보호받고 있습니다.",
    valuesearch_footer: "ValueSearch의 모든 컨텐츠는 저작권법에 의거 보호받고 있습니다.",
    term_of_use: "서비스 이용약관",
    privacy_policy: "개인정보 보호정책",
    news_room: "뉴스룸",
    my_subscription: "MY",
    interested_industry: "관심 산업",
    /* Side Menu */
    home_menu: "홈",
    search_query_menu: "기업데이터",
    fs_menu: "재무제표",
    template_menu: "템플릿",
    function_menu: "함수",
    welcome_to_addin: "{0}{br}에 오신것을 환영합니다!",
    switching_language: "언어를 변경하고 있습니다...",
    /* Login */
    login_description: "엑셀에서 방대한 금융/기업/경제/문서 데이터를 손쉽게 다운로드하고, 분석해 보세요.",
    /* domain picker */
    domain_selection: "도메인 선택",
    domain_selection_description: "해당 계정은 2개이상의 도메인에 가입되어있습니다.{br}로그인할 도메인을 선택해주세요.",
    back_to_login: "다시 로그인하기",
    /* Home Page */
    what_we_can_do: "무엇을 할 수 있나요?",
    first_usecase: "기업, 산업, 인물, 경제 지표 등 방대한 기업/금융 데이터를 엑셀에서 손쉽게 <LINK>조회</LINK>하고 사용하실 수 있습니다.",
    second_usecase: "2,500개의 상장기업들과 30만개 이상의 비상장기업들의 10년치 재무제표를 <LINK>다운로드</LINK> 받을 수 있습니다",
    third_usecase: "뉴스, 공시, 증권사리포트, IR, 특허 등 다양한 종류의 문서 데이터를 손쉽게 내려받고 분석할 수 있습니다.",
    fourth_usecase: "미리 만들어진 재무분석, 산업분석 등 <LINK>템플릿을 활용</LINK>하여 빠르게 원하는 분석을 할 수 있습니다.",
    /* Download Page */
    financial_statement: "재무제표",
    search_company: "기업검색",
    select_option: "옵션선택",
    setting_isneeded: " 설정이 필요합니다",
    error_fetching_fs: "FS 요청시 오류가 발생했습니다.",
    error_fetching_available_fs: "사용 가능한 FS 요청시 오류가 발생했습니다.",
    no_fs_report_error: "검색된 기업의 재무제표가 없습니다.",
    /* Step 1 */
    search_result_count: `검색 결과 {count}건`,
    select_result_count: `선택 결과 {count}건`,
    list_no_more: "목록의 끝입니다",
    search_text_suggestion_1: "･ 단어의 철자가 정확한지 확인해 보세요.",
    search_text_suggestion_2: "･ 두 단어 이상의 검색어인 경우, 띄어쓰기를 확인해 보세요.",
    search_text_suggestion_3: "･ 한글을 영어로 혹은 영어를 한글로 입력했는지 확인해 보세요.",
    max_search_limit: "한번에 검색할 수 있는 기업수를 초과하셨습니다. (최대 40개 검색)",
    /* Step 2 */
    fs_option_selection: "재무제표 옵션설정",
    inquired_fs: "조회 재무제표",
    multiple_selection: "복수선택",
    consolidated_standalone_setting: "연결/별도",
    if_not_standalone: "없을 시 별도",
    consolidated: "연결",
    standalone: "별도",
    consolidated_or_standalone: "연결|별도",
    yearly_quarterly_setting: "분기/연도",
    accumulated_incremental_setting: "누적/증분",
    accumulated: "누적",
    incremental: "증분",
    show_data_setting: "데이터가 없는 항목 표시",
    show: "표시",
    hide: "숨김",
    duration: "기간",
    from: "From",
    until: "Until",
    report_selection: "보고서 선택",
    compact_fs_form: "약식재무제표",
    detail_fs_form: "상세재무제표",
    nice_compact_fs_form: "약식재무제표(나이스기준)",
    nice_detail_fs_form: "상세재무제표(나이스기준)",
    compact_fs_form_description: "약식 재무계정정보를 제공합니다.",
    detail_fs_form_description: "상세 재무계정정보를 제공합니다.",
    nice_compact_fs_form_description: "나이스 기준의 약식 재무계정정보를 제공합니다.",
    nice_detail_fs_form_description: "나이스 기준의 상세 재무계정정보을 제공합니다.",

    financial_statement_excel: "재무상태표",
    income_statement_excel: "손익계산서",
    caseflow_statement_excel: "현금흐름표",
    report_type_header: "보고서타입",
    account_code_header: "계정코드",
    depth_header: "LV",
    account_subject_header: "계정과목",
    /* Function Page */
    function_page_title: "Function 기능설명",
    function_search: "함수 검색",
    /* Function Download */
    download_begin: "다운로드가 곧 시작됩니다.",
    download_ready: "파일이 준비되었습니다. 이제 이 탭을 닫을 수 있습니다.",
    download_error: "오류가 발생했습니다. 탭을 닫고 다시 다운로드하십시오.",
    /* Template Download */
    template_page_title: "템플릿", 
    search_template: "템플릿 검색",
    /* Query Page */
    query_page_title: "기업데이터 Download",
    select_data: "데이터선택",
    search_data: "데이터검색",
    financial_data: "재무데이터",
    market_data: "시장데이터",
    overview_data: "개요데이터",
    option_selection: "옵션설정",
    use_option: "적용",
    /* Screener Page */
    screener_page_title: "기업 스크리너",
    screener_address: "주소",
    screener_address_placeholer: "키워드 입력: (예: 서울 강남구)",
    screener_address_label: "에 위치한 기업을 검색합니다.",
    screener_document: "문서",
    screener_document_placeholer: "키워드 입력: (예: 이차전지)",
    screener_document_label: "포함된 기업을 검색합니다.",
    screener_market: "시장데이터",
    screener_financial: "재무데이터",
    screener_other: "기업분류",
    add_screener: "스크리너 추가",
    update_screener: "스크리너 수정",
    screener_empty_result: "일치하는 기업이 없습니다. 조건을 삭제하시거나 수정하시기 바랍니다.",  
    /**
     * DATA STRING
     */
    score: "관련도",
    date: "날짜",
    prev_date: "이전 날짜",
    entity_name: "회사명",
    pbr: "PBR",
    per: "PER",
    no_result: "결과가 없습니다.",
    content: "본문",
    keyword: "검색어",
    daterange: "기간",
    all: "전체",
    select: "선택",
    date_from: "시작일",
    date_to: "종료일",
    search: "검색",
    news_section: "섹션",
    news_section_full: "뉴스 분야",
    news_publisher: "언론사",
    research_section: "리포트 섹션",
    research_publisher: "증권사",
    company_search: "기업 검색",
    company_analysis: "기업 분석",
    people_analysis: "인물 분석",
    thematic_analysis: "주제 분석",
    document_search: "문서 검색",
    bond_search: "채권 검색",
    sentiment_analysis: "감성 분석",
    event_study: "이벤트 분석",
    event_study_re: "이벤트 분석 Re.",
    mind_map: "마인드맵",
    industry_analysis: "산업 분석",
    economic_indicator: "경제 지표",
    company_discovery: "기업 발굴",
    dashboard: "대시보드",
    newsroom: "뉴스룸",
    excel_add_in : "딥서치 엑셀 Add-in",
    search_home: "검색 홈",
    related_keyword: "관련 키워드",
    related_entities: "관련 항목",
    related_keyword_category: "관련 키워드 검색 범위",
    related_keyword_range: "관련 키워드 검색 기간",
    addtional_condition: "검색 조건",
    time_elapsed: "연산 시간",
    company_summary: "기업 개요",
    stock_change_rate: "주가 변동률",
    volume: "거래량",
    default_excel_name: "검색 결과",
    default_excel_button_name: "검색 결과 다운로드",
    download: "다운로드",
    download_pdf: "원본 문서 다운로드",
    download_pdf_completed: "원본 문서 다운로드 완료",
    download_completed: "다운로드 완료",
    trading_halted : "거래정지",
    positive: "긍정",
    negative: "부정",
    neutral: "중립",
    save: "저장하기",
    load: "불러오기",
    is_alive: "상태",
    normal: "정상",
    stoppage: "휴업",
    business_closed: "폐업",
    clear_all: "전체 삭제",
    previous: "이전",
    no_stocks: "주식 개수",
    no_shares: "주식수",
    total_marcaps: "시가총액",
    average_per: "평균 PER",
    firm_count: "기업수",
    average_change: "변동값 평균",

    news_company: "뉴스 기업",
    disclosure_company: "공시 기업",
    research_company: "증권사리포트 기업",
    ir_company: "IR 기업",
    alpha_company: "알파 기업",
    investment_info: "투자정보",
    report_problem: "결과물에 대한 사용자 의견",
    monitoring: "모니터링",
    target_discovery: "타겟발굴",
    market_research: "시장조사",

    query: "쿼리",
    notes: "참고",
    text: "텍스트",
    value: "값",

    //기업
    company_status: "관심 기업",
    event: "이벤트",
    event_analysis: "이벤트 분석",
    company_event_analysis: "주가 영향도 분석",
    issue_stock: "이슈 기업",
    source: "Sources",

    // 기업 data-coverage
    coverage_type: "종류",
    coverage_accumulated: "누적",

    /**
     * TIME STRING
     */
    recent_1m: "최근 1달",
    recent_3m: "최근 3달",
    recent_1y: "최근 1년",
    recent_2y: "최근 2년",
    recent_3y: "최근 3년",
    recent_5y: "최근 5년",
    today: "오늘",
    yesterday: "어제",
    this_week: "이번주",
    last_week: "지난주",
    this_month: "이번달",
    last_month: "지난달",
    this_year: "올해",
    last_year: "작년",
    next_year: "내년",
    three_months_ago: "3개월 전",
    two_weeks_ago: "2주전 대비",
    one_week_ago: "1주전 대비",
    one_month_ago: "한달 전 대비",

    /**
     * CATEGORY
     */
    industry: "산업",
    company: "기업",
    bond: "채권",
    discovery: "발굴",
    financial: "재무",
    market: "시장",
    command: "명령어",
    document: "문서",
    example: "예제",
    favorites: "즐겨찾기",
    history: "최근 검색어",
    recommend_search: "추천 검색",
    thematic: "주제",
    publisher: "출처",
    bank_of_korea: "한국은행",

    /**
     * SEARCH_TAB
     */
    answer_tab: "답변",
    discovery_tab: "관련 기업",
    document_search_tab: "문서",
    company_search_tab: "기업",
    company_related_companies_tab: "{0} 관련 기업",
    company_biz_area_search_tab: "기업(사업 영역)",
    company_industry_search_tab: "기업(산업)",
    company_biz_name_search_tab: "기업(상호)",
    company_addr_search_tab: "기업(주소)",
    industry_search_tab: "산업",
    people_search: "인물 검색",
    people_list: "인물 리스트",

    /**
     * MENU STRING
     */
    condition_init: "조건 초기화",
    condition_refresh: "새로 고침",
    related_company_to_event: "연관 기업 분석",
    setting: "설정",
    related: "관련",
    related_document: "관련 문서",
    user: "사용자",
    organization: "기업",
    admin: "관리자",
    company_search_more_info: "함께보기",
    company_founded: "설립시기",
    company_member: "임직원",
    related_company: "관련 기업",
    related_summary_company: "관련 개요 및 현황 기업",
    related_industry: "관련 산업",
    affiliation_company: "재직 기업",
    select_from_saved_condition: "사용자 검색 조건에서 선택",
    recommend_condition: "추천 조건",
    recommend_event: "추천 이벤트",
    user_search_condition: "사용자 검색 조건",
    save_condition: "[조건 저장]",
    finish: "완료",
    cancel: "취소",
    kospi_index: "코스피 지수",
    kosdaq_index: "코스닥 지수",
    close_list: "[목록접기]",
    open_list: "[목록보기]",
    loading: "[로딩중]",
    expand: "펼치기",
    collapse: "접기",
    symbol: "종목코드",
    confirm: "확인",
    next_help: "다음 도움말",
    help: "도움말",
    market_analysis: "시장 분석",
    industry_company: "산업 기업",
    industry_name: "산업이름",
    search_condition: "검색 조건",
    delete_selection: "선택 삭제",
    delete: "삭제",
    delete2: "삭제하기",
    rename: "이름 변경",
    edit: "편집",
    add_condition: "조건 추가",
    general_condition: "일반 조건",
    subject: "주제",
    search_keyword: "검색 키워드",
    search_periods: "검색 기간",
    document_information: "문서 정보",
    market_information: "시장 정보",
    employee_count: "종업원수",
    male: "남",
    female: "여",
    age: "나이",
    age_of_ceo: "대표이사 나이",
    profile: "프로필",
    company_periods: "설립일",
    company_size: "기업 규모",
    company_listed: "기업 공개 여부",
    signup: "회원가입",
    deepsearch_login: "로그인",
    login: "로그인",
    logout: "로그아웃",
    user_withdrawal: "회원탈퇴",
    video_tip: "동영상 TIP",
    necessary: "필수",
    view: "보기",
    view_video: "동영상 보기",
    ceo: "대표이사",
    date_founded: "설립일자",
    birthdate: "생년월일",
    company_type: "기업형태",
    exchange: "거래소",
    business_area: "사업영역",
    industry_id: "산업분류",
    recently_news: "최근 뉴스",
    news: "뉴스",
    news_1: "뉴스 1",
    news_2: "뉴스 2",
    news_3: "뉴스 3",
    disclosure: "공시",
    performance_disclosure: "실적 공시",
    research: "증권사리포트",
    ir: "IR",
    alpha: "알파",
    patent: "특허",
    change_rate: "변동률",
    rank: "순위",
    research_name: "증권사명",
    market_brief: "증권사별 Daily Brief : ",
    title: "제목",
    summary: "요약",
    highlight: "하이라이트",
    content_url: "원문 링크",
    ratio: "배",
    detail_volume: "거래량(당기|전기|증감)",
    more_document_info: "관련 정보 더보기",
    more_topic: "[토픽 더보기]",
    topic: "토픽",
    daily_event_analysis: "일자별 이상징후 분석",
    more_info: "더보기",
    more_expandation: "네트워크 확장",
    add_more_info: "+ 표시 항목 추가",
    show_only_unlisted_company: "비상장 기업만 표기",
    show_related_documents: "더보기",
    change_ratio_top_10: "주가변동률 상위 기업 TOP 10",
    change_ratio_bottom_10: "주가변동률 트위 기업 TOP 10",
    user_search: "추가 분석",
    add_search_condition: "[검색 조건 추가]",
    close_search_condition: "[검색 조건 닫기]",
    close: "닫기",
    company_information: "기업 정보",
    index_company: "구성 기업",
    company_overview: "기업 개요",
    financial_information: "재무 정보",
    price_information: "시장 정보",
    related_subject: "관련 주제",
    suggested_keyword: "추천 검색어",
    result: "결과",
    reason: "산출 근거",
    input_interpretation: "질의 해석",
    chart: "차트",
    multi_compare_chart: "차트",
    single_compare_chart: "차트",
    trend: "트렌드",
    news_trend: "뉴스 트렌드",
    trending_topics: "트렌딩 토픽",
    recent_topic: "주요 토픽",
    author: "저자",
    portfolio_analysis: "포트폴리오 분석",
    start_analysis: "분석 시작",
    analysis: "분석",
    retry: "재시도",
    related_news: "관련 뉴스",
    more: "More",
    pending: "대기중",
    using: "사용중",
    choose: "선택하기",
    free: "무료",
    suspended: "정지됨",
    canceled: "취소됨",
    ended: "종료됨",
    state: "상태",
    count: "개수",
    modify: "수정",
    add: "추가",
    added: "추가됨",
    overwrite: "덮어쓰기",
    information: "안내",
    version: "버전",
    select_all: "전체선택",
    total: "종합",
    sum: "합계",
    growth_rate: "성장률",
    average: "평균",
    max: "최대",
    min: "최소",
    unreported: "보고되지 않음",
    company_position: "직책",
    career_experiences: "이력",
    page: "페이지",
    rows: "개",
    buy: "매수",
    strong_buy: "강력매수",
    sell: "매도",
    strong_sell: "강력매도",
    very_high: "매우 높음",
    high: "높음",
    usually: "보통",
    low: "낮음",
    very_low: "매우 낮음",
    quarterly: "분기",
    yearly: "연간",
    company_share: "지분",
    consensus_before: "이전 컨센서스",
    consensus_after: "최신 컨센서스",
    compared_to_month: `{month, plural,
        =0 {-}
        =1 {지난달 대비}
        other {{month} 개월전 대비}}`,
    three_months: "3개월",
    other: "기타",
    registration_date: "등록일",
    info: "정보",

    //뉴스 카테고리
    category: "카테고리",
    economy: "경제",
    tech: "기술",
    politics: "정치",
    society: "사회",
    culture: "문화",
    world: "세계",
    entertainment: "연예",
    sports: "스포츠",
    opinion: "사설",
    top: "종합",

    //MARKET DATA
    market_cap: "시가총액",
    yesterday_volume: "전일 거래량",

    //재무정보
    financial_evaluation: "재무평가",
    financial_statements: "재무상태표",
    growth: "성장성",
    stability: "안정성",
    dividend: "배당",
    profitability: "수익성",
    valuation: "밸류에이션",
    income_statement: "포괄손익계산서",
    cashflow_statement: "현금흐름표",
    consolidated_financal: "연결",
    individual_financial: "개별",
    sales: "매출액",
    revenues: "매출액",
    revenues_growth: "매출 성장",
    operating_income: "영업이익",
    operating_income_ratio: "영업이익률",
    net_income: "당기순이익",
    assets: "자산",
    non_current_assets: "비유동자산",
    tangible_assets: "유형자산",
    investment_property: "투자부동산",
    intangible_assets: "무형자산",
    biological_assets: "생물자산",
    long_term_investment_assets: "장기투자자산",
    trade_and_other_receivables: "매출채권및기타채권",
    deferred_tax_assts: "이연법인세자산",
    other_financial_assts: "기타금융자산",
    other_non_financial_assts: "기타비금융자산",
    continuing_involvement_assts: "지속적관여자산",

    current_assts: "유동자산",
    inventories_assts: "재고자산",
    short_term_investment_assets: "단기투자자산",
    current_tax_assts: "당기법인세자산",
    rental_housing_assets: "임대주택자산",
    cash_and_cash_equivalents: "현금및현금성자산",

    other_financial_industry_assets: "기타금융업자산",
    consolidation_adjustments: "연결조정차",

    total_assets: "자산총계",
    cash_equivalents: "현금 및 현금성 자산",
    liabilities: "부채",
    liabilities_ratio: "부채비율",
    leverage: "부채비율",
    equity: "자본",
    majority_interest: "지배기업소유주지분",
    issued_capital: "납입자본",
    retained_earnings: "이익잉여금",
    other_components_of_equity: "기타자본구성요소",
    capital_related_to_assets_held_for_sale: "매각예정자산관련자본액",
    non_controlling_interest: "비지배지분(소수주주지분)",

    total_equity: "자본총계",
    ordinary_profit: "경상이익",
    holding_ratio: "지분율",
    ownership_percentage: "소유권 비율",
    prev_holding_ratio: "이전 지분율",
    changed_holding_ratio: "지분 변화",
    total_holding_ratio: "전체 지분율",
    real_holding_ratio: "실질 지분율",
    total_debt: "전체 부채",
    short_term_borrowings: "단기차입금",
    long_term_borrowings: "장기차입금",
    total_borrowings: "전체 차입금",
    forecasted_sales: "매출액",
    forecasted_operating_income: "영업이익",
    forecasted_net_income: "당기순이익",

    product_name: "상품명",
    product_sales_status: "분야별 매출 현황",
    /**
     * 기업 종류
     */
    corporation_company: "주식회사",
    join_venture_company: "합자회사",
    partnership_company: "합명회사",
    limited_corporation_company: "유한회사",
    copartnership_company: "조합",
    goverment_investment_organization: "정부투자기관",
    individual: "개인",
    school: "학교",
    hospital: "병원",
    association: "단체/협회",
    limited_liability_company: "유한책임회사",
    cooperative_society: "협동조합",
    join_stock_association: "합자조합",
    etc: "기타",

    /**
     * 기업 크기
     */
    major_company: "대기업",
    small_company: "중소기업",
    middle_company: "중견기업",

    /**
     * 기업 시장
     */
    kospi: "KOSPI",
    kosdaq: "KOSDAQ",
    konex: "KONEX",
    unlisted: "비상장",
    date_listed: "상장일자",

    /**
     * COMPANY AUDIT TYPE
     */
    is_external_audit: "외부감사",
    external_audit: "외감",
    non_external_audit: "비외감",

    /**
     * 리포트
     */
    report_all: "리포트 : 전체",
    report_market: "리포트 : 시장",
    report_strategy: "리포트 : 전략",
    report_company: "리포트 : 기업",
    report_industry: "리포트 : 산업",
    report_economy: "리포트 : 경제",
    report_bond: "리포트 : 채권",

    /**
     * 뉴스
     */
    news_all: "뉴스 : 전체",
    news_economy: "뉴스 : 경제",
    news_tech: "뉴스 : 기술",
    news_politics: "뉴스 : 정치",
    news_society: "뉴스 : 사회",
    news_culture: "뉴스 : 문화",
    news_world: "뉴스 : 세계",
    news_entertainment: "뉴스 : 연예",
    news_opinion: "뉴스 : 사설",

    /**
     * 변수 선택 마법사
     */
    data_select_wizard: "변수 선택 마법사",
    data_select_title_stage1: "1단계 : 데이터 종류 선택",
    data_select_title_stage2: "2단계 : 변수 선택",
    data_select_title_stage3: "3단계 : 증권/기업 선택",
    data_select_title_stage4: "4단계 : 날짜 범위 선택",
    data_select_title_stage5: "5단계 : 생성된 쿼리 확인",

    data_select_option_search_unlisted_companies: "비상장 기업 포함",
    data_select_option_search_instruction:
        "위 검색창에 찾고자 하는 이름 일부를 입력하고 엔터키를 누르세요.",

    data_select_datatype_market_index: "시장 인덱스",
    data_select_datatype_stock_prices: "기업 시장 정보",
    data_select_datatype_firm_fundamentals: "기업 재무 정보",
    data_select_datatype_bank_of_korea: "한국은행",

    data_select_button_next: "다음",
    data_select_button_finish: "완료",
    data_select_button_previous: "이전",
    data_select_filter_by_name: "이름으로 검색",

    /**
     * 기업 검색
     */
    company_search_search_conditions: "검색 조건",
    company_search_filters: "필터",
    company_search_reset: "초기화",

    company_search_company_name: "기업 이름",
    company_search_address: "주소",
    company_search_business_area: "사업 영역",
    company_search_related_keyword: "관련 키워드",
    company_search_company_rid: "법인 등록번호",
    company_search_business_rid: "사업자 등록번호",
    company_search_custom_option: "사용자 정의 검색 조건",
    company_search_industry: "산업",
    company_search_ceo_age: "대표이사 나이",
    company_search_employees: "종업원 수",
    company_search_founded_date: "설립일",
    company_search_marcap: "시가총액",
    company_search_listing_status: "상장 여부",
    company_search_listing_status_listed: "상장 기업",
    company_search_listing_status_unlisted: "비상장 기업",
    company_search_listing_status_external_audit: "비외감 기업",

    company_search_map: "지도",
    company_search_company_list: "기업 목록",

    type_industry_name: "산업명 입력",

    total_matched_firm_count_message:
        "재무제표 데이터가 없는 비외감 기업을 포함 총 {1} 개의 기업이 검색되었습니다. 전체 목록 확인을 위해선 아래의 더보기 버튼을 클릭해주세요.",

    /**
     * 채권 검색
     */
    select_bond_issuer: "채권 발행자 선택",
    bond_classification_list: "채권 분류 목록",
    bond_issuer_list: "채권 발행자 목록",
    search_bond_issuer_by_name: "이름으로 채권 발행자 검색",
    results_are_not_found: "발견된 결과가 없습니다.",
    selected_bond_issuer_list: "선택된 채권 발행자 목록",
    related_company_info: "관련 기업 재무 정보",
    bond_issuance_history: "채권 발행 규모 이력",
    bond_repayment_schedule: "채권 상환 일정",
    bond_yield_curve: "채권 수익률 곡선",
    bond_list: "채권 목록",
    compare_yield_curves: "수익률 곡선 비교",
    bond_yield_time_series_graph: "금리 시계열 그래프",
    credit_spreads: "신용 스프레드",
    credit_spreads_time_series: "신용 스프레드 시계열",
    principal_component_analysis: "주성분 분석",
    maturity_in_years: "만기 (년)",

    /**
     * 이벤트 분석 Remastered
     */
    event_study_stage1_select_dates: "이벤트 분석: 1단계. 이벤트 정의 및 날짜 선택",
    event_study_stage2_stock_analysis: "이벤트 분석: 2단계. 주식 분석 및 포트폴리오 구성",
    event_study_stage3_stock_portfolio_analysis: "이벤트 분석: 3단계. 주식 포트폴리오 분석 결과",
    event_study_stage2_bond_analysis: "이벤트 분석: 2단계. 채권 분석",
    event_study_stage3_bond_portfolio_analysis: "이벤트 분석: 3단계. 채권 포트폴리오 분석 결과",

    choose_next_step: "다음 단계 선택",
    stock_analysis: "주식 분석",
    bond_analysis: "채권 분석",
    stock_portfolio_analysis: "주식 포트폴리오 분석",
    save_selected_stock_portfolio: "선택한 주식 포트폴리오 저장",

    load_previous_event_date_definitions: "기존 이벤트 정의 불러오기",
    save_selected_event_dates: "선택된 이벤트 날짜 저장하기",
    overwrite_previous_record: "기존 기록 덮어쓰기",
    save_as_new_record: "새 이름으로 저장하기",
    event_dates_are_successfully_saved: "이벤트 날짜가 성공적으로 저장되었습니다.",
    event_dates_are_successfully_loaded: "이벤트 날짜를 성공적으로 불러왔습니다.",

    // 이벤트 분석 - 날짜 선택
    how_to_select_event_dates: "이벤트 날짜 조회 기준 선택",
    how_to_select_event_dates_news_trends: "뉴스 트렌드",
    how_to_select_event_dates_market_indicator: "시장 지표",
    how_to_select_event_dates_curated_events: "주요 사건 정리",
    how_to_select_event_dates_manual_select: "날짜 직접 지정",

    news_trend_search_keywords: "뉴스 트렌드 검색 키워드",
    news_trend_chart: "뉴스 트렌드 차트",
    news_list_on_trend_dates: "트렌드 날짜의 뉴스 목록",

    market_indicator_list: "시장 지표 목록",
    exchange_rate: "환율",
    interest_rate: "금리",
    stock_market: "주식시장",
    market_indicator_chart: "시장 지표 차트",
    indicator_related_news_list: "지표 관련 뉴스 목록",
    news_search_keyword: "뉴스 검색 키워드",
    curated_event_list_view: "주요 사건 목록",
    event_date_manual_select: "이벤트 날짜 직접 지정",

    selected_event_date_list: "선택된 이벤트 날짜 목록",
    stock_market_returns_on_event_dates: "이벤트 당일 주식 시장 수익률",
    stock_market_index_on_surrounding_days: "이벤트 전후 주식 시장 인덱스",
    exchange_rate_changes_on_event_dates: "이벤트 당일 환율 변화",
    exchange_rate_trends_in_surrounding_days: "이벤트 전후 환율 추이",
    govt_bond_yield_changes_on_event_dates: "이벤트 당일 국고채 금리 변화",
    govt_bond_yields_on_surrounding_days: "이벤트 전후 국고채 금리",
    major_issues_on_event_dates: "이벤트 당일 주요 이슈",

    // 이벤트 분석 - 주식 분석
    select_stock_analysis_basis: "주식 분석 기준 선택",
    stock_analysis_basis_related_firms: "관련 기업",
    stock_analysis_basis_industry: "산업",
    stock_analysis_basis_indicator_correlation: "지표 상관관계",
    stock_analysis_basis_manual_select: "직접 선택",
    manual_select: "직접 선택",

    selected_stock_list: "선택된 주식 목록",
    stock_price_history: "주식 가격 이력",
    stock_price_around_event_dates: "이벤트 전후 주가 변화",

    related_firm_list: "관련 기업 목록",
    search_keywords_for_related_firms: "관련 기업 검색 키워드",
    related_firm_reasoning: "관련 기업 선정 이유",
    reasoning_source: "근거 자료 출처",

    select_industry_category_level: "산업 분류 범주 선택",
    industry_category_level_10k_large: "10차 한국표준 대분류",
    industry_category_level_10k_medium: "10차 한국표준 중분류",
    industry_category_level_10k_small: "10차 한국표준 소분류",

    industry_list_and_summary: "산업 목록 및 요약 정보",
    latest_market_info: "최근 시장 정보",
    event_day_returns: "이벤트 당일 수익률",
    cumulative_returns: "누적 수익률",
    industry_event_day_returns: "산업별 이벤트 당일 수익률",
    industry_cumulative_returns: "산업별 누적 수익률",
    industry_aggregate_financial_info: "산업별 누계 재무 정보",
    both_listed_and_unlisted: "상장기업/비상장기업 전체",
    listed_firms_only: "상장기업만 포함",
    listed_firms_target: "상장기업 대상",
    not_available_for_large_cateogy:
        "해당 컴포넌트는 중분류 또는 소분류에 대해서만 사용하실 수 있습니다.",
    industry_stock_list: "산업 내 주식 목록",

    correlation_reference_indicator: "상관관계 기준 지표",
    stock_return_correlation: "주식 수익률 상관관계",
    correlation: "상관관계",
    securities_type: "증권 종류",
    marcap_rank: "시가총액 순위",

    // 이벤트 분석 - 채권 분석
    select_bond_analysis_basis: "채권 분석 기준 선택",

    bond_analysis_basis_issuer: "채권 발행자",
    bond_analysis_basis_classification: "채권 분류",
    bond_analysis_basis_industry: "산업",

    bond_yield_changes_on_event_dates: "이벤트 당일 채권 수익률 변화",
    bond_type: "채권 분류",
    bond_count: "채권 개수",
    then_bond_count: "당시 채권 개수",
    issuer_name: "발행자 이름",
    total_issue_size: "전체 발행액",
    yield_change_bps: "수익률 변화 (bps)",
    average_ytm: "평균 YTM",
    average_maturity: "평균 만기",
    average_line: "평균선",
    unlisted_estimate: "비상장기업 추정치",

    outstanding_bonds_are_no_longer_found: "발행된 채권이 더 이상 존재하지 않습니다.",
    yield_curve_on_event_dates: "이벤트 당일 수익률 곡선",
    yield_curve_change_on_event_dates: "이벤트 당일 수익률 곡선 변화",
    cumulative_yield_changes_around_evnet_dates: "이벤트 전후 금리 누적 변화",
    major_classification: "대분류",
    selected_bond_class_list: "선택된 채권 분류 목록",
    bond_list_per_industry: "산업별 채권 목록",
    industry_code: "산업코드",
    selected_bond_industry_list: "선택된 산업 목록",
    bond_issuer_list_within_class: "분류 내 채권 발행자 목록",
    bond_issuer_list_within_industry: "산업 내 채권 발행자 목록",
    yield_difference_relative_to_evnet_dates: "이벤트 당일 대비 채권 금리 변화 (bps)",

    /**
     * 산업 분석 Remastered
     */
    industry_identification_standard: "산업 정의 기준",
    deepsearch_industry: "딥서치 산업 분류",
    korean_standard_industry: "통계청 표준 산업 분류",
    conglomerate: "기업집단",
    conglomerate_list: "기업집단 목록",
    conglomerate_name: "그룹 이름",
    owner: "소유주",
    chairman: "회장",
    member_count: "계열사 수",
    primary_bank: "주거래 은행",
    conglomerate_or_chairman_name: "그룹 또는 회장 이름",
    conglomerate_details: "그룹 상세 정보",
    mission: "미션",
    main_business: "주 사업",
    main_company: "주 기업",
    user_query: "사용자 쿼리",
    theme_list: "주제 목록",
    firm_manual_select: "기업 직접 선택",
    manually_selected_firm_list: "직접 선택된 기업 목록",
    select_industry: "산업 분류 선택",
    average_annual_growth_rates_over_past_years: "과거 기간별 연 평균 성장률",
    historical_sentiment_chart: "과거 감성분석 추이",
    sentiment_trend: "긍부정 추이",
    sentiment_score: "긍부정 점수",
    news_count: "뉴스 개수",
    search_companies_by_name: "이름으로 기업 검색",
    primary_bank_market_share: "주거래은행 점유율",
    revenue_sum: "매출액 총합",
    asset_sum: "자산 총합",
    liability_sum: "부채 총합",
    all_industry: "산업 전체",
    selected_firms_only: "선택된 기업만 포함",
    relevant_economic_indicators: "관련 경제 지표",
    top_individual_compensations: "개인별 최고 보수",
    average_employee_compensations: "평균 직원 연봉",

    industry_consensus: "산업 경제 전망",
    field: "항목",
    statistic: "통계치",
    mean: "평균",
    median: "중위값",
    firms_with_analyst_forecasts_are_not_found:
        "애널리스트 예측치를 가진 기업이 존재하지 않습니다.",
    listed_firms_didnt_exist_in_the_given_sample_year:
        "선택된 연도에는 선택된 상장 기업이 존재하지 않습니다",

    /**
     * 뉴스룸
     */
    news_search: "뉴스 검색",
    date_range: "날짜 범위",
    date_range_mode_trending: "트렌딩",
    date_range_mode_recent: "최신",
    date_range_mode_one_week: "일주일",
    date_range_mode_one_month: "한달",
    date_range_mode_one_year: "일년",
    date_range_mode_manual: "사용자 지정",

    manual_date_range_modal_title: "날짜 범위 사용자 지정",

    type_and_enter: "입력 후 엔터",
    includeAnd: "포함 (and)",
    includeOr: "포함 (or)",
    exclude: "제외",

    major_topics: "주요 토픽",
    all_news_articles: "모든 신문 기사",

    sort_by_relevance: "연관도",
    sort_by_score: "이슈 정도",
    sort_by_date: "최신",

    sentiment: "감성",

    none: "없음",
    appointment: "인사",
    obituary: "부고",

    topic_list: "토픽 목록",
    news_list: "뉴스 목록",
    doc_count: "문서 개수",
    last_update: "마지막 업데이트",
    seconds_ago: "초 전",
    minutes_ago: "분 전",
    hours_ago: "시간 전",

    ml_news_analysis: "인공지능 뉴스 분석",
    show_as_list: "리스트로 보기",
    people: "인물",
    regions: "지역",
    brands: "브랜드",
    organizations: "조직",

    selected_date_range: "선택된 날짜 범위",
    search_selected_period: "선택 기간 검색",

    add_to_favorites: "즐겨찾기 추가",
    save_newsroom_favorites: "뉴스룸 즐겨찾기 추가",
    save_as_new_setting: "새 이름으로 저장",
    overwrite_existing_setting: "기존 설정 덮어쓰기",
    favorites_delete_message: "즐겨찾기를 삭제 하시겠습니까?",
    added_to_favorites: "즐겨찾기에 추가 되었습니다",
    deleted_from_favorites: "즐겨찾기에서 삭제 되었습니다",

    matched_topics_are_not_found:
        "조건을 만족하는 결과가 발견되지 않았습니다. 검색 기간을 늘려서 다시 한번 시도해보세요.",

    launch_newsroom_mobile_app: "뉴스룸 모바일 앱 실행",


    /**
     * 경제 지표 Economic Indicator
     */
    search_keyword_input: "검색어 입력",
    popular_search_keywords: "인기 검색어",
    must_have: "반드시 포함",
    open_in_new_tab: "새 탭에서 열기",
    open_in_new_window: "새 창에서 열기",

    economic_indicator_source: "출처",
    country: "국가",
    capital_city: "수도",
    data_frequency: "데이터 주기",
    popularity: "인기도",
    survey_institution: "수집 기관",

    kosis: "통계청",
    fred_economic_data: "FRED Economic Data",
    imf: "IMF",
    oecd: "OECD",
    world_bank: "World Bank",
    deepsearch_own: "딥서치 자체 수집 데이터",

    time_series_data: "시계열 데이터",
    cross_section_data: "횡단면 데이터",
    sample_date: "데이터 날짜",
    data_value: "데이터 값",
    data_list_of_the_same_category: "동일 분류 내 데이터 목록",
    select_all_in_the_same_category: "동일 분류 내 모든 항목 선택",
    depth_per_category: "카테고리별 깊이",
    depth_x_format: "{depth} 단계",
    related_news_articles: "관련 뉴스 기사",
    data_categories_are_not_selected: "선택된 데이터 카테고리가 없습니다.",

    search_by_name: "이름으로 검색",
    select_within_category: "카테고리 내 선택",

    input_data_series_name_to_search_for: "검색할 데이터 시리즈 이름 입력 후 엔터",
    results_are_not_found_try_again: "발견된 결과가 없습니다. 검색어를 바꾸어서 다시 시도해 보십시오.",
    click_below_to_see_data_table: "아래를 클릭하면 데이터 테이블을 확인할 수 있습니다.",
    need_subscription_to_download_data: "데이터를 다운로드하기 위해서는 월 정기 이용권이 필요합니다.",

    count_per_source: "출처별 데이터 시리즈 갯수",
    reference_notes: "참고 노트",

    chart_edit_tools: "차트 편집 도구",
    data_series_list: "데이터 시리즈 목록",
    search_economic_indicator: "경제 지표 검색",
    edit_chart_style: "차트 스타일 편집",
    add_annotations: "주석 달기",

    series_name: "시리즈 이름",
    chart_legend: "차트 표시 이름",
    add_a_new_annotation: "새로운 주석 추가",
    annotation_list: "주석 목록",

    stat_name: "통계 항목",
    difference_relative_to: "변화량",
    growth_rate_relative_to: "증감율",
    relative_to_last_period: "전기 대비",
    relative_to_last_month: "전월 대비 (m/m)",
    relative_to_last_quarter: "전분기 대비 (q/q)",
    relative_to_last_year: "전년도 대비 (y/y)",


    /**
     * 대시보드 추가 버튼 Save To Dashboard
     */
    save_to_dashboard: "대시보드에 추가",
    add_to_dashboard: "대시보드에 추가",
    added_to_dashboard: "대시보드에 추가 되었습니다.",
    remove_from_dashboard: "대시보드에서 제거",
    removed_from_dashboard: "대시보드에서 제거 되었습니다.",


    /**
     * 특허 분석
     */
    patent_analysis: "특허 분석",
    patent_select_view_type: "분석 옵션 선택",
    patent_view_type_keywords: "키워드",

    /**
     * 문서 검색
     */
    document_search_title: "제목",
    document_search_has_the_words: "본문 포함",
    document_search_doesnt_have: "본문 제외",

    type_keywords_to_be_inlcuded_in_title: "제목에 포함될 키워드 입력",
    type_keywords_to_be_inlcuded_in_contents: "본문에 포함될 키워드 입력",
    type_keywords_to_be_excluded_from_contents: "본문에서 제외할 키워드 입력",

    document_search_trending_topics: "트렌딩 토픽",
    document_search_trending_query: "질의",

    document_search_filters: "검색 조건",
    document_search_filter_categories: "카테고리",
    document_search_filter_sections: "분야",
    document_search_filter_date_range: "날짜 범위",
    document_search_filter_date_range_from: "시작",
    document_search_filter_date_range_to: "끝",
    document_search_filter_sentiments: "어조",
    document_search_filter_companies: "기업",
    industries: "산업",
    document_search_filter_pubishers: "출처",

    document_search_date_range_ten_year_limit: "뉴스 검색 기간 선택은 10년으로 제한되어 있습니다.",

    document_search_popup_filter_clear: "선택 초기화",
    document_search_popup_section_filter:
        "분야 필터는 카테고리에서 뉴스나 증권사리포트, 둘 중 하나만 선택되었을 때 사용 가능합니다.",
    document_search_popup_aggregation_filter:
        "이 필터는 현재 선택된 카테고리/분야에 대해서는 지원되지 않습니다.",

    /**
     * 검색 문서 종류
     */
    search_document_all: "검색문서 : 전체",
    search_document_news: "검색문서 : 뉴스",
    search_document_disclosure: "검색문서 : 공시",
    search_document_research: "검색문서 : 증권사리포트",
    search_document_ir: "검색문서 : IR",
    search_document_alpha: "검색문서 : 알파",

    /**
     * 검색 문서 기간
     */
    document_period_year: "검색 기간 : 최근 1년",
    document_period_2_year: "검색 기간 : 최근 2년",
    document_period_this_year: "검색 기간 : 올해",
    document_period_last_year: "검색 기간 : 작년",
    document_period_before_last_year: "검색 기간 : 재작년",
    document_period_user_setting: "검색 기간 : 사용자 설정",

    /**
     * 이슈 종목 관련
     */
    volume_increase: "거래량 급등",
    volume_decrease: "거래량 급락",
    price_increase: "주가 급등",
    price_decrease: "주가 급락",
    total_increase: "거래대금 급등",
    total_decrease: "거래대금 급락",
    top_price_increase: "상한가",
    top_price_decrease: "하한가",
    top_volume_increase: "거래량 상위",
    top_market_cap_in_kospi: "시가총액 상위 - KOSPI",
    top_market_cap_in_kosdaq: "시가총액 상위 - KOSDAQ",

    change_in_shareholders_high: "지분변동 상위",
    government_shareholders_top_share: "국민연금 지분기업 상위",
    government_shareholders_top_ratio_on_marketcap: "국민연금 지분율 상위",
    government_shareholders_increase: "국민연금 지분확대 기업 ",
    government_shareholders_decrease: "국민연금 지분축소 기업",
    government_shareholders_top_sales: "국민연금 지분기업 매출액 상위",
    government_non_shareholders_top_sales: "국민연금 비지분기업 매출액 상위",
    government_shareholders_top_volume: "국민연금 지분기업 거래량 상위",
    kdb_shareholders: "산업은행 지분율 상위",

    company_with_better_consensus: "컨센서스 상향",
    company_with_bad_consensus: "컨센서스 하향",
    company_target_price_better_consensus: "목표주가 상향",
    company_target_price_bad_consensus: "목표주가 하향",

    company_market_cap_large: "시가총액 1조 이상",
    company_market_cap_medium: "시가총액 3천억~1조원",
    company_market_cap_small: "시가총액 3천억 미만",

    shareholders_name: "주주명",
    company_name: "기업명",
    org_name: "소속",
    team_name: "부서",
    price: "주가",
    powered_by: "powered by",

    /**
     * SOURCE STRING
     */
    market_source: "시장 정보 : Koscom, Thomson Reuters",
    company_source: "기업 정보 : 나이스평가정보",
    document_source:
        "문서 정보 : 언론사(뉴스), 금융감독원 Dart(공시), 증권사(리포트), 각 회사 발표 IR 자료, SNEK Alpha(알파)",

    /**
     * MARKET STATUS
     */
    market_pre: "개시 전",
    market_open: "거래 중",
    market_closed: "거래 종료",

    stock_price_unit: "주가(원)",
    stock_count_unit: "거래량(주)",
    employee_count_unit: "종업원수(명)",
    day_unit: "일",
    month_unit: "월",
    year_unit: "년",
    unit: "단위",
    one_won: "원",
    one_million_won: "백만원",
    person_count: "명",

    /**
     * Format
     */

    format_news_trend: "{0} 뉴스 트렌드 (%)",
    format_stock_price: "{0} 주가",
    format_stock_volume: "{0} 거래량",
    format_stock_market_cap: "{0} 시가총액",

    format_number: `{value, plural,
        =0 {-}
        other {{value, number}}}`,

    format_million: `{value, plural,
        =0 {-}
        other {{value, number} 백만}}`,

    format_billion: `{value, plural,
        =0 {-}
        other {{value, number} 억}}`,

    format_trillion: `{value, plural,
        =0 {-}
        other {{value, number} 조}}`,

    format_price: `{price, plural,
        =0 {0 원}
        other {{price, number} 원}}`,

    format_stock: `{stock, plural,
        =0 {-}
        other {{stock, number} 주}}`,

    format_change_rate: `{value} %`,

    format_event_analysis: "{name} 이벤트 분석",
    format_company_event_analysis: "{name} 주가 영향도 분석",

    /**
     * TIME FORMAT
     */
    format_birthday: "YYYY년 MM월 DD일",
    format_date: "YYYY-MM-DD",
    format_date_month: "YYYY년 MM월",
    format_document_date: "YYYY/MM/DD",
    format_time: "HH시 mm분",
    format_market_time: "YYYY년 MM월 DD일 HH:mm 기준",
    format_financial_time: "YYYY년 MM월 DD일 기준",

    format_related_document: "{0} 관련 문서",
    format_related_disclosure: "{0} 관련 공시",
    format_related_news: "{0} 관련 뉴스",
    format_related_research: "{0} 관련 증권사리포트",
    format_related_ir: "{0} 관련 IR",
    format_related_company: "{0} 관련 기업",

    /**
     * Message String key
     */
    message_no_result: "결과가 없습니다.",
    message_searching: "데이터를 검색하고 있습니다...",
    message_no_search_data: "검색 결과가 없습니다.",
    message_error_search_data:
        "서버에서 결과를 제대로 가져오지 못했습니다. 잠시후에 시도해 보시거나, 검색 쿼리를 변경하여 요청해 주십시오.",
    message_fail_to_analysis: "분석 결과가 없습니다.",

    //INPUT_INTERPRETATION
    message_same_name_list: `* {name}와(과) 동일한 이름을 가진 기업이 총 {count, plural,
        =0 {0개}
        one {1개}
        other {{count, number}개}} 있습니다. `,

    message_company_search_count: `(총 {count, plural,
        =0 {0개}
        one {1개}
        other {{count, number}개}}의 기업)`,
    message_company_limit_search_count: `(총 {total, plural,
        =0 {0개}
        one {1개}
        other {{total, number}개}} 기업 중 {count, plural,
            =0 {0개}
            one {1개}
            other {{count, number}개}})`,
    message_company_limit_search_info: `결과는 최대 1000개 까지만 표기됩니다. 전체 목록을 확인하려면 엑셀로 데이터를 다운로드 받거나, 검색 조건을 변경하여 결과를 1000개 이하로 조정하시기 바랍니다.`,
    message_document_search_count: `총 {count, plural,
        =0 {0개}
        one {1개}
        other {{count, number}건}}`,
    message_company_count: `{count, plural,
        =0 {0 개}
        one {1 개}
        other {{count, number} 개}}`,

    message_document_count: `{count, plural,
        =0 {0건}
        one {1건}
        other {{count, number}건}}`,

    message_more_document_count: `{count, plural,
        =0 {0건}
        one {1건}
        other {{count, number}+건}}`,

    message_more_count: `{count, plural,
        =0 {0건}
        one {1건 더보기}
        other {{count, number}건 더보기}}`,

    message_since_document_count: `{count, plural,
            =0 {0건}
            one {1건}
            other {{count, number}건}} \n ({since}년~)`,

    message_tooltip_score_5:
        "LEVEL 5 ( 관련도 : {0} ) : 기업의 사업보고서 및 IR 자료를 통하여, 회사는 해당 분야의 사업을 진행하고 있다는 것을 공시하고 있으며, 해당 사실은 증권사리포트 및 뉴스 자료 등을 통하여 적극적으로 알려져 있습니다.",
    message_tooltip_score_4:
        "LEVEL 4 ( 관련도 : {0} ) : 기업의 사업보고서 및 IR 자료를 통하여, 회사는 해당 분야의 사업을 진행하고 있다는 것을 공시하고 있으며, 해당 내용이 증권사 리포트를 통하여 커버되고 있습니다.",
    message_tooltip_score_3:
        "LEVEL 3 ( 관련도 : {0} ) : 기업의 사업보고서 및 IR 자료를 통하여, 회사는 해당 분야의 사업을 진행하고 있다는 것을 공시하고 있습니다.",
    message_tooltip_score_2:
        "LEVEL 2 ( 관련도 : {0} ) : 기업의 사업보고서에서 해당 분야의 사업을 진행하고 있다는 것을 공시하고 있으며, 관련 내용이 뉴스로 출판된 적이 있습니다.",
    message_tooltip_score_1:
        "LEVEL 1 ( 관련도 : {0} ) : 기업의 사업보고서에서 해당 분야의 사업을 진행하고 있다는 것을 공시하고 있습니다.",

    portfolio_guide:
        "DeepSearch는 선택한 기업 검색 조건을 바탕으로 포트폴리오를 구성하고, 포트폴리오의 과거 성과를 손쉽게 확인해 보실 수 있도록 지원합니다.\n포트폴리오 분석과 관련하여, 사용자는 벤치마크 지수, 분석 기간, 구성 방법, 기업 개수, 리밸런싱 주기, 거래 비용 조건을 선택할 수 있습니다.\n포트폴리오 구성 방법으로 동일 비중, 시가총액 비중, 사용자 검색 조건 비중을 지원합니다. 동일 비중은 포트폴리오의 구성 종목의 개별 비중을 동일하게, 시가총액 비중은 시가총액에 따라, 사용자 검색 조건의 경우 사용자 조건 결과 비중에 따라 종목을 구성합니다. 예를 들어, 사용자 검색 조건으로 비중을 결정하고, 검색 조건이 인공지능 관련 기업이라면, 인공지능 관련도 점수에 따라 개별 기업의 비중이 결정됩니다. \n포트폴리오는 최대 30개의 기업으로 구성되며, 기업의 선정은 사용자가 선택한 첫번째 조건을 가장 잘 만족하는 순서에 따라 결정됩니다. 예를 들어, 사용자가 검색한 조건이 매출액 증가 기업이라면, 매출액 상위 30개 기업을 선택합니다. \n거래 비용의 경우, 매 리밸런싱 주기 때의 거래 비용을 지정할 수 있습니다. 예를 들어, 거래 비용이 1% 이고, 전체 포트폴리오 합계가 100, 리밸런싱 때 변동되는 비중이 10% 라면, 거래 비용 합계는 100*10%*1% 가 됩니다. ",

    currency_format: "원",
    stock_format: "주",
    related_score: "관련도",
    /**
     * GUIDE STRING
     */
    message_wrong_input: "서버로 부터 적절한 결과를 받지 못했습니다.",
    message_no_choose_condition: "선택된 검색 조건이 없습니다.",
    message_common_search_input: "기업, 산업, 주제, 인물, 사용자 질의",
    message_duplicated_data: "이미 동일한 데이터가 있습니다.",
    message_add_data: "{0} 을 추가하였습니다.",
    message_not_found_data: "{0} 에 대한 결과가 없습니다.",
    message_drag_chart_document_period: "차트를 드래그하여 특정 기간에 대한 문서 정보만을 확인할 수 있습니다.",
    message_sentiment_trend_period: "설정된 기간 동안 해당 기업에 대한 긍부정 추이를 보여줍니다. 긍부정 점수는 매월 회사에 대한 뉴스 중 긍정적 뉴스와 부정적 뉴스가 차지하는 비중으로 계산됩니다.",

    keyword_news_trend: "{0} 뉴스 트렌드 (%)",
    keyword_price: "{0} 주가",
    keyword_volume: "{0} 거래량",
    keyword_market_cap: "{0} 시가총액",

    //포트폴리오
    portfolio: "포트폴리오",
    portfolio_entities: "포트폴리오 구성",
    portfolio_symbol_weight: "비중",

    portfolio_summary: "포트폴리오 성과 분석",
    portfolio_benchmark: "벤치마크",
    portfolio_raw_returns: "단순 수익률(연평균)",
    portfolio_stdev_raw_returns: "표준편차(단순 수익률)",

    portfolio_excess_returns: "초과 수익률(연평균)",
    portfolio_stdev_excess_returns: "표준편차(초과 수익률)",
    portfolio_category: "항목",

    portfolio_sharpe_ratio: "샤프 비율",
    portfolio_capm_alpha: "CAPM(알파)",
    portfolio_capm_beta: "CAPM(베타)",

    portfolio_max_drawdown: "최대 하락폭(MDD)",
    /**
     * 포트폴리오 분석 옵션
     */
    portfolio_option_benchmark_kospi: "벤치마크 : KOSPI",
    portfolio_option_benchmark_kosdaq: "벤치마크 : KOSDAQ",

    /**
     * 포트폴리오 분석 기간
     */
    portfolio_option_period_1_year: "분석 기간 : 최근 1년",
    portfolio_option_period_3_year: "분석 기간 : 최근 3년",
    portfolio_option_period_5_year: "분석 기간 : 최근 5년",
    portfolio_option_period_10_year: "분석 기간 : 최근 10년",

    /**
     * 포트폴리오 구성 기업 개수
     */
    portfolio_option_company_count_10: "기업 개수(MAX) : 10개",
    portfolio_option_company_count_20: "기업 개수(MAX) : 20개",
    portfolio_option_company_count_30: "기업 개수(MAX) : 30개",

    /**
     * 포트폴리오 구성 방법
     */
    portfolio_option_weight_method_equal: "구성 방법 : 동일 비중",
    portfolio_option_weight_method_value: "구성 방법 : 시가 총액 비중",
    portfolio_option_weight_method_custom: "구성 방법 : 사용자 검색 조건",

    /**
     * 포트폴리오 거래 비용
     */
    portfolio_option_transaction_cost_00: "거래 비용 : 없음",
    portfolio_option_transaction_cost_01: "거래 비용 : 0.1%",
    portfolio_option_transaction_cost_05: "거래 비용 : 0.5%",
    portfolio_option_transaction_cost_10: "거래 비용 : 1.0%",
    portfolio_option_transaction_cost_15: "거래 비용 : 1.5%",
    portfolio_option_transaction_cost_20: "거래 비용 : 2.0%",

    /**
     * 포트폴리오 리밸런싱 주기
     */
    portfolio_option_rebalancing_daily: "리밸런싱 : 매일",
    portfolio_option_rebalancing_monthly: "리밸런싱 : 매월말",
    portfolio_option_rebalancing_quarterly: "리밸런싱 : 매분기말",
    portfolio_option_rebalancing_semi_annual: "리밸런싱 : 매반기말",
    portfolio_option_rebalancing_annual: "리밸런싱 : 매년말",
    portfolio_option_rebalancing_annual_june: "리밸런싱 : 매년 6월말",
    portfolio_option_rebalancing_no: "리밸런싱 : 없음",

    /**
     * Login 관련 신규 텍스트
     */
    message_unauthorized_error: "딥서치엑셀애드인 사용권한이 없는 계정입니다.(엔터프라이즈 혹은 프리미엄 이상 사용가능) 채널톡으로 문의 부탁드립니다.",
    message_login_error: "계정 또는 비밀번호가 틀립니다.",
    message_login_authentication_error: "계정 정보를 찾을 수 없습니다. 입력하신 계정으로 가입이 안되었거나, 인증 전 상태 입니다.",
    message_password_no_match_error: "비밀번호가 일치하지 않습니다.",
    message_password_match_error: "비밀번호가 일치합니다.",
    message_do_change: "변경 하시겠습니까?",
    message_can_not_change_email: "변경 할 수 없는 이메일입니다.",
    message_changed_email: "이메일이 변경되었습니다.",
    message_changed_password: "비밀번호가 변경되었습니다.",
    message_input_password: "비밀번호를 입력해 주세요.",
    message_not_found_user: "사용자의 데이터를 찾을 수 없습니다.",
    need_login_message: "{0} 로그인이 필요한 서비스입니다.",

    /**
     *  홈페이지 관련 신규 텍스트
     */
    // 홈페이지 메뉴
    about: "About",
    product: "제품",
    promotion: "홍보",
    press: "보도자료",
    blog: "블로그",
    notice: "알림",
    notice_event: "공지/이벤트",
    contact: "Contact",
    change_password: "비밀번호 변경",
    find_password: "비밀번호 찾기",
    current_password: "현재 비밀번호",
    change_email: "이메일 변경",
    email: "이메일",
    phone: "전화번호",
    address: "주소",
    zipcode: "우편번호",
    password: "비밀번호",
    new_password: "새로운 비밀번호",
    password_confirm: "비밀번호 확인",
    change: "변경",
    connect_with_facebook: "페이스북 계정 연결",
    overview: "Overview",
    function: "Function",
    features: "Features",
    business_rid: "사업자번호",
    company_rid: "법인번호",
    tel: "전화",
    fax: "팩스",
    website: "홈페이지",
    land_lot_ko: "지번 주소",
    road_name_ko: "도로명 주소",
    address_road_name: "도로명 주소",
    request_free_trial: "트라이얼 계정 신청 (무료)",
    request_demo: "데모 신청",
    introduce_service_pdf: "서비스 소개 (PDF)",
    message_deepsearch_overview:
        "딥서치 애널리틱스는 의사 결정 과정에서 손쉽게 자료를 수집하고, 분석하고, 관리할 수 있도록 지원합니다.\n이 과정은 단지 분석하고자 하는 주제와 관련된 몇 번의 키워드 입력만으로도 충분합니다.",
    /**
     * DeepSearch Proudct
     */
    message_deepsearch_introdution:
        "딥서치는 금융 빅데이터 제공 뿐만 아니라 금융 빅데이터에 대한 검색 및 분석 기술로 누구나 손쉽게 사용할 수 있도록 제공합니다.",
    /**
     * DDI Product
     */
    ddi: "DDI",
    ddi_full: "DeepSearch Data Intelligence",
    ddi_case: "적용 사례",

    message_ddi_introdution:
        "DeepSearch Data Intelligence(DDI)를 사용하면 재무 데이터를 수집하고 관리하는 시간과 비용을 최소화 할 수 있습니다.",
    /**
     * SNEK Product
     */
    snek: "SNEK",

    message_snek_introdution:
        "SNEK은 사용자 관심 기업에 대하여 깊이 있게 분석할 수 있도록 도와주는 투자 리서치 플랫폼입니다.",
    /**
     * Alerts Product
     */
    alerts: "Alerts",
    ddi_alerts: "DDI 경보",
    message_ddi_alerts_introdution:
        "DeepSearch는 위험 키워드를 강조 표시하고 해당 키워드로 관련 문서를 표시합니다. 이를 통해 사용자는 사전에 위험을 예방하고 투자 또는 대출 규모를 줄이기 위해 적시에 의사 결정을 내릴 수 있습니다.",

    /**
     * Index Product
     */
    index: "Index",
    index_various_topic: "",
    index_mega_trends_50: "DeepSearch MegaTrend 50",
    references: "References",

    message_index_introdution:
        "DeepSearch의 강력한 검색기능을 이용하여 자신만의 고유한 Index를 구성할 수 있습니다.",
    message_index_sub_introdution:
        "DeepSearch는 현재 50여개 주제 포트폴리오를 구성하여 공시하고 있습니다.",

    /**
     * Careers
     */
    recruit: "채용",
    recruit_apply: "지원",
    recruit_list: "목록",

    /*
     * Chart controls
     */
    chart_dropdown_companies: "기업",
    chart_dropdown_2nd_axis: "보조축 선택",

    /*
     * Dashboard
     */
    dashboard_default_group: "기본 그룹",
    dashboard_default_tile_kospi_index: "코스피 지수",
    dashboard_default_tile_kosdaq_index: "코스닥 지수",
    dashboard_default_tile_call_rates: "콜금리",
    dashboard_default_tile_krw_usd_xr: "원달러 환율",

    dashboard_group: "대시보드 그룹",
    dashboard_group_list_add_group: "그룹 추가",
    dashboard_group_list_add_empty_group: "비어있는 그룹 추가",
    dashboard_group_list_add_default_home_group: "기본 홈 그룹 추가",
    dashboard_group_list_add_securities_template: "관심 종목 템플릿 추가",

    dashboard_grid_menu_add_new_tile: "새로운 타일 추가",
    dashboard_grid_menu_add_favorite_securities: "관심 종목 추가",
    dashboard_grid_menu_add_favorite_industry: "관심 산업 추가",
    dashboard_grid_menu_add_trending_topics: "트렌딩 토픽 추가",
    dashboard_grid_menu_add_favorite_news: "관심 뉴스 추가",
    dashboard_grid_menu_data_select_wizard: "변수 선택 마법사",
    dashboard_grid_menu_write_query_directly: "딥서치 쿼리 직접 입력",

    trending_topic_tile_is_added: "트랜딩 토픽 타일이 추가되었습니다.",
    document_types: "문서 종류",

    dashboard_grid_menu_rename_group: "대시보드 그룹 이름 변경",
    dashboard_grid_menu_delete_group: "대시보드 그룹 삭제",

    dashboard_focus_menu_back_to_groups: "대시보드 그룹 그리드뷰로 돌아가기",
    dashboard_focus_menu_edit_tile: "대시보드 타일 수정",
    dashboard_focus_menu_delete_tile: "대시보드 타일 삭제",
    new_tab: "새 탭",
    start_page: "시작 페이지",
    error: "에러",

    related_articles: "관련 기사",

    dashboard_groups_control_rename: "이름 변경",
    dashboard_groups_control_delete: "삭제",
    dashboard_groups_control_adding_new_group: "새로운 그룹 추가 중...",

    dashboard_rename_group_modal_message: "새로운 그룹 이름을 입력하세요.",
    dashboard_delete_group_modal_message: "그룹을 삭제 하시겠습니까?",

    dashboard_write_query_modal_title: "딥서치 쿼리 입력해서 새로운 타일 추가하기",
    dashboard_write_query_modal_message: "타일 이름과 딥서치 쿼리를 아래에 입력해주세요.",
    dashboard_write_query_modal_tile_name: "타일 이름",
    dashboard_write_query_modal_deepsearch_query: "딥서치 쿼리",

    star_button_add_to_dashboard: "대시보드 추가",
    add_to_dashboard_completed: '"{0}"가 대시보드 "{1}" 그룹에 추가 되었습니다.',

    /**
     * Contact
     */
    contact_us: "Contact Us",
    name: "이름",
    email_address: "이메일 주소",
    message: "메세지",
    send: "전달",
    message_contact_us:
        "소중한 의견 및 제안을 기다리고 있습니다. 자유롭게 메시지를 남겨주시면, 빠르게 확인하고 연락 드리겠습니다.",
    contact_email: "contact@deepsearch.com",
    contact_phone: "070-8711-3338",
    contact_address: "서울특별시 서초구 서초대로 396, 강남빌딩 2층 204호 (주)딥서치",

    /**
     * ADMIN
     */
    lang: "언어",
    account: "계정",
    add_user: "사용자 추가",
    add_organization: "기업 추가",
    active: "활성",
    enable: "사용",
    disable: "사용 안함",
    updated_date: "업데이트",
    created_date: "생성일자",
    deleted_date: "삭제일자",
    last_active_date: "최근 활동",
    last_login_date: "최근 로그인",
    password_changed_date: "암호 변경 날짜",
    create: "생성",
    create_account_completed: "계정 생성완료",
    create_organization_completed: "기업 생성완료",
    search_user_data_placeholder: "결과 내 검색 및 사용자 검색",
    search_org_data_placeholder: "결과 내 검색 및 기업 검색",
    my_info: "내 정보",
    upgrade: "업그레이드",
    plan_upgrade: "플랜 업그레이드",
    user_info: "사용자 정보",
    user_data: "사용자 데이터",
    usage_status: "이용 현황",
    usage: "사용량",
    usage_limit: "사용한도",
    export_excel_company: "기업 엑셀 다운로드",
    export_excel_document: "문서 엑셀 다운로드",
    export_excel_portfolio: "포트폴리오 엑셀 다운로드",
    export_content_usage: "컨텐츠 다운로드 사용량",
    export_excel_remain: "잔여 엑셀 다운로드 개수",
    export_excel_target_count: "다운로드 대상 개수",
    export_excel_org_usage_message: "엑셀 다운로드 한도는 {0}에 갱신되며, 기업 단위로 관리됩니다.",
    export_excel_usage_message: "엑셀 다운로드 한도는 {0}에 갱신됩니다.",
    export_excel_empty_message:
        "남은 다운로드 가능 개수가 부족합니다. Premium 업그레이드 시 무제한 다운로드가 가능합니다.",
    member_seat_license: "Member Seat 라이센스",
    user_max_sessions: "사용자 동시접속",
    pro_access: "PRO 권한",
    premium_access: "PREMIUM 권한",
    non_external_audit_access: "비외감 재무정보 권한",
    subscription_term: "계약기간",
    subscription_status: "계약상태",
    auto_cancel: "자동취소",
    addable_subscription_item: "추가 가능한 아이템",
    subscription_modify: "계약기간 수정",
    subscription_delete: "이용권 삭제",
    trial_to_subscription: "이용권으로 변경",
    account_manager: "담당 어카운트 매니저",

    trial: "TRIAL",
    licensed_subscription: "이용권",
    metered_subscription: "종량제 이용권",
    start_date: "시작일",
    end_date: "종료일",
    unlimit: "무제한",
    end_trial_message: "{0}, TRIAL 기간이 만료 됩니다.",
    end_subs_message: "{0}, 기간이 만료 됩니다.",
    no_subscription_menu_block_message: "{0}을 수행하기 위해서는 월 정기 이용권이 필요합니다.",
    contact_inquiry: "계약 문의",
    delete_user_from_organization_message: "그룹에서 제거하시겠습니까?",
    scheduled_to_end: "종료 예정",
    scheduled_to_end_after_days: "{0}일 후 종료 예정",
    actived_seat: "활성 사용자",
    member_seat_change_message: "{0}의 활성상태를 변경 하시겠습니까?",
    seat_changed_message: "Seat 변경완료.",
    no_seat_message: "Seat가 부족합니다.",
    expired_message: "사용 기간이 만료되었습니다.",
    expired_detail_message:
        "사용 기간을 연장하거나, 정식 서비스 진행을 위하여 아래 담당자에게 문의를 부탁 드립니다.",
    empty_subscription_message: "사용 가능한 이용권이 없습니다.",
    message_not_enough_subscription_item: "{0} 이용권을 모두 사용하였습니다.",
    go_home: "홈으로 가기",
    metered_subscription_blind_message:
        "종량제 이용권 사용자는 엑셀 다운로드를 이용해 데이터를 확인 할 수 있습니다.",
    no_subscription_blind_message:
        "검색 결과를 확인할 수 있는 이용권이 없습니다. 우측 상단 이용 현황 버튼을 클릭하여, 계약 상태를 확인해 주세요.",
    account_plan: "플랜",
    extending_plan : "플랜 연장하기",

    /**
     * mail document list
     */
    mail_list: "메일 리스트",
    added_documents_mail: "메일 리스트에 추가된 문서",
    add_document_to_maillist: "메일 리스트에 문서가 추가되었습니다.",
    remove_document_from_maillist: "메일 리스트에서 문서가 삭제되었습니다.",
    error_document_to_maillist: "이미 추가된 문서입니다.",
    selected_industry_list: "분석 대상 산업",
    industry_overview: "산업 개요",
    industry_pie_chart_title: "산업 내 비중 분석",
    industry_bar_chart_title: "경쟁사 비교 분석",
    industry_peer_comparison_chart_title: "배수 기반 가치 비교",
    industry_past_history_title: "시계열 분석",
    analyst_report: "리포트",
    positive_news: "긍정적 뉴스",
    negative_news: "부정적 뉴스",
    all_news: "전체 뉴스",
    securities: "종목",
    keywords: "키워드",
    securities_list: "종목 리스트",
    news_feed_in: "{0} 뉴스 피드",
    selected_securities_sentiment_analysis: "선택된 종목에 대한 감성 분석",
    selected_topic_sentiment_analysis: "선택된 토픽에 대한 감성 분석",
    mind_map_graph: "마인드맵 그래프",
    spawn_conditions: "확장 조건",
    trending_keywords: "최신 이슈",
    related_keywords: "관련 키워드",
    similar_keywords: "유사 키워드",
    video_help_message: "동영상 TIP은 왼쪽 메뉴의 ‘도움말’을 통해 언제든 보실 수 있습니다.",
    dont_show_again: "다시 보지 않기",
    selected_company: "선택된 기업",
    selected_companies: `{name} {count, plural,
        =0 {}
        one {외 {count}개 기업}
        other {외 {count}개 기업}}`,
    selected_filter: "정렬 순서",
    go_up: "위로 가기",
    fundamentals_comparison: "비교 분석",
    user_custom: "사용자 정의",
    add_subject_message: "원하시는 주제를 입력하세요.",
    trending_topic_company: "트렌딩 토픽 기업",
    peer_relative_to: "비교 그룹",
    topic_base: "트렌딩 토픽 기반",
    market_base: "시장 기반",
    shareholding_base: "지분 기반",
    consensus_base: "컨센서스 기반",
    shareholders_base: "주주 기반",
    shareholder_company: "주주 기업",
    holding_value: "지분가치",
    current_price: "현재가",
    target_price: "목표가",
    prev_target_price: "직전목표가",
    target_price_rage: "목표주가 범위",
    price_change_ratio: "등락률",
    transaction_price: "거래대금",
    competition_section: "경쟁분야",
    stock_price_trend_competitors: "경쟁기업과의 주가변화 추이",
    competitors_portfolio: "경쟁기업 포트폴리오",
    remove_cache: "내부 저장 데이터 초기화",
    message_remove_cache: "캐시 제거가 완료되었습니다.",
    message_not_found_industry: "{0} 관련 산업을 찾을 수 없습니다.",
    company_history: "연혁",
    business_goal: "사업 분야",
    company_related_firms_and_business: "관계회사 및 사업분야",
    shareholders_info: "주주 정보",
    comperitor_comparison_analysis: "경쟁사 비교 분석",
    common_stock: "보통주",
    preferred_stock: "우선주",
    company_branches_info: "지점 정보",
    dividend_info: "배당 정보",
    dividend_rate: "배당률",
    company_shares_n_dividend_info: "주주 및 배당 정보",
    company_employee_info: "임직원 정보",
    company_employee_count_info: "임직원 숫자 추이",
    dart_based_company_employee_info: "평균급여 및 종업원수",
    permanent_employee: "정규직",
    contract_employee: "계약직",
    average_tenure: "평균 근속",
    average_salary: "평균 급여",
    total_annual_salary: "총 연간 급여",
    dart_based_company_executive_info: "이사회 임원 및 평균 보수",
    company_directors_compensation: "이사회 임원 보수 정보",
    non_registered_executives_included: "비등기 임원 포함",
    list_of_top_payers_by_individual: "개인별 상위 보수 지급자 목록",
    company_directors_info: "이사회 임원 목록",
    company_executive_info: "임원 정보",
    company_executive_count: "임원수",
    job_description: "업무",
    gender: "성별",
    relation: "관계",
    compensation: "보수",
    total_compensation: "전체 보수",
    avg_compensation: "평균 보수",
    tenure_duration: "임기 기간",
    tenure_end: "임기 종료",
    company_borrowings_info: "차입금 세부 구성 정보",
    company_related_firms: "자회사 및 관계사",
    shareholder_related_company: "주주 관련 기업",
    message_no_borrowings_info: "{0}년 차입금 정보가 없습니다.",
    message_shareholder_related_company: "주주가 개인일 경우 동명이인일 수 있습니다.",
    message_inaccurate_shareholder_related_company: "특정 인물이 주식을 보유한 기업 리스트는 동명 이인 처리가 안된 것으로 데이터가 정확하지 않을 수 있습니다.",
    research_consensus: "증권사 컨센서스",
    forecasted_financial_information: "증권사 컨센서스 ( 재무정보 )",
    research_consensus_average: "증권사 컨센서스 평균",
    research_consensus_info: "증권사 컨센서스 추이",
    target_price_and_opinion: "목표주가 및 투자의견",
    investment_opinion: "투자의견",
    prev_investment_opinion: "직전투자의견",
    collect_into_industry: "검색 결과 기반 산업 분석 더보기",
    more_search_results: "검색 결과 더보기",
    shareholders_change_rate: "지분변화율",
    upper_shareholders_related_network: "주주 네트워크",
    pin_to_top: "상단에 고정",
    presumptive_agency: "추정기관",
    presumptive_date: "추정일자",
    presumptive_year: "추정연도",
    latest_estimate: "최신 추정치",
    last_estimate: "직전 추정치",
    sales_growth_rate: "최근 3년 매출액 연평균 증가율",
    last_year_profitability_ratio: "직전연도 영업이익률",
    last_year_liabilities_ratio: "직전연도 부채비율",
    dividend_yield: "배당수익률",
    freq: "빈도",
    frequency: "빈도",
    keyword_freq: "키워드 빈도",
    business_summary: "사업 개요",
    business_status: "사업 현황",
    three_month_stock_return: "3개월 주가 수익률",

    /**
     * VALUATION
     */
    business_area_selection: "검색 조건 입력",
    enter_business_keywords: "비즈니스 키워드 입력",
    keyword_relationship: "키워드 관계",
    document_relationship: "출처문서 관계",
    business: "사업",
    business_domain: "사업 영역",
    industry_category: "산업 분류",
    report: "리포트",
    revenue: "매출",
    selected_recommend_condition: "선택 조건",
    selected_keywords: "선택된 키워드",
    recommended_condition: "추천",
    valuation_selected_companies: "선택 기업",
    industry_analysis_more: "선택 기업으로 산업 분석",
    beta_calculation_more: "선택 기업으로 BETA 계산",
    user_company_selection: "직접 기업 선택",
    user_add_condition: "사용자 조건",
    enter_keyword: "키워드 입력",
    reset: "초기화",
    valuation_recommend_condition: "추천 검색 조건",
    enter_condition: "조건입력",
    address_placeholder: "주소입력",
    direct_input: "직접 입력",
    and: "동시에",

    company_age: "업력",
    new_company: "신생",
    one_three_years: "1-3년",
    three_seven_years: "3-7년",
    seven_ten_years: "7-10년",
    ten_plus_years: "10년 이상",

    related_disclosure: "관련 공시",
    related_ir: "관련 IR",
    related_research: "관련 리포트",
    related_patent: "관련 특허",

    valuation_income_statement: "손익계산서",
    valuation_search_results: "기업목록",
    previous_info: "이전 정보",
    financial_accounts: "계정명",
    book_value: "북 밸류",
    interest_baring_debt: "이자부 부채",
    premium_analysis: "프리미엄",

    /**
     * Beta Calculation
     */
    beta_calculation: "베타 계산",
    company_selection: "기업 선택",
    beta_calculation_criteria: "베타 계산",
    yeild_based: "수익률 기준",
    select_period: "기간선택",
    interest_liability_base_year: "이자부부채 기준 연도",
    total_trading_dates: "총거래일",
    apply_beta_calculation: "적용베타 계산",
    view_yield_scatter_plot: "수익률 산포도 보기",
    standard_index: "기준지수",
    // interest_liability_one_million_won: "이자부 부채 (백만원)",
    // market_cap_one_million_won: "시가 총액 (백만원)",
    analysis_period: "분석 기간",
    yeild_calculation_criteria: "수익률 계산 기준",
    reference_date: "조회 기준일",

    download_all_financial_statements: "전체 재무제표 엑셀 다운로드",

    /**
     * Billing
     */
    purchase: "결제하기",
    payment_history: "결제내역",
    card_number: "카드번호",
    expiry_date: "유효기간",
    card_first_2_digit: "카드 비밀번호 앞 2자리",
    enter_card_info: "카드정보 입력",
    message_safe_payment_info: "결제 정보는 SSL 프로토콜로 안전하게 전송됩니다.",
    message_omit_dash: "- 생략하고 입력",
    message_already_registered_card: "이미 다른 계정에서 사용중인 카드 입니다. 다른 카드를 입력하세요.",
    birthday_or_business_rid: "생년월일 6자리(개인) / 사업자 번호 10자리(법인)",
    choose_plan: "플랜 선택",
    free_for_a_month: "한달 무료 사용",
    signup_for_free: "무료 회원가입",
    join : "가입",
    cancel_subscription: "구독 취소",
    recover_subscription: "구독 재개",
    start_subscription: "구독 시작",
    subscription_info: "구독 정보",
    view_history: "내역 보기",
    data_download: "데이터 다운로드",
    payment_method: "결제 수단",
    payment_card: "결제카드",
    card_change: "카드 변경",
    card_registeration: "카드 등록",
    admission: "승인",
    pay_date: "결제일",
    next_pay_date: "다음 결제일",
    pay_amount: "결제금액",
    remark: "비고",
    receipt: "영수증",
    no_payment_info: "결제 내역이 없습니다.",
    failure: "실패",
    payment_required: "결제필요",
    no_card_info: "카드정보 없음",
    change_plan: "플랜 변경",
    cancel_choose_plan: "플랜 선택 취소",
    card_owner_name: "카드 소유자 이름",
    format_input_length_error: "{0} 자리 이상의 값을 입력해야합니다.",
    contact_biz_inquiry: "영업팀 문의",

    //People
    show_all_target_search_result: "{0} 전체 결과 보기",
    owned_share: "보유 지분",
    name_search: "이름 검색",
    company_name_search: "기업명 검색",
    message_company_name_search: "기업명으로 검색",
    choose_company_first: "기업을 먼저 선택해 주세요",
    find_name_first: "이름을 먼저 검색해 주세요",

    //Search
    quick_guide: "딥서치 퀵가이드",
    message_deepsearch_guide: "딥서치를 다양하게 활용할 수 있는 가이드를 제공합니다.",
    main_search_placeholder: "검색어를 입력해 주세요.",
    top_search_placeholder: "통합 검색",
    view_related_document: "관련문서 보기",
    close_related_document: "관련문서 닫기",
    wikipedia : "위키피디아",

    //Bookmark
    bookmark : "북마크",
    add_bookmark : "북마크 추가",
    remove_bookmark : "북마크 제거",
    message_add_bookmark : "북마크에 추가했습니다",
    message_remove_bookmark : "북마크에서 제거했습니다",
    message_empty_bookmark : "추가된 북마크가 없습니다",
    message_invalid_date : "설정된 날짜가 정확하지 않습니다",
};
