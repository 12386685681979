import { AppInfo } from ".";

const Nice: AppInfo = {
  uid: "735633870199853056",
  name: "NICE",
  displayName: "Kisline DeepSearch",
  appId: "deepsearch-kisline",
  gaCode: "UA-118044144-1",
  gtmId: "",
  hosts: ["www.kisline.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "/deepsearch/favicon.ico",
  appIcon: "/deepsearch/images/apple-homescreen.png",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.kisline.com",
  logoUrl: "/public/partners/nice-enterprise-logo.png",
  title_en: "DeepSearch | Data for your decisions",
  title_ko: "DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default Nice;
