import { AppInfo } from ".";

const DeepSearch: AppInfo = {
  uid: "1033446395606077440",
  name: "miraeasset",
  displayName: "미래에셋증권",
  appId: "deepsearch-miraeasset",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["miraeasset.deepsearch.com", "alpha-miraeasset.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "https://img.securities.miraeasset.com/new2016/layout/favicon.ico",
  appIcon: "https://img.securities.miraeasset.com/new2016/layout/favicon.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://securities.miraeasset.com",
  logoUrl: "https://img.securities.miraeasset.com/renew2019/ico_footer_logo.png",
  title_en: "미래에셋증권 - DeepSearch | Data for your decisions",
  title_ko: "미래에셋증권 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default DeepSearch;
