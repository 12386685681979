import { IncomingHttpHeaders } from "http";
import { IHTTPRequestPodModel } from "../../../../../../model/compute/IPods";

import _DataPod from "../_DataPod";

export default class _HTTPRequest extends _DataPod implements IHTTPRequestPodModel {
  get contentBody(): string {
    return this.content.content;
  }
  get headers(): IncomingHttpHeaders {
    return this.content.headers;
  }

  get encoding(): string {
    return this.content.encoding;
  }

  get cookies(): any {
    return this.content.cookies;
  }

  get reason(): any {
    return this.content.reason;
  }

  get statusCode(): any {
    return parseInt(this.content.status_code);
  }

  get url(): any {
    return this.content.url;
  }
}
