import { AppInfo } from ".";

const SAMSUNG_SECURITIES: AppInfo = {
  uid: "993021200546533376",
  name: "삼성증권",
  displayName: "삼성증권",
  appId: "deepsearch-samsungsecurities",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["samsungsecurities.deepsearch.com", "alpha-samsungsecurities.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "/favicon.ico",
  appIcon: "/images/apple-homescreen.png",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.samsungsecurities.co.kr",
  logoUrl: "https://www.samsungsecurities.co.kr/kor/img/com/logo-a.png",
  title_en: "삼성증권 - DeepSearch | Data for your decisions",
  title_ko: "삼성증권 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default SAMSUNG_SECURITIES;
