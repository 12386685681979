import AuthModel from "../AuthModel";

export default class ApplicationModel extends AuthModel {

    get authPolicy(): string {
        return this._data.auth_policy;
    }
    get permissions(): string {
        return this._data.permissions;
    }
    get displayName(): string {
        return this._data.display_name;
    }
}
