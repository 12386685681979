import { validateSync } from "class-validator";

import { IComputeModel } from "../model/compute/IComputeModel";
import ComputeAPIService from "../network/api/ComputeAPIService";
import computeParser from "../utils/ComputeParser";
import { FSModel } from "../model/function/FS";
// import translateModel from "../model/function/Translate";
import { _pushOpreation } from "./BatchProcess";
import { isLogin } from "../utils/AuthUtil";

/**
 * 딥서치 서치엔진을 이용합니다.
 * @customfunction QUERY
 * @cancelable
 * @param input DeepSearch Query Input
 * @param rows 행 기준으로 아래로 n칸 이동
 * @param cols 열 기준으로 오른쪽으로 n칸 이동
 * @param height 배열의 높이
 * @param width 배열의 너비
 * @param invocation
 * @helpurl https://help.deepsearch.com/data-cloud/custom-function
 * @returns {string[][]} String to write.
 */

export function Query(
  input: string,
  rows?: number,
  cols?: number,
  height?: number,
  width?: number
  // invocation?: CustomFunctions.CancelableInvocation
) {
  // invocation.onCanceled = () => {
  //   _popOperation("query", {
  //     input,
  //     rows,
  //     cols,
  //     height,
  //     width,
  //   });
  // };

  if (!isLogin()) {
    return [["Login is required!!"]];
  }
  return _pushOpreation("query", {
    input,
    rows,
    cols,
    height,
    width,
  });
}

CustomFunctions.associate("QUERY", Query);

/**
 * 재무제표항목을 조회합니다.
 * entity: (삼성전자 / KRX:005930)
 * | date : (YYYY-MM-DD)
 * | type : (IS, BS, CF)
 * | annual : (분기, 연도)
 * | accumulated : (누적, 증분)
 * | consolidated : (연결, 별도)
 * | account : 재무계정코드
 * @customfunction FS
 * @param entity 회사명 혹은 Symbol을 입력합니다. (Ex : 삼성전자 / KRX:005930)
 * @param date 조회할 기간을 설정합니다. (YYYY-MM-DD)
 * @param type 조회할 재무제표를 설정합니다. (IS, BS, CF)
 * @param annual 연간 재무제표만 조회할 지 여부를 설정합니다.(분기 / 연도)
 * @param accumulated 누적, 증분
 * @param consolidated 연결, 별도
 * @param account 재무계정 code를 입력합니다.
 * @helpurl https://help.deepsearch.com/data-cloud/custom-function
 * @returns {string} 조회한 재무정보의 정보
 */

// ? > GetFinancialStatements(삼성전자, report_type="IFRS",date_from=2015-01-01, date_to=2015-12-31))

export function FS(
  entity: string,
  date: number,
  type: string,
  annual: string,
  accumulated: string,
  consolidated: string,
  account: string
  // invocation?: CustomFunctions.CancelableInvocation
) {
  if (!isLogin()) {
    return "Login is required!!";
  }
  const model = new FSModel();

  model.entity = entity;
  model.dateNumber = date;
  model.date = model.convertDate();
  model.type = type;
  model.annual = annual;
  model.accumulated = accumulated;
  model.consolidated = consolidated;
  model.account = account;

  const errors = validateSync(model);
  if (errors?.length > 0) {
    return errors.reduce((e, c) => {
      return e + c?.constraints?.isLength ?? "";
    }, "");
  }

  const { date_from, date_to } = model.parsingDate();

  const FSQuery: string = `GetFinancialStatements(${
    model.entity
  },is_accumulated=${model.parsingAccumulated()},report_ids="${model.parsingType()}",consolidated=${model.parsingConsolidated()},is_annual=${model.parsingAnnual()}, date_from=${date_from}, date_to=${date_to})`;

  // invocation.onCanceled = () => {
  //   _popOperation("fs", {
  //     FSQuery,
  //     account,
  //   });
  // };

  return _pushOpreation("fs", {
    FSQuery,
    account,
  });
}

CustomFunctions.associate("FS", FS);

// netstat -ano | findstr :3000
// taskkill /PID <PID> /F

/**
 * 실시간 시세를 조회합니다.
 * @customfunction getMarketData
 * @param entity 회사명 혹은 Symbol을 입력합니다. (Ex : 삼성전자 / KRX:005930)
 * @param type open : 시가 | high : 고가 | low : 저가 | close : 종가 | shareVolume : 거래량 | tradeVolue : 거래금액
 * @helpurl https://help.deepsearch.com/data-cloud/custom-function
 * @returns {string} 조회한 회사의 현재 주식가격
 */

export function getMarketData(entity: string, type: string, invocation: CustomFunctions.StreamingInvocation<string>) {
  const query: string = `${entity} ${type}`;

  const timer = setInterval(() => {
    const api = ComputeAPIService.compute(query);

    api
      .then((compute: IComputeModel) => {
        const { data } = computeParser(compute.dataPod);
        const price: string = data[1][3];
        invocation.setResult(price);
      })
      .catch(() => {
        invocation.setResult("올바르지않은 쿼리입니다.");
      });
  }, 1000);

  invocation.onCanceled = () => {
    clearInterval(timer);
  };
}

CustomFunctions.associate("getMarketData", getMarketData);

/**
 * 파파고 번역 api를 이용한 번역기능을 이용합니다.
 * @customfunction translate
 * @param input 번역할 텍스트
 * @param source_language 번역할 텍스트의 언어
 * @param target_language 번역 후 텍스트의 언어
 * @param honorific 존댓말여부
 * @returns {string} input값에 입력된 텍스트를 번역하여 target_language 텍스트를 반환합니다.
 */

// export function translate(input: string, source_language?: string, target_language?: string, honorific?: boolean) {
//   if (!isLogin()) {
//     return "Login is required!!";
//   }
//   if (source_language == null) {
//     source_language = "en";
//   }

//   if (target_language == null) {
//     target_language = "ko";
//   }

//   if (honorific == null) {
//     honorific = false;
//   }

//   const post_body: translateModel = {
//     source: source_language,
//     target: target_language,
//     text: input,
//     honorific: honorific,
//   };

//   return _pushOpreation("translate", {
//     post_body,
//   });
// }

// CustomFunctions.associate("translate", translate);

CustomFunctions.associate("QUERY", Query);
CustomFunctions.associate("FS", FS);
CustomFunctions.associate("GETMARKETDATA", getMarketData);