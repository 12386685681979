import { AppInfo } from ".";

const DeepSearch: AppInfo = {
  uid: "1041757341319565312",
  name: "stonebridge",
  displayName: "스톤브릿지",
  appId: "deepsearch-stonebridge",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["stonebridge.deepsearch.com", "alpha-stonebridge.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico:
    "https://static.wixstatic.com/media/d138cf_c1a1b9af864548dda86035847c32e90b%7Emv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/d138cf_c1a1b9af864548dda86035847c32e90b%7Emv2.png",
  appIcon:
    "https://static.wixstatic.com/media/d138cf_c1a1b9af864548dda86035847c32e90b%7Emv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/d138cf_c1a1b9af864548dda86035847c32e90b%7Emv2.png",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.stonebridge.co.kr/",
  logoUrl: "/public/partners/stone-bridge.png",
  title_en: "스톤브릿지 - DeepSearch | Data for your decisions",
  title_ko: "스톤브릿지 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default DeepSearch;
