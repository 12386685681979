import PageDataModel from "../PageDataModel";
import DocumentModel from "../document/DocumentModel";
/**
current_page: 1
docs: []
last_page: 0
max_score: null
profile: {compile: 1, query: 2, fetch_cached_snapshot: 0, fetch_new_query_result: 4, fetch_cached_query_result: 0, …}
scroll_id: "0"
 */
export default class DocumentsWrapperModel implements PageDataModel<DocumentModel> {
  private _data: any;
  constructor(data: any) {
    this._data = data;
  }

  get currentPage(): number {
    return this._data.current_page;
  }

  get lastPage(): number {
    return this._data.last_page;
  }

  get totalMatches() {
    return this._data.total_matches;
  }

  get list() {
    return this._data.docs.map((d: DocumentModel) => {
      return new DocumentModel(d);
    });
  }

  get found() {
    return this._data.found;
  }

  isLast(): boolean {
    return this.currentPage === this.lastPage;
  }
}
