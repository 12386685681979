import en from "./strings/en";
import ko from "./strings/ko";

interface IndexedValue<T> {
  [key: string]: T;
}

const DEFAULT_LANG = "en_US";
const ko_KR: IndexedValue<string> = ko;
const en_US: IndexedValue<string> = en;

const Strings: IndexedValue<IndexedValue<string>> = {
  ko_KR,
  en_US,
};

export class TextObject implements TextObject {
  _id: any;
  _value: string;
  constructor(id: any, value: string) {
    this._id = id;
    this._value = value;
  }

  get id(): any {
    return this._id;
  }

  get value(): string {
    if (this._value) {
      return this._value;
    } else if (Strings[DEFAULT_LANG][this._id]) {
      return Strings[DEFAULT_LANG][this._id];
    } else {
      console.warn("Can not found value in this language", this._id);
    }
    return this._id;
  }
}

class LocaleSetting implements IndexedValue<any> {
  _stringRes: IndexedValue<string>;
  _language: string;
  // [key: string]: any | null;
  constructor() {
    /**
     * DeepSearch 버전만 다국어 지원함.
     */
    this._language = "ko_KR";
    this._init();
    // if (Config.TYPE.DEEPSEARCH === Config.App) {
    //   this._init();
    // }
    this._stringRes = Strings[this._language];
    // this._texts = {};
    for (let key in Strings[DEFAULT_LANG]) {
      let value = this._stringRes[key];
      let property = {
        configurable: false,
        enumerable: true,
        value: new TextObject(key, value),
        writable: false,
      };
      Object.defineProperty(this, key, property);
    }

    // document.documentElement.lang = this.languageCode;
  }

  _init() {
    let lang = localStorage.getItem("lang");
    if (!lang) {
      lang = window.navigator.language;
      lang = lang.replace("-", "_");
    }
    for (let key in Strings) {
      if (key === lang) {
        this._language = key;
        break;
      }
    }
    if (!this._language) {
      this._language = DEFAULT_LANG;
    }
  }

  get language(): string {
    return this._language;
  }

  set language(lang: string) {
    if (lang) {
      let updateLang = null;
      for (let key in Strings) {
        if (key === lang) {
          updateLang = key;
          break;
        }
      }
      if (updateLang) {
        localStorage.setItem("lang", updateLang);
        window.location.reload();
      } else {
        console.warn("Unsupport language locale ", lang);
      }
    }
  }

  get languageCode(): string {
    return this._language.split("_")[0];
  }

  get conuntryCode(): string {
    return this._language.split("_")[1];
  }

  get korean() {
    return this.languageCode === "ko";
  }

  /**
   *
   * @param {React.Component | any} obj
   * @param {TextObject | string} text TextObject or key
   */
  formatMessage(obj: any, text: string | TextObject, format?: any) {
    let intl = null;
    if (obj.props) {
      intl = obj.props.intl;
    } else if (obj.intl) {
      intl = obj.intl;
    } else {
      intl = obj;
    }
    if (intl) {
      if (text instanceof TextObject) {
        return intl.formatMessage(
          {
            id: text.id,
            defaultMessage: text.value,
          },
          format
        );
      } else {
        let string = this[text];
        if (string && string instanceof TextObject) {
          return intl.formatMessage(
            {
              id: string.id,
              defaultMessage: string.value,
            },
            format
          );
        } else {
          return text;
        }
      }
    } else {
      throw Error(`USE import { injectIntl } from "react-intl"; !!!`);
    }
  }
}

const Locale = new LocaleSetting();
export default Locale;
