import { AppInfo } from ".";

const MIDASASSET: AppInfo = {
  uid: "993024813360615424",
  name: "마이다스에셋자산운용",
  displayName: "마이다스에셋자산운용",
  appId: "deepsearch-midasasset",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["midasasset.deepsearch.com", "alpha-midasasset.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "/favicon/midass.ico",
  appIcon: "/favicon/midass.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "http://midasasset.com",
  logoUrl: "http://midasasset.com/wp-content/themes/midasasset_new/images/img_logo.png",
  title_en: "마이다스에셋자산운용 - DeepSearch | Data for your decisions",
  title_ko: "마이다스에셋자산운용 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default MIDASASSET;
