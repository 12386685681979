import axios, { CancelTokenSource } from "axios";
import { IAxiosCacheAdapterOptions, setup } from "axios-cache-adapter";
import * as localforage from "localforage";
import { from } from "rxjs";
import { retry, tap } from "rxjs/operators";

import Config from "../resources/Config";
import DomainConfig from "../resources/DomainConfig";
import Locale from "../resources/Locale";

export const DEEPSEARCH_AUTH_KEY =
  "Basic NTM0MTdkYWY3YTdkNGU3OGI3MzVlZTMzMzEyZTQzZTQ6NzYyMjEyNjA0MWExMDg2ZDZjNjU3MTA2ZGNmOTUxYzIzNTdmNTc4OWUyZDEyZmVlYjhmMWQ1MmE3MGExZjA3MA==";

const AUTH_ID = "ds_excel";
const AUTH_PWD = "ds_excel_23jncdsvv";

const CACHE_MAX_AGE = 5 * 60 * 1000;
const RETRY_COUNT = 3;

const DEBUG_MODE = window?.__RUNTIME_CONFIG__?.REACT_APP_STAGE != "production";
const ERROR_LOG = Config.DEBUG_MODE;

const CancelTokenMap: { [key: string]: CancelTokenSource } = {};

export const store = localforage.createInstance({
  name: "datacloud",
});

const cacheOption: IAxiosCacheAdapterOptions = {
  maxAge: CACHE_MAX_AGE,
  clearOnStale: true,
  store: store,
  exclude: {
    query: false,
  },
  debug: DEBUG_MODE,
};

const api = axios.create({
  withCredentials: true,
  headers: {
    Authorization: DEEPSEARCH_AUTH_KEY,
  },
});

const cacheApi = setup({
  withCredentials: true,
  headers: {
    Authorization: DEEPSEARCH_AUTH_KEY,
  },
  cache: cacheOption,
});

const onError = function (error: any) {
  if (ERROR_LOG) {
    if (error.message && error.message.indexOf("Cancel") < 0) {
      console.error("NETWORK ERROR MESSAGE - ", error.message);
    }

    if (error.response) {
      console.error("NETWORK ERROR CODE - ", error.response.status);
      console.error("NETWORK ERROR BODY - ", error.response.data);
    }

    if (error.request) {
      console.error("NETWORK ERROR REQUEST -", error.request);
    }
  }
};

export interface Params {
  [key: string]: string;
}

export interface Cache {
  maxAge: number;
  unit?: "sec" | "min" | "hour" | "day";
}

export class ApiPromise<T> extends Promise<T> {
  cancelSource: any;
  subCalls?: ApiPromise<any>[];
  constructor(
    executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
    cancelSource: any,
    subCalls?: ApiPromise<any>[]
  ) {
    super(executor);
    this.cancelSource = cancelSource;
    this.subCalls = subCalls;
  }

  cancel(msg?: string): void {
    if (this.cancelSource) {
      this.cancelSource.cancel(msg ? msg : "Cancel");
    }
    if (this.subCalls) {
      this.subCalls.forEach((api) => api.cancel());
    }
  }
}

export default class APIService {
  static handleRequest(apiPromise: Promise<any>, resolve: (e: any) => void, reject: (e: any) => void, key: string) {
    const onResponseCompleted = (response: any) => {
      if (DEBUG_MODE) {
        console.log(response.request);
      }
      delete CancelTokenMap[key];
      resolve(response);
    };

    const onResponseError = (error: any) => {
      delete CancelTokenMap[key];
      if (error.message !== "Cancel") {
        reject(error);
      }
    };
    apiPromise.then(onResponseCompleted).catch((error) => {
      const { response } = error;
      const doRetry = (function () {
        if (response && response.status) {
          const { status } = response;
          if (status >= 400 && status <= 499) {
            return false;
          }
        }
        return true;
      })();
      if (doRetry) {
        from(apiPromise).pipe(tap(null, onError), retry(RETRY_COUNT)).subscribe(onResponseCompleted, onResponseError);
      } else {
        onResponseError(error);
      }
    });
  }

  static post(
    url: string,
    params: Params | any,
    headers?: Params | any,
    options?: Params | any,
    ignoreCancelMap?: boolean
  ) {
    const _params = {
      locale: Locale.language,
      ...params,
    };
    const source = axios.CancelToken.source();
    const key = `${url}/${JSON.stringify(params)}`;
    if (!ignoreCancelMap) {
      CancelTokenMap[key] = source;
    }
    return new ApiPromise((resolve, reject) => {
      const config = {
        cancelToken: source.token,
        ...options,
      };
      if (!DEBUG_MODE) {
        config.auth = {
          username: AUTH_ID,
          password: AUTH_PWD,
        };
      }
      if (headers) {
        config.headers = headers;
        config.headers["X-DeepSearch-App-ID"] = DomainConfig.appId;
      }
      this.handleRequest(api.post(url, _params, config), resolve, reject, key);
    }, source);
  }

  static get(
    url: string,
    params?: Params | any,
    headers?: Params | any,
    options?: Params | any,
    ignoreCancelMap?: boolean
  ) {
    const _params = {
      locale: Locale.language,
      ...params,
    };
    const source = axios.CancelToken.source();
    const key = `${url}/${JSON.stringify(params)}`;
    if (!ignoreCancelMap) {
      CancelTokenMap[key] = source;
    }
    return new ApiPromise((resolve, reject) => {
      const data = {
        cancelToken: source.token,
        params: _params,
        ...options,
      };
      if (!DEBUG_MODE) {
        data.auth = {
          username: AUTH_ID,
          password: AUTH_PWD,
        };
      }
      if (headers) {
        data.headers = headers;
        data.headers["X-DeepSearch-App-ID"] = DomainConfig.appId;
      }
      this.handleRequest(api.get(url, data), resolve, reject, key);
    }, source);
  }

  static cacheGet(
    url: string,
    params?: Params | any,
    headers?: Params | any,
    options?: Params | any,
    cache?: Cache,
    ignoreCancelMap?: boolean
  ) {
    const _params = {
      locale: Locale.language,
      ...params,
    };
    const source = axios.CancelToken.source();
    const key = `${url}/${JSON.stringify(params)}`;
    if (!ignoreCancelMap) {
      CancelTokenMap[key] = source;
    }
    return new ApiPromise((resolve, reject) => {
      const data = {
        cancelToken: source.token,
        params: _params,
        ...options,
      };
      if (!DEBUG_MODE) {
        data.auth = {
          username: AUTH_ID,
          password: AUTH_PWD,
        };
      }
      if (headers) {
        data.headers = headers;
        data.headers["X-DeepSearch-App-ID"] = DomainConfig.appId;
      }
      if (cache) {
        let maxAge = cache.maxAge;
        switch (cache.unit) {
          case "sec":
            maxAge *= 1000;
            break;
          case "min":
            maxAge *= 1000 * 60;
            break;
          case "hour":
            maxAge *= 1000 * 60 * 60;
            break;
          case "day":
            maxAge *= 1000 * 60 * 60 * 24;
            break;
          default:
        }
        data.cache = {
          maxAge: maxAge,
        };
      }
      this.handleRequest(cacheApi.get(url, data), resolve, reject, key);
    }, source);
  }

  // static clearCache(callback?: (error: any) => void) {
  //   store.clear((err: any) => {
  //     if (callback) {
  //       callback(err);
  //     }
  //   });
  // }

  static cancelAllRequests = () => {
    Object.keys(CancelTokenMap).forEach((key) => {
      const source = CancelTokenMap[key];
      if (source) {
        source.cancel("Cancel");
      }
      delete CancelTokenMap[key];
    });
  };
}

// const KEY_LASTEST_VERSION = "lastest-version";
// const lastestVersion = localStorage.getItem(KEY_LASTEST_VERSION);
// if (Config.DEEPSEARCH_APP_VERSION !== lastestVersion) {
//   APIService.clearCache(() => {
//     localStorage.setItem(KEY_LASTEST_VERSION, Config.DEEPSEARCH_APP_VERSION);
//     console.log("CLEAR CACHE FOR NEW VERSION. PREV - ", lastestVersion, ", CURRENT - " + Config.DEEPSEARCH_APP_VERSION);
//   });
// }
