import { IDataFrame, ISearchResultPodModel, SearchResultCategory } from "../../../../../../model/compute/IPods";

import _DataPod from "../_DataPod";
import { _DataFrame } from "./_DataFrame";

export default class _SearchResultPod extends _DataPod implements ISearchResultPodModel {
  getCategory(category: SearchResultCategory): IDataFrame {
    return new _DataFrame(this.content[category]);
  }

  private category(category: SearchResultCategory) {
    return this.content[category];
  }

  get query(): string {
    return this.content["query"] || this.inputPod.input;
  }

  get isEmpty(): boolean {
    if (
      this.category("addresses") ||
      this.category("bond_classes") ||
      this.category("bond_issuers") ||
      this.category("business_area") ||
      this.category("business_summary") ||
      this.category("companies") ||
      this.category("related_companies") ||
      this.category("economic_indicators") ||
      this.category("executives") ||
      this.category("industries") ||
      this.category("shareholders") ||
      this.category("wikipedia")
    ) {
      return false;
    }
    return true;
  }
}
