import { AppInfo } from ".";

const DeepSearch: AppInfo = {
  uid: "1051896008474234880",
  name: "myangel",
  displayName: "동양생명",
  appId: "deepsearch-myangel",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["myangel.deepsearch.com", "alpha-myangel.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "",
  favico: "https://www.myangel.co.kr/favicon.ico",
  appIcon: "https://www.myangel.co.kr/favicon.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.myangel.co.kr/",
  logoUrl: "https://www.myangel.co.kr/image/myangel_main/ci.png",
  title_en: "동양생명 - DeepSearch | Data for your decisions",
  title_ko: "동양생명 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default DeepSearch;
