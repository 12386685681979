import { IAccountingStandardsModel } from "../../../../../../model/compute/IPods";
import _SubPod from "../_SubPod";

export default class _AccountingStandards extends _SubPod implements IAccountingStandardsModel {
  get type(): "IFRS" | "GAAP" {
    return this.content.type;
  }

  get period(): string {
    return this.content.period;
  }

  get consolidated(): boolean {
    return this.content.consolidated;
  }

  get ifrs(): boolean {
    return this.type === "IFRS";
  }

  get annual(): boolean {
    return this.period === "Annual";
  }
}
