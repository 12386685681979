import Locale from "../../../../resources/Locale";
import AuthModel from "../../AuthModel";

type Interval = "day" | "month";

export type TierName =
  | "Pro"
  | "Premium"
  | "Enterprise"
  | "Bond"
  | "Patent"
  | "Event"
  | "Discovery"
  | "Valuation"
  | "Data"
  | "Session";
export type Tier = {
  value: number;
  name: TierName;
};

export class Plan {
  id: string;
  name: string;
  fullName: string;
  tier: Tier;
  constructor(id: string, name: string, fullName: string, tier: Tier) {
    this.id = id;
    this.name = name;
    this.fullName = fullName;
    this.tier = tier;
  }
}

export class OrganizationPlan extends Plan {}

export class UserPlan extends Plan {}

export class PermitPlan extends UserPlan {}

export const ProductInterval = {
  Day: "day",
  Month: "month",
};

export const ProductCurrency = {
  KRW: "KRW",
};

export const ProductPlan = {
  EXPORT_EXCEL_ENTITY: new Plan("export.excel.entity", Locale.company.value, Locale.export_excel_company.value, {
    value: 1,
    name: "Data",
  }),
  EXPORT_EXCEL_DOCUMENT: new Plan("export.excel.document", Locale.document.value, Locale.export_excel_document.value, {
    value: 1,
    name: "Data",
  }),
  EXPORT_EXCEL_PORTFOLIO: new Plan(
    "export.excel.portfolio",
    Locale.portfolio.value,
    Locale.export_excel_portfolio.value,
    { value: 1, name: "Data" }
  ),
  USER_MAX_SESSION: new UserPlan("user.max_sessions", Locale.user_max_sessions.value, Locale.user_max_sessions.value, {
    value: 1,
    name: "Session",
  }),
  MEMBER_SEAT_LICENSE: new OrganizationPlan(
    "org.seat.members",
    Locale.member_seat_license.value,
    Locale.member_seat_license.value,
    { value: 1, name: "Session" }
  ),

  /**
   * Pro
   */
  PRO_ACCESS: new PermitPlan("v2.access.pro", Locale.pro_access.value, Locale.pro_access.value, {
    value: 2,
    name: "Pro",
  }),

  /**
   * Premium
   */
  PREMIUM_ACCESS: new PermitPlan("v2.access.premium", Locale.premium_access.value, Locale.premium_access.value, {
    value: 3,
    name: "Premium",
  }),

  /**
   * DATA PACK
   */

  PATENT_PERMIT: new PermitPlan(
    "v2.permit.patent_search",
    Locale.patent_analysis.value,
    `Permit ${Locale.patent_analysis.value}`,
    {
      value: 0,
      name: "Patent",
    }
  ),
  BOND_PERMIT: new PermitPlan("v2.permit.bond_search", Locale.bond_search.value, `Permit ${Locale.bond_search.value}`, {
    value: 0,
    name: "Bond",
  }),
  EVENT_STUDY_PERMIT: new PermitPlan(
    "v2.permit.event_study",
    Locale.event_study.value,
    `Permit ${Locale.event_study.value}`,
    {
      value: 0,
      name: "Event",
    }
  ),
  COMPANY_DISCOVERY_PERMIT: new PermitPlan(
    "v2.permit.company_discovery",
    Locale.company_discovery.value,
    `Permit ${Locale.company_discovery.value}`,
    {
      value: 0,
      name: "Discovery",
    }
  ),
  VALUATION_PERMIT: new PermitPlan("v2.permit.valuation", Locale.valuation.value, `Permit ${Locale.valuation.value}`, {
    value: 0,
    name: "Valuation",
  }),
  NON_EXTERNAL_AUDIT_PERMIT: new PermitPlan(
    "v2.permit.nice.non_external_audit",
    Locale.non_external_audit_access.value,
    `Permit ${Locale.non_external_audit_access.value}`,
    {
      value: 4,
      name: "Enterprise",
    }
  ),
  /**
   * Web
   */
  UNLIMIT_FAKE_EXCEL: new OrganizationPlan("unlimit.excel.deepsearch", Locale.unlimit.value, Locale.unlimit.value, {
    value: 3,
    name: "Pro",
  }),
};

export default class ProductPlanModel extends AuthModel {
  get description(): string {
    return this._data.description;
  }

  get productId(): string {
    return this._data.product_id;
  }

  get currency() {
    return this._data.currency;
  }

  get amount(): number {
    if (this._data.amount) {
      return this._data.amount;
    }
    return 0;
  }

  get interval(): Interval {
    return this._data.interval;
  }

  get intervalCount(): number {
    if (this._data.interval_count) {
      return this._data.interval_count;
    }
    return 0;
  }

  get days(): number {
    if (this.interval === ProductInterval.Day) {
      return 1 * this.intervalCount;
    } else if (this.interval === ProductInterval.Month) {
      return 1 * this.intervalCount * 30;
    }
    return this.intervalCount;
  }

  get months(): number {
    if (this.interval === ProductInterval.Day) {
      return (1 * this.intervalCount) / 30;
    } else if (this.interval === ProductInterval.Month) {
      return 1 * this.intervalCount;
    }
    return this.intervalCount;
  }

  get aggregateUsage() {
    return this._data.aggregate_usage;
  }

  get billingScheme() {
    return this._data.billing_scheme;
  }

  get usageType() {
    return this._data.usage_type;
  }

  get tiers() {
    return this._data.tiers;
  }

  get tiersMode() {
    return this._data.tiers_mode;
  }

  get localizationName() {
    let name = this.name;
    if (this.plan) {
      name = this.plan.name;
    }
    return name;
  }

  get tier(): Tier | null {
    return this.plan ? this.plan.tier : null;
  }

  get plan(): Plan | null {
    for (let key in ProductPlan) {
      const data = ProductPlan[key];
      if (data.id === this.productId) {
        return data;
      }
    }
    return null;
  }
}
