import _SubPod from "../_SubPod";
import { IResultSizeWarningPodModel } from "../../../../../../model/compute/IPods";

export default class _ResultWarning extends _SubPod implements IResultSizeWarningPodModel {
  get total(): number {
    return this.content.data.original_size;
  }

  get count(): number {
    return this.content.data.reduced_size;
  }
}
