import { AppInfo } from ".";

const NICE_KISLINE: AppInfo = {
  uid: "987806108968161280",
  name: "나이스평가정보",
  displayName: "나이스평가정보",
  appId: "deepsearch-nice",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["kisline.deepsearch.com", "alpha-kisline.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "/favicon.ico",
  appIcon: "/images/apple-homescreen.png",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.kisline.com",
  logoUrl: "/public/partners/nice-enterprise-logo.png",
  title_en: "나이스평가정보 - DeepSearch | Data for your decisions",
  title_ko: "나이스평가정보- DeepSearch | Data for your decisions",
  storeName: "딥서치",
  manage: "true",
  excelAddInList: [],
};
export default NICE_KISLINE;
