import moment from "moment";
import { ProductPlan, Tier } from "../billing/item/ProductPlanModel";

import SubscriptionItemModel from "../billing/item/SubscriptionItemModel";
import UnlimitSubscriptionModel from "../billing/UnlimitSubscriptionModel";
import CustomerModel from "../CustomerModel";
import OrganizationModel from "../org/OrganizationModel";

export default class UserModel extends CustomerModel {
  get customerName(): string {
    return this.username;
  }

  get username(): string {
    return this._data.username;
  }

  get email(): string {
    return this._data.email;
  }

  get teamName(): string {
    if (this._data.affiliation) {
      if (this._data.affiliation["department"]) {
        return this._data.affiliation["department"];
      }
    }
    if (this._data.attributes) {
      if (this._data.attributes["user:team"]) {
        return this._data.attributes["user:team"];
      } else if (this._data.attributes.team) {
        return this._data.attributes.team;
      } else if (this._data.attributes["org:department"]) {
        return this._data.attributes["org:department"];
      }
    }
    return "";
  }

  get position(): string {
    if (this._data.affiliation) {
      if (this._data.affiliation["position"]) {
        return this._data.affiliation["position"];
      }
    }
    if (this._data.attributes) {
      if (this._data.attributes["org:position"]) {
        return this._data.attributes["org:position"];
      } else if (this._data.attributes.position) {
        return this._data.attributes.position;
      }
    }
    return "";
  }

  get organizationName(): string {
    if (this._data.affiliation) {
      if (this._data.affiliation["org_name"]) {
        return this._data.affiliation["org_name"];
      }
    }
    if (this._data.attributes) {
      if (this._data.attributes["org:name"]) {
        return this._data.attributes["org:name"];
      } else if (this._data.attributes["org:display_name"]) {
        return this._data.attributes["org:display_name"];
      }
    }
    return "";
  }

  get permissions() {
    return this._data.permissions;
  }

  get enabled(): boolean {
    return this._data.is_active;
  }

  get passwordExpired() {
    return this._data.is_password_expired;
  }

  get lastPasswordChangedDate(): string {
    if (this._data.last_password_change) {
      return this._convertDate(this._data.last_password_change);
    }
    return this._data.last_password_change;
  }

  get passwordChangedDate(): string {
    return this._convertDate(this._data.last_password_change);
  }

  get lastSignInDate(): string {
    return this._convertDate(this._data.last_signin);
  }

  get lastActiveDate(): string {
    return this._convertDate(this._data.last_active);
  }

  get isSuperUser(): boolean {
    return this._data.is_superuser;
  }

  get isStaffUser(): boolean {
    return this._data.is_staff;
  }

  get userType(): string {
    if (this.isSuperUser) {
      return "Admin";
    } else if (this.isStaffUser) {
      return `Staff`;
    }
    return "";
  }

  get organizations(): OrganizationModel[] {
    let orgs = [];
    if (this._data.member_of) {
      orgs = this._data.member_of.map((org: any) => new OrganizationModel(org));
    }
    return orgs;
  }

  get allSubscriptions() {
    const subsList = this.subscriptionsInUse;
    this.organizations.forEach((org) => {
      subsList.push(...org.subscriptionsInUse);
    });
    return subsList.sort((a, b) => {
      if (a.isPeriodic && b.isPeriodic) {
        if (a.periodEndAt && !b.periodEndAt) {
          return 1;
        } else if (!a.periodEndAt && b.periodEndAt) {
          return -1;
        } else if (a.periodEndAt && b.periodEndAt) {
          const aDate = moment(a.periodEndAt);
          const bDate = moment(b.periodEndAt);
          if (aDate.diff(bDate) < 0) {
            return -1;
          } else {
            return 1;
          }
        }
      } else if (a.isPeriodic && !b.isPeriodic) {
        return -1;
      } else if (!a.isPeriodic && b.isPeriodic) {
        return 1;
      } else if (!a.isPeriodic && !b.isPeriodic) {
        const aDate = moment(a.cycleAnchor);
        const bDate = moment(b.cycleAnchor);
        if (aDate.diff(bDate) < 0) {
          return -1;
        } else {
          return 1;
        }
      }
      return 0;
    });
  }

  get entireSubscriptions() {
    const subsList = this.subscriptions;
    this.organizations.forEach((org) => {
      subsList.push(...org.subscriptions);
    });
    return subsList;
  }

  get subscriptionItems() {
    const items: SubscriptionItemModel[] = [];
    /**
     * Unlimit Subscription과 Subscription 이 섞여있을 경우 Subscription만 전달.
     */
    const unlimitSubsList = this.allSubscriptions.filter((subs) => subs instanceof UnlimitSubscriptionModel);
    if (this.allSubscriptions.length > unlimitSubsList.length) {
      this.allSubscriptions
        .filter((subs) => !(subs instanceof UnlimitSubscriptionModel))
        .forEach((subs) => {
          const subsItems = subs.items.filter((item) => item.itemId !== ProductPlan.MEMBER_SEAT_LICENSE.id);
          items.push(...subsItems);
        });
    } else {
      Array.from(new Set(unlimitSubsList)).forEach((subs) => {
        items.push(...subs.items);
      });
    }
    return items;
  }

  get hasExpired() {
    return this.allSubscriptions.length <= 0;
  }

  get hasOnlyLicensedSubscription() {
    const subsList = this.allSubscriptions;
    if (subsList && subsList.length > 0) {
      const licensedSubsList = subsList.filter((subs) => subs.isPeriodic);
      return licensedSubsList.length === subsList.length;
    }
    return false;
  }

  get hasLicensedSubscription() {
    const subsList = this.allSubscriptions;
    if (subsList && subsList.length > 0) {
      const licensedSubsList = subsList.filter((subs) => subs.isPeriodic);
      return licensedSubsList.length > 0;
    }
    return false;
  }

  get hasOnlyMeteredSubscription() {
    const subsList = this.allSubscriptions;
    if (subsList && subsList.length > 0) {
      const meteredSubsList = subsList.filter((subs) => !subs.isPeriodic);
      return meteredSubsList.length === subsList.length;
    }
    return false;
  }

  get subscriptionTiers(): Tier[] {
    const tiers: Tier[] = [];
    const subscription = this.allSubscriptions;
    if (subscription.length > 0) {
      subscription.forEach((item) => {
        tiers.push(...item.tiers);
      });
      return tiers.sort((a, b) => {
        return b.value - a.value;
      });
    }

    return tiers;
  }

  get subscriptionTier(): Tier | null {
    if (this.subscriptionTiers.length > 0) {
      return this.subscriptionTiers[0];
    }
    return null;
  }

  get type(): "user" | "org" {
    return "user";
  }

  get isNew(): boolean {
    return this._data.is_new;
  }

  get freeTierStartAt(): string | null {
    if (this._data.billing_info && this._data.billing_info.trial_started_at) {
      return this._data.billing_info.trial_started_at;
    }
    return null;
  }

  get cumulativeAmount() {
    if (this._data.billing_info && this._data.billing_info.cumulative_amount) {
      return this._data.billing_info.cumulative_amount;
    }
    return 0;
  }

  get usingFreeTier(): boolean {
    if (this.subscriptionsInUse.length > 0) {
      const subs = this.subscriptionsInUse[0];
      if (subs && subs.isTrial && this.freeTierStartAt) {
        if (moment(subs.createdAt).diff(moment(this.freeTierStartAt), "hour") === 0) {
          return true;
        }
      }
    }
    return false;
  }
}
