import { ISubPodModel } from "../../../../../model/compute/IPods";

export default class _SubPod implements ISubPodModel {
  private _data: any;
  constructor(data: any) {
    this._data = data;
  }

  get class(): string | null {
    return this._data ? this._data.class : null;
  }

  get content(): any | null {
    return this._data ? this._data.content : null;
  }

  get position(): number {
    return this._data ? this._data.position : -1;
  }

  get data(): any {
    return this._data;
  }

  get title(): string | null {
    return this._data ? this._data.title : null;
  }

  get subpods(): any[] | null {
    return this._data ? this._data.subpods : null;
  }
}
