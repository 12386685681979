import AuthModel from "../AuthModel";
import UserModel from "./UserModel";

export default class OrganizationUserModel extends AuthModel {
  get role() {
    return this._data.role;
  }

  get user(): UserModel {
    return new UserModel(this._data.user);
  }

  get hasSeat(): boolean {
    return this._data.enabled;
  }

  get username(): string {
    return this.user.username;
  }

  get email(): string {
    return this.user.email;
  }

  get name(): string {
    return this.user.name;
  }

  get lastActiveDate() {
    return this.user.lastActiveDate;
  }

  get createdAt() {
    return this.user.createdAt;
  }
}
