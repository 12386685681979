export enum TagName {
  Property = "property",
  Entity = "entity",
  DateRange = "daterange",
  Keyword = "keyword",
  Function = "function",
  Date = "date",
  Integer = "integer",
}

export class TagModel {
  readonly _node: any;
  constructor(node: any) {
    this._node = node;
  }

  get name(): string | null {
    if (this._node) {
      return this._node.localName;
    }
    return null;
  }

  get fullValue(): string | null {
    if (this._node) {
      return this._node.textContent;
    }
    return null;
  }

  get value(): string | null {
    if (this.fullValue) {
      let split = this.fullValue.split("/");
      if (split.length > 0) {
        return split[split.length - 1];
      } else {
        return this.fullValue;
      }
    }
    return null;
  }

  get type(): string | null {
    if (this._node && this._node.attributes) {
      for (let i = 0; i < this._node.attributes.length; i++) {
        const node = this._node.attributes[i];
        if (node.localName === "type") {
          return node.textContent;
        }
      }
    }
    return null;
  }

  get symbol(): string | null {
    if (this._node && this._node.attributes) {
      for (let i = 0; i < this._node.attributes.length; i++) {
        const node = this._node.attributes[i];
        if (node.localName === "symbol") {
          return node.textContent;
        }
      }
    }
    return null;
  }
}
