import { Length, IsString, IsDate, Matches } from "class-validator";

// const TYPE_ERROR_MESSAGE = "올바른 재무제표 타입을 입력해주세요.\n1: 손익계산서 / 2: 재무상태표 / 3: 현금흐름표";
// const ACCUMULATED_ERROR_MESSAGE = "올바른 값을 입력해주세요. \n1: 누적 / 2: 비누적";
// const CONSOLIDATED_ERROR_MESSAGE = "올바른 값을 입력해주세요. \n1: 연결 / 2: 별도";
// const ANNUAL_ERROR_MESSAGE = "올바른 값을 입력해주세요. \n1: 전체 / 2: Only 연도재무제표";
const ACCOUNTID_ERROR_MESSAGE = "올바른 값 읿력해주세요.";

interface Idate {
  date_from: string;
  date_to: string;
}

const reportType = {
  IS: "Income",
  BS: "BalanceSheet",
  CF: "CashFlow",
};

export class FSModel {
  @IsString()
  entity: string;

  dateNumber: number;

  @IsDate()
  date: Date;

  @IsString()
  @Matches(/(IS|BS|CF)/)
  type: string;

  @IsString()
  @Matches(/(분기|연도)/)
  annual: string;

  @IsString()
  @Matches(/(누적|증분)/)
  accumulated: string;

  @IsString()
  @Matches(/(연결|별도)/)
  consolidated: string;

  @IsString()
  @Length(6, 6, {
    message: ACCOUNTID_ERROR_MESSAGE,
  })
  account: string;

  convertDate() {
    return new Date((this.dateNumber - 25569) * 86400 * 1000);
  }

  parsingDate(): Idate {
    const quarter: number = Math.floor((this.date.getMonth() - 1) / 3) + 1;

    let date_from: Date = new Date(this.date.getFullYear(), quarter * 3 - 2, 1);
    let date_to: Date = new Date(this.date.getFullYear(), quarter * 3 + 1, 1);

    const parsed_date_from: string =
      this.annual === "분기"
        ? `${date_from.getFullYear().toString()}-${date_from.getMonth().toString().padStart(2, "0")}-01`
        : `${this.date.getFullYear().toString()}-01-01`;

    const parsed_date_to: string =
      this.annual === "분기"
        ? `${date_to.getFullYear().toString()}-${date_to.getMonth().toString().padStart(2, "0")}-01`
        : `${this.date.getFullYear().toString()}-12-31`;

    return {
      date_from: parsed_date_from,
      date_to: parsed_date_to,
    };
  }

  parsingType(): string {
    return reportType[this.type];
  }

  parsingConsolidated(): string {
    return this.consolidated === "연결" ? "true" : "false";
  }

  parsingAnnual(): string {
    return this.annual === "분기" ? "false" : "true";
  }

  parsingAccumulated(): string {
    return this.accumulated === "누적" ? "true" : "false";
  }
}
