import { AppInfo } from ".";

const LAIC: AppInfo = {
  uid: "1101628994299760640",
  name: "lindeman",
  displayName: "린드먼아시아",
  appId: "deepsearch-laic",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["laic.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "/favicon.ico",
  appIcon: "/images/apple-homescreen.png",
  helpUrl: "https://intelligent-drifter-3c5.notion.site/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://intelligent-drifter-3c5.notion.site/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "http://laic.kr/",
  logoUrl: "http://laic.kr/img/foot_logo.png",
  title_en: "린드먼아시아 - DeepSearch | Data for your decisions",
  title_ko: "린드먼아시아 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
};

export default LAIC;
