import { AppInfo } from ".";

const SHINHAN_VC: AppInfo = {
  uid: "1107368320505614336",
  name: "신한벤처투자",
  displayName: "신한벤처투자",
  appId: "deepsearch-shinhanvc",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["shinhanvc.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "https://www.shinhaninvest.com/siw/common/images/shinhan.ico",
  appIcon: "https://www.shinhaninvest.com/siw/common/images/shinhan.ico",
  helpUrl: "https://deepsearch-jira.atlassian.net/servicedesk/customer/portals",
  noticeUrl: "https://intelligent-drifter-3c5.notion.site/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "http://www.shinhanvc.com",
  logoUrl: "http://www.shinhanvc.com/common/images/logo_desktop_kr.png",
  title_en: "신한벤처투자 - DeepSearch | Data for your decisions",
  title_ko: "신한벤처투자 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default SHINHAN_VC;
