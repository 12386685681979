import { AppInfo } from ".";

const DeepSearch: AppInfo = {
  uid: "1076159534713671680",
  name: "hunet",
  displayName: "휴넷",
  appId: "deepsearch-hunet",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["hunet.deepsearch.com", "alpha-hunet.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "",
  favico: "https://www.hunet.co.kr/favicon.ico",
  appIcon: "https://www.hunet.co.kr/favicon.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.hunet.co.kr/",
  logoUrl: "https://img.hunet.co.kr/hunet/main_v3/layout_header_01.png",
  title_en: "휴넷 - DeepSearch | Data for your decisions",
  title_ko: "휴넷 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default DeepSearch;
