const moment = require("moment").default || require("moment");
import Locale from "../../../../../../resources/Locale";
import _SubPod from "../_SubPod";
import { IMarketStatusPodModel } from "../../../../../../model/compute/IPods";

export default class _MarketStatus extends _SubPod implements IMarketStatusPodModel {
  get referenceTime(): string | null {
    if (this.content) {
      return this.content.reference_time;
    }
    return null;
  }

  get status(): string | null {
    if (this.content) {
      return this.content.status;
    }
    return null;
  }

  get marketStatus(): string | null {
    if (this.referenceTime && this.status) {
      let status = Locale.market_closed.value;
      if (this.status === "regular") {
        status = Locale.market_open.value;
      }
      const refTime = moment(this.referenceTime).format(Locale.format_market_time.value);
      return `${refTime} (${status})`;
    }
    return "";
  }
}
