import { IDataPodModel } from "../model/compute/IPods";

interface IDataFrame {
  columns: string[];
  data: string[][];
  dataTypes: string[];
  dataLength: number;
}

const DOCUMENT_COLUMNS = [
  "created_at",
  "category",
  "section",
  "publisher",
  "author",
  "title",
  "content",
  "content_url",
  "securities",
  "entities",
  "industry",
  "polarity",
];
const DOCUMENT_DISPLAY_COLUMNS = [
  "date",
  "category",
  "section",
  "publisher",
  "author",
  "title",
  "content",
  "content_url",
  "securities",
  "entities",
  "industry",
  "polarity",
];
const DOCUMENT_COLUMNS_TYPES = [
  "datetime64",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
];

const TREND_RESULT_COLUMNS = ["key", "value", "count"];
const TREND_RESULT_DISPLAY_COLUMNS = ["date", "value", "count"];
const TREND_RESULT_COLUMNS_TYPES = ["datetime64", "float", "int64"];

const TRENDING_TOPIC_SEARCH_RESULT_COLUMNS = [
  "date",
  "category",
  "section",
  "rank",
  "score",
  "topic",
  "uid",
  "keywords",
  "securities",
  "industries",
  "polarities",
];
const TRENDING_TOPIC_SEARCH_RESULT_DISPLAY_COLUMNS = [
  "date",
  "category",
  "section",
  "rank",
  "score",
  "topic",
  "topic_id",
  "keywords",
  "securities",
  "industries",
  "polarities",
];
const TRENDING_TOPIC_SEARCH_RESULT_COLUMNS_TYPES = [
  "datetime64",
  "string",
  "string",
  "int64",
  "float",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
];

const HISTORICAL_TOPICS_SEARCH_RESULT_COLUMNS = [
  "date",
  "category",
  "section",
  "rank",
  "score",
  "topic",
  "uid",
  "keywords",
  "securities",
  "entities",
];
const HISTORICAL_TOPICS_SEARCH_RESULT_DISPLAY_COLUMNS = [
  "date",
  "category",
  "section",
  "rank",
  "score",
  "topic",
  "topic_id",
  "keywords",
  "securities",
  "entities",
];
const HISTORICAL_TOPICS_SEARCH_RESULT_COLUMNS_TYPES = [
  "datetime64",
  "string",
  "string",
  "int64",
  "float",
  "string",
  "string",
  "string",
  "string",
  "string",
];

const HISTORICAL_TOPICS_SEARCH_SPECIFIC_RESULT_COLUMNS = [
  "created_at",
  "category",
  "section",
  "publisher",
  "author",
  "title",
  "content",
  "content_url",
  "rank",
  "score",
  "topic",
  "uid",
  "keywords",
  "securities",
  "entities",
];
const HISTORICAL_TOPICS_SEARCH_SPECIFIC_RESULT_DISPLAY_COLUMNS = [
  "date",
  "category",
  "section",
  "publisher",
  "author",
  "title",
  "content",
  "content_url",
  "topic_rank",
  "topic_score",
  "topic_title",
  "topic_id",
  "topic_keywords",
  "topic_securities",
  "topic_entities",
];
const HISTORICAL_TOPICS_SEARCH_SPECIFIC_RESULT_COLUMNS_TYPES = [
  "datetime64",
  "string",
  "string",
  "int64",
  "float",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
  "string",
];

const trendingTopicResultParser = (dataContent: any): IDataFrame => {
  let columns: string[] = [];
  let data: string[][] = [];
  let dataTypes: string[] = [];
  let dataLength: number = 0;

  const timestamp = dataContent.data.timestamp;
  const trendingTopicSearchResultList = dataContent.data.topics.news;

  Object.keys(trendingTopicSearchResultList).forEach((key) => {
    trendingTopicSearchResultList[key].forEach((topic: Object) => {
      let parsedList: string[] = [];
      TRENDING_TOPIC_SEARCH_RESULT_COLUMNS.forEach((column: string) => {
        if (column === "date") {
          parsedList.push(timestamp);
        } else if (column === "keywords") {
          const parsedKeywords: string = topic[column]
            .map((keyword: Object) => {
              return `${keyword["keyword"]}`;
            })
            .join("|");
          parsedList.push(parsedKeywords);
        } else if (column === "industries") {
          const parsedIndustry: string = topic["statistics"][column]
            .map((industry: Object) => {
              return `${industry["label"]}:${industry["name"]}`;
            })
            .join("|");
          parsedList.push(parsedIndustry);
        } else if (column === "polarities") {
          const parsedPolarity: string = topic["statistics"][column]
            .map((polarity: Object) => {
              return `${polarity["name"]}:${polarity["mean"]}`;
            })
            .join("|");
          parsedList.push(parsedPolarity);
        } else if (column === "securities") {
          const parsedSecurities: string = topic["statistics"][column]
            .map((security: Object) => {
              return `${security["name"]}`;
            })
            .join("|");
          parsedList.push(parsedSecurities);
        } else {
          parsedList.push(topic[column]);
        }
      });
      data.push(parsedList);
    });
  });

  columns = TRENDING_TOPIC_SEARCH_RESULT_DISPLAY_COLUMNS;
  dataTypes = TRENDING_TOPIC_SEARCH_RESULT_COLUMNS_TYPES;
  dataLength = data.length;

  return {
    columns,
    data,
    dataTypes,
    dataLength,
  };
};

const historicalTopicsSearchResultParser = (dataContent: any): IDataFrame => {
  let columns: string[] = [];
  let data: string[][] = [];
  let dataTypes: string[] = [];
  let dataLength: number = 0;

  const historicalTopicsSearchResultList = dataContent.data.topics;

  historicalTopicsSearchResultList.forEach((topic: Object) => {
    let parsedList: string[] = [];

    HISTORICAL_TOPICS_SEARCH_RESULT_COLUMNS.forEach((column: string) => {
      if (column === "keywords") {
        const parsedKeywords: string = topic[column]
          .map((keyword: Object) => {
            return `${keyword["keyword"]}`;
          })
          .join("|");
        parsedList.push(parsedKeywords);
      } else if (column === "entities") {
        const parsedEntities: string = topic[column]
          .map((entity: Object) => {
            return `${entity["name"]}`;
          })
          .join("|");
        parsedList.push(parsedEntities);
      } else if (column === "securities") {
        const parsedSecurities: string = topic[column]
          .map((security: Object) => {
            return `${security["name"]}`;
          })
          .join("|");
        parsedList.push(parsedSecurities);
      } else {
        parsedList.push(topic[column]);
      }
    });
    data.push(parsedList);
  });

  columns = HISTORICAL_TOPICS_SEARCH_RESULT_DISPLAY_COLUMNS;
  dataTypes = HISTORICAL_TOPICS_SEARCH_RESULT_COLUMNS_TYPES;

  return {
    columns,
    data,
    dataTypes,
    dataLength,
  };
};

const historicalTopicsSearchSpecificResultParser = (dataContent: any): IDataFrame => {
  let columns: string[] = [];
  let data: string[][] = [];
  let dataTypes: string[] = [];
  let dataLength: number = 0;

  const historicalTopicsSearchSpecificResultList = dataContent.data.topics.news;

  Object.keys(historicalTopicsSearchSpecificResultList).forEach((key) => {
    historicalTopicsSearchSpecificResultList[key].forEach((topic: Object) => {
      topic["docs"].forEach((doc: Object) => {
        let parsedList: string[] = [];

        HISTORICAL_TOPICS_SEARCH_SPECIFIC_RESULT_COLUMNS.forEach((column: string) => {
          if (column === "keywords") {
            const parsedKeywords: string = topic[column]
              .map((keyword: Object) => {
                return `${keyword["keyword"]}`;
              })
              .join("|");
            parsedList.push(parsedKeywords);
          } else if (column === "entities") {
            const parsedEntities: string = topic[column]
              .map((entity: Object) => {
                return `${entity["name"]}`;
              })
              .join("|");
            parsedList.push(parsedEntities);
          } else if (column === "securities") {
            const parsedSecurities: string = topic[column]
              .map((security: Object) => {
                return `${security["name"]}`;
              })
              .join("|");
            parsedList.push(parsedSecurities);
          } else if (column == "rank" || column == "score" || column == "topic") {
            parsedList.push(topic[column]);
          } else {
            parsedList.push(doc[column]);
          }
        });
        data.push(parsedList);
      });
    });
  });

  columns = HISTORICAL_TOPICS_SEARCH_SPECIFIC_RESULT_DISPLAY_COLUMNS;
  dataTypes = HISTORICAL_TOPICS_SEARCH_SPECIFIC_RESULT_COLUMNS_TYPES;
  dataLength = data.length;

  return {
    columns,
    data,
    dataTypes,
    dataLength,
  };
};

const computeParser = (dataPod: IDataPodModel): IDataFrame => {
  let { content: inputContent } = dataPod.inputPod;
  let { class: resultType, content: dataContent } = dataPod.data;

  let columns: string[] = [];
  let data: string[][] = [];
  let dataTypes: string[] = [];
  let dataLength: number = 0;

  switch (resultType) {
    case "Result:DocumentSearchResult": {
      const docsList = dataContent.data.docs;
      docsList.forEach((doc: Object) => {
        let parsedList: string[] = [];
        DOCUMENT_COLUMNS.forEach((column: string) => {
          let parsedSecurities: string[] = [];
          if (doc[column] == null) {
            parsedList.push("");
          } else {
            if (column === "securities" || column === "entities") {
              doc[column].forEach((security: Object) => {
                parsedSecurities.push(security["name"]);
              });
              parsedList.push(parsedSecurities.join("|"));
            } else if (column === "industry") {
              let parsedIndustry = `${doc[column]["label"]}:${doc[column]["name"]}`;
              parsedList.push(parsedIndustry);
            } else if (column === "polarity") {
              parsedList.push(doc[column]["name"] == null ? "" : doc[column]["name"]);
            } else {
              parsedList.push(doc[column]);
            }
          }
        });
        data.push(parsedList);
      });

      columns = DOCUMENT_DISPLAY_COLUMNS;
      dataTypes = DOCUMENT_COLUMNS_TYPES;

      break;
    }
    case "Result:DataFrame": {
      columns = dataContent.columns;
      const sampleData: [] = dataContent.data[columns[0]];
      dataLength = sampleData.length;

      for (let i = 0; i < dataLength; i++) {
        let parsedList: string[] = [];
        columns.forEach((column) => {
          parsedList.push(dataContent.data[column][i]);
        });
        data.push(parsedList);
      }

      dataTypes = columns.map((column) => dataContent.dtypes[column]);
      break;
    }
    case "Result:DocumentTrendsResult": {
      const documentTrendsResultList = dataContent.data.trends[0].buckets;

      documentTrendsResultList.forEach((bucket: Object) => {
        let parsedList: string[] = [];
        TREND_RESULT_COLUMNS.forEach((column: string) => {
          parsedList.push(bucket[column]);
        });
        data.push(parsedList);
      });

      columns = TREND_RESULT_DISPLAY_COLUMNS;
      dataTypes = TREND_RESULT_COLUMNS_TYPES;
      break;
    }
    case "Result:TrendingTopicsSearchResult": {
      ({ columns, data, dataTypes } = trendingTopicResultParser(dataContent));
      break;
    }
    case "Result:TrendingTopicResult": {
      break;
    }
    case "Result:HistoricalTopicsSearchResult": {
      let functionName: string = inputContent.input;

      if (functionName.includes("SearchHistoricalTopics")) {
        ({ columns, data, dataTypes } = historicalTopicsSearchResultParser(dataContent));
      } else {
        ({ columns, data, dataTypes } = historicalTopicsSearchSpecificResultParser(dataContent));
      }

      break;
    }
    case "Result:PortfolioReturns":
      break;
    case "Result:PortfolioAnalysis":
      break;
    case "Result:UnifiedSearchResult":
      const unifiedCompanyResult = dataContent.companies;
      columns = unifiedCompanyResult.columns;

      const sampleData: [] = unifiedCompanyResult.data[columns[0]];
      dataLength = sampleData.length;

      for (let i = 0; i < dataLength; i++) {
        let parsedList: string[] = [];
        columns.forEach((column) => {
          parsedList.push(unifiedCompanyResult.data[column][i]);
        });
        data.push(parsedList);
      }

      dataTypes = columns.map((column) => unifiedCompanyResult.dtypes[column]);

    default:
  }

  dataTypes.forEach((type: string, i: number) => {
    if (type == "datetime64") {
      data.map((row) => row.splice(i, 1, row[i]?.replace("T00:00:00", "")));
    }
  });

  data.unshift(columns);

  return {
    columns,
    data,
    dataTypes,
    dataLength,
  };
};

export default computeParser;
