import { AppInfo } from ".";

const DB_FI: AppInfo = {
  uid: "997975056376270848",
  name: "DB금융투자",
  displayName: "DB금융투자",
  appId: "deepsearch-db-fi",
  gaCode: "UA-118044144-2",
  gtmId: "GTM-N76G2NQ",
  hosts: ["db-fi.deepsearch.com", "alpha-db-fi.deepsearch.com"],
  chTalkPluginKey: "7c9f9d66-edf7-4a21-be3d-157a514a7445",
  blogUrl: "https://blog.deepsearch.com",
  favico: "https://www.db-fi.com/favicon.ico",
  appIcon: "https://www.db-fi.com/favicon.ico",
  helpUrl: "https://www.notion.so/1994b0737f5c43b1ad497897602fe374",
  noticeUrl: "https://www.notion.so/cd87e8c22d7845149e99f13e538049ea",
  serviceUrl: "https://www.deepsearch.com",
  homepage: "https://www.db-fi.com",
  logoUrl: "https://www.db-fi.com/img/common/lg_dongbu.gif",
  title_en: "DB금융투자 - DeepSearch | Data for your decisions",
  title_ko: "DB금융투자 - DeepSearch | Data for your decisions",
  storeName: "딥서치",
  excelAddInList: [],
};

export default DB_FI;
