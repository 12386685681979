const moment = require("moment");
import Config from "../resources/Config";

export type QUARTER_TYPE = "start" | "end";

export default class DSUtil {
  static quarter_start_option = {
    "1Q": {
      start: "01-01",
      end: "03-31",
    },
    "2Q": { start: "04-01", end: "06-30" },
    "3Q": { start: "07-01", end: "09-30" },
    "4Q": { start: "10-01", end: "12-31" },
  };

  static modifyCDNAddress(url) {
    if (Config.App === Config.TYPE.NICE) {
      url = url.replace(Config.CDN_HOST_SNEK, Config.CDN_HOST_NICE);
    }
    return url;
  }

  static getConvertedPeriods(input) {
    let today = moment().format("YYYY-MM-DD");
    let yearago = moment().subtract(1, "year").format("YYYY-MM-DD");
    let firstday = moment().format("YYYY-01-01");
    let thisyear = moment().format("YYYY");
    let lastyear = moment().subtract(1, "year").format("YYYY");
    let beforelastyear = moment().subtract(2, "year").format("YYYY");

    let convertedInput = input.replace("{from_1_year_ago}", `${yearago}-${today}`);
    convertedInput = convertedInput.replace(/{today}/g, `${today}`);

    convertedInput = convertedInput.replace(
      /{end_days_of_last_year}/g,
      `${moment().subtract(1, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_2_years_ago}/g,
      `${moment().subtract(2, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_3_years_ago}/g,
      `${moment().subtract(3, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_4_years_ago}/g,
      `${moment().subtract(4, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_5_years_ago}/g,
      `${moment().subtract(5, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_6_years_ago}/g,
      `${moment().subtract(6, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_7_years_ago}/g,
      `${moment().subtract(7, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_8_years_ago}/g,
      `${moment().subtract(8, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_9_years_ago}/g,
      `${moment().subtract(9, "year").format("YYYY-12-31")}`
    );
    convertedInput = convertedInput.replace(
      /{end_days_of_10_years_ago}/g,
      `${moment().subtract(10, "year").format("YYYY-12-31")}`
    );

    convertedInput = convertedInput.replace(/{last_year}/g, `${lastyear}`);
    convertedInput = convertedInput.replace(/{LFY}/g, `${thisyear}`);
    convertedInput = convertedInput.replace(/{before_last_year}/g, `${beforelastyear}`);
    convertedInput = convertedInput.replace(
      /{2_years_before_last_year}/g,
      `${moment().subtract(3, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{3_years_before_last_year}/g,
      `${moment().subtract(4, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{4_years_before_last_year}/g,
      `${moment().subtract(5, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{5_years_before_last_year}/g,
      `${moment().subtract(6, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{6_years_before_last_year}/g,
      `${moment().subtract(7, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{7_years_before_last_year}/g,
      `${moment().subtract(8, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{8_years_before_last_year}/g,
      `${moment().subtract(9, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{9_years_before_last_year}/g,
      `${moment().subtract(10, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{10_years_before_last_year}/g,
      `${moment().subtract(11, "year").format("YYYY")}`
    );
    convertedInput = convertedInput.replace(
      /{from_1_month_ago}/g,
      `${moment().subtract(1, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_2_month_ago}/g,
      `${moment().subtract(2, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_3_month_ago}/g,
      `${moment().subtract(3, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_4_month_ago}/g,
      `${moment().subtract(4, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_5_month_ago}/g,
      `${moment().subtract(5, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_6_month_ago}/g,
      `${moment().subtract(6, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_7_month_ago}/g,
      `${moment().subtract(7, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_8_month_ago}/g,
      `${moment().subtract(8, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_9_month_ago}/g,
      `${moment().subtract(9, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_10_month_ago}/g,
      `${moment().subtract(10, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_11_month_ago}/g,
      `${moment().subtract(11, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(
      /{from_12_month_ago}/g,
      `${moment().subtract(12, "month").format("YYYY-MM-DD")}-${today}`
    );
    convertedInput = convertedInput.replace(/{this_year}/g, `${thisyear}`);
    convertedInput = convertedInput.replace(/{first_day_of_year}/g, `${firstday}`);
    convertedInput = convertedInput.replace(/{1_years_ago}/g, `${yearago}`);
    convertedInput = convertedInput.replace(/{2_years_ago}/g, `${moment().subtract(2, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{3_years_ago}/g, `${moment().subtract(3, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{4_years_ago}/g, `${moment().subtract(4, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{5_years_ago}/g, `${moment().subtract(5, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{6_years_ago}/g, `${moment().subtract(6, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{7_years_ago}/g, `${moment().subtract(7, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{8_years_ago}/g, `${moment().subtract(8, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{9_years_ago}/g, `${moment().subtract(9, "year").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{10_years_ago}/g, `${moment().subtract(10, "year").format("YYYY-MM-DD")}`);

    convertedInput = convertedInput.replace(/{1_month_ago}/g, `${moment().subtract(1, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{2_months_ago}/g, `${moment().subtract(2, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{3_months_ago}/g, `${moment().subtract(3, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{4_months_ago}/g, `${moment().subtract(4, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{5_months_ago}/g, `${moment().subtract(5, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{6_months_ago}/g, `${moment().subtract(6, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{7_months_ago}/g, `${moment().subtract(7, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{8_months_ago}/g, `${moment().subtract(8, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(/{9_months_ago}/g, `${moment().subtract(9, "month").format("YYYY-MM-DD")}`);
    convertedInput = convertedInput.replace(
      /{10_months_ago}/g,
      `${moment().subtract(10, "month").format("YYYY-MM-DD")}`
    );
    convertedInput = convertedInput.replace(
      /{11_months_ago}/g,
      `${moment().subtract(11, "month").format("YYYY-MM-DD")}`
    );
    convertedInput = convertedInput.replace(
      /{12_months_ago}/g,
      `${moment().subtract(12, "month").format("YYYY-MM-DD")}`
    );
    return convertedInput;
  }

  static removeMarketCode(symbol) {
    if (symbol.substring(0, 4) === "KRX:") var result = symbol.substring(4);
    else if (symbol.substring(0, 5) === "NICE:") result = symbol.substring(5);
    else result = symbol;

    return result;
  }

  static isListedCompany(symbol) {
    if (symbol && symbol.substring(0, 4) === "KRX:") return true;
    else return false;
  }

  static getLastMarketOpenDay(date) {
    var today = moment(date).format("YYYY-MM-DD");

    var dayPosition = moment(today).isoWeekday();
    if (dayPosition >= 6) {
      //Saturday or Sunday
      today = moment(today)
        .add(5 - dayPosition, "day")
        .format("YYYY-MM-DD");
    }

    return today;
  }

  static get1DayBeforeMarketOpenDay(day) {
    var yesterDay = moment(day).subtract(1, "day").format("YYYY-MM-DD");

    var dayPosition = moment(yesterDay).isoWeekday();
    if (dayPosition >= 6) {
      //Saturday or Sunday
      yesterDay = moment(yesterDay)
        .add(5 - dayPosition, "day")
        .format("YYYY-MM-DD");
    }
    return yesterDay;
  }
  static remove_duplicates(arr) {
    var ret_arr = [];

    for (var i = 0; i < arr.length; i++) {
      if (ret_arr.indexOf(arr[i]) < 0) {
        ret_arr.push(arr[i]);
      }
    }

    return ret_arr;
  }

  static changeQueryPageNumber(input, pageNum) {
    var pageString = "page=" + pageNum;
    if (input) {
      if (input.toString().includes("DocumentSearch")) {
        if (!input.toString().includes("page")) {
          input = input.substring(0, input.length - 1);
          input = input + "," + pageString + ")";
        } else {
          input = input.replace(/page=\d*/, pageString);
        }
      } else {
        if (!input.toString().includes("page")) {
          input = input.substring(0, input.length - 1);
          input = input + "&" + pageString;
        } else {
          input = input.replace(/page=\d*/, pageString);
        }
      }
    }

    return input;
  }

  static removePageNumberFromQuery(input) {
    if (input.toString().includes("DocumentSearch")) {
      if (input.toString().includes("page")) {
        input = input.replace(/page=\d*/, "");
      }
    } else {
      if (input.toString().includes("page")) {
        input = input.replace(/page=\d*/, "");
      }
    }
    return input;
  }

  static arrayToORString(array) {
    var string = "";

    if (array && array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
          string = string + array[i];
        } else {
          string = string + array[i] + " or ";
        }
      }
    }

    return string;
  }

  static arrayToEntityString(array) {
    var result = "";

    for (var i = 0; i < array.length; i++) {
      result = result + "[" + array[i] + "] ";
    }

    return result.trim();
  }

  static arrayToSpaceString(array) {
    var string = "";

    if (array && array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        string = string + " " + array[i];
      }
    }

    return string.trim();
  }

  static arrayToCommaString(array) {
    var string = "None";

    if (array && array.length > 0) {
      string = "[";

      for (var i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
          string = string + '"' + array[i] + '"';
        } else {
          string = string + '"' + array[i] + '",';
        }
      }
      string = string + "]";
    }

    return string;
  }

  static checkArrayNumber(dataArr) {
    for (var i = 0; i < dataArr.length; i++) {
      if (typeof dataArr[i] !== "number") {
        return false;
      }
    }

    return true;
  }

  static isIE() {
    return window.navigator.userAgent.indexOf("Trident") !== -1 && !this.isEdge();
  }

  static isEdge() {
    return window.navigator.userAgent.indexOf("Edge") !== -1;
  }

  static toShortNumbers(numberArr: string, fixedSize = 2) {
    var shortnumbers = [];
    for (var i = 0; i < numberArr.length; i++) {
      shortnumbers.push(this.toShortNumber(numberArr[i], fixedSize));
    }
    return shortnumbers;
  }
  static toShortNumber(number, fixedSize = 2) {
    if (!number) {
      return "-";
    }
    var modifedString: string | number = number * 1; //Number(number);// Math.abs(number);

    if (modifedString >= 1000000000000 || modifedString <= -1000000000000) {
      modifedString = number / 1000000000000;
      modifedString = modifedString.toFixed(fixedSize);
      modifedString = this.numberWithCommas(modifedString);
      modifedString = modifedString + "조";
    } else if (modifedString >= 100000000 || modifedString <= -100000000) {
      modifedString = number / 100000000;
      modifedString = modifedString.toFixed(fixedSize);
      modifedString = this.numberWithCommas(modifedString);
      modifedString = modifedString + "억";
    } else if (modifedString >= 1000000 || modifedString <= -1000000) {
      modifedString = number / 1000000;
      modifedString = modifedString.toFixed(fixedSize);
      modifedString = this.numberWithCommas(modifedString);
      modifedString = modifedString + "백만";
    } else if (modifedString === 0) {
      modifedString = "-";
    } else if (modifedString === null) {
      modifedString = "-";
    } else if (modifedString < 0) {
      modifedString = modifedString.toFixed(fixedSize);
    } else {
      var checkString = modifedString.toFixed(fixedSize).toString();

      if (checkString.slice(-3) === ".00") {
        modifedString = modifedString.toFixed(0);
      } else {
        modifedString = modifedString.toFixed(fixedSize);
      }

      modifedString = this.numberWithCommas(modifedString);
    }

    return modifedString;
  }

  static numberWithCommas(x): string {
    if (x.toString() === "") return "-";

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  static buildDateWithQuarter(year: string, quarter: string, type: QUARTER_TYPE): string {
    return year + "-" + this.quarter_start_option[quarter][type];
  }

  static platform() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/android/.test(userAgent)) {
      // return "Android";
      return "Linux";
    } else if (/ipad/.test(userAgent)) {
      return "iPadOS";
    } else if (/iphone/.test(userAgent)) {
      return "iOS";
    } else if (/linux/.test(userAgent)) {
      return "Linux";
    } else if (/x11/.test(userAgent)) {
      return "Linux";
    } else if (/mac/.test(userAgent)) {
      return "macOS";
    } else if (/win/.test(userAgent)) {
      return "Windows";
    }
    return null;
  }
}
//   static getEntityTag(company_name) {
//     var entityWidth = company_name.toString().length * 10 + 20;
//     entityWidth = entityWidth.toString() + "px";

//     var modifiedName = company_name.toString().substring(0, 10);

//     return (
//       <div
//         key={company_name}
//         style={{
//           marginTop: "10px",
//           display: "inline-block",
//           borderRadius: "4px",
//           backgroundColor: "white",
//           border: "1px solid #4A90E2",
//           width: entityWidth,
//           textAlign: "center",
//           marginRight: "5px",
//         }}
//       >
//         <div style={{ fontSize: "12px", color: "#4A90E2", cursor: "pointer" }}>{modifiedName}</div>
//       </div>
//     );
//   }

//   static isNeedToShow(name) {
//     if (name === "symbol") return false;
//     if (name === "doc_uid") return false;

//     return true;
//   }

//   static isFiscalYearDates(dates) {
//     for (var i = 0; i < dates.length; i++) {
//       if (moment(dates[i], "YYYY-MM-DD").isValid() === true) {
//         var modifiedTime = moment(dates[i]).format("DDHHmm");

//         if (modifiedTime === "310000" || modifiedTime === "300000") {
//         } else {
//           return false;
//         }
//       } else {
//         return false;
//       }
//     }
//     return true;
//   }

//   static convertToFiscalYearDates(dates, removeFy) {
//     var modifiedDates = [];
//     for (var i = 0; i < dates.length; i++) {
//       if (moment(dates[i], "YYYY-MM-DD").isValid() === true) {
//         var modifiedDate = moment(dates[i]).format("YYYY");
//         if (removeFy === undefined || removeFy === false) {
//           modifiedDate = "FY" + modifiedDate;
//         }
//         modifiedDates.push(modifiedDate);
//       }
//     }
//     return modifiedDates;
//   }

//   static isDulicatedData(data) {
//     if (data.length === 1) return false;

//     if (this.checkArrayNumber(data)) {
//       return false;
//     }

//     for (var i = 0; i < data.length; i++) {
//       if (i > 0) {
//         if (data[i] !== data[i - 1]) {
//           return false;
//         }
//       }
//     }

//     return true;
//   }

//   static toCommaNumbers(numberArr) {
//     var results = [];
//     for (var i = 0; i < numberArr.length; i++) {
//       results.push(this.numberWithCommas(numberArr[i]));
//     }
//     return results;
//   }

//   static numberWithCommas(x) {
//     if (x.toString() === "") return "-";

//     var parts = x.toString().split(".");
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     return parts.join(".");
//   }

//   static getLast1YearPeriodString(date) {
//     if (date) {
//       var date_yearago = moment(date).subtract(1, "year").format("YYYY-MM-DD");
//       return date_yearago + "-" + date;
//     } else {
//       var today = moment().format("YYYY-MM-DD");
//       var yearago = moment().subtract(1, "year").format("YYYY-MM-DD");
//       return yearago + "-" + today;
//     }
//   }
//   static toDate(date) {
//     var modifiedDate;

//     if (moment(date, "YYYY-MM-DD").isValid() === true) {
//       // if (Moment(date).isSame(Moment(), 'year')) {
//       //     if (Moment(date).isSame(Moment(), 'day')) {
//       //         modifiedDate = Moment(date).fromNow()
//       //     }
//       //     else {
//       //         modifiedDate = Moment(date).format("MM/DD")
//       //     }
//       // }
//       // else {
//       //     modifiedDate = Moment(date).format("YYYY/MM/DD")
//       // }

//       modifiedDate = moment(date).format("YYYY/MM/DD");

//       return modifiedDate;
//     } else {
//       return "";
//     }
//   }

//   static dateFrom(date, def, pattern) {
//     const $pattern = pattern || "YYYY-MM-DD";
//     if (date && moment(date).isValid()) {
//       return moment(date).format($pattern);
//     }
//     return def ? moment(def).format($pattern) : moment().subtract(1, "year").format($pattern);
//   }

//   static dateTo(date, def, pattern) {
//     const $pattern = pattern || "YYYY-MM-DD";
//     if (date && moment(date).isValid()) {
//       return moment(date).format($pattern);
//     }
//     return def ? moment(def).format($pattern) : moment().format($pattern);
//   }

//   static toIndustryCode(query) {
//     if (query) {
//       const split = query.split(":");
//       let code = query;
//       if (split.length > 1) {
//         code = split[1].substring(2);
//       } else {
//         code = split[0].substring(2);
//       }
//       let pos = code.indexOf("0", 3);
//       if (pos < 0) {
//         pos = code.length;
//       }
//       return code.substring(0, pos);
//     }
//     return query;
//   }
//   static currentTime() {
//     return moment().format("YYYY-MM-DD_HH:mm:ss");
//   }

//   static targetYear(substractCurrent, subtractPrev) {
//     const pivotDay = "02-28";
//     return moment().diff(moment(`${moment().format("YYYY")}-${pivotDay}`), "days") >= 0
//       ? moment().subtract(substractCurrent, "year").format("YYYY")
//       : moment().subtract(subtractPrev, "year").format("YYYY");
//   }

//   static isEnterprise() {
//     return !this.isNice() && !this.isDeepSearch() && !this.isDangtalk();
//   }

//   static isDangtalk() {
//     return Config.App === Config.TYPE.DANGTALK;
//   }

//   static isNice() {
//     return Config.App === Config.TYPE.NICE;
//   }

//   static isDeepSearch() {
//     return Config.App === Config.TYPE.DEEPSEARCH;
//   }
// }
