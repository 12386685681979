import { IPortfolioReturnsPodModel } from "../../../../../../model/compute/IPods";
import _DataPod from "../_DataPod";

export default class _PortfolioReturns extends _DataPod implements IPortfolioReturnsPodModel {
  get entries() {
    if (this.data.content) {
      return this.data.content.entities;
    }
    return [];
  }
  get rebalancingDates() {
    if (this.content) {
      return this.content.rebalancing_dates;
    }
    return [];
  }
  get weightBy() {
    if (this.content) {
      return this.content.weighted_by;
    }
    return "";
  }

  get returns() {
    if (this.content) {
      return {
        return: this.content.returns.data.Return,
        value: this.content.returns.data.Value,
        valueNetOfCost: this.content.returns.data.Value,
        date: this.content.returns.data.date,
      };
    }
    return null;
  }

  get weights() {
    if (this.content) {
      return {
        date: this.content.weights.data.date,
        symbol: this.content.weights.data.symbol,
        weight: this.content.weights.data.weight,
      };
    }
    return null;
  }
}
