import ApplicationModel from "../../../../../../model/auth/application/ApplicationModel";
import ProductPlanModel from "../../../../../../model/auth/billing/item/ProductPlanModel";
import SubscriptionItemModel from "../../../../../../model/auth/billing/item/SubscriptionItemModel";
import SubscriptionModel from "../../../../../../model/auth/billing/SubscriptionModel";
import OrganizationModel from "../../../../../../model/auth/org/OrganizationModel";
import OrganizationUserModel from "../../../../../../model/auth/user/OrganizationUserModel";
import UserModel from "../../../../../../model/auth/user/UserModel";
import {
  IOrganizationMemberListPodModel,
  IOrganizationPodModel,
  IUserListPodModel,
  IUserPodModel,
  IOrganizationListPodModel,
  IPlanPodModel,
  IPlanListPodModel,
  ISubscriptionPodModel,
  ISubscriptionListPodModel,
  ISubscriptionItemPodModel,
  ISubscriptionItemListPodModel,
  IApplicationListPodModel,
  IApplicationPodModel,
} from "../../../../../../model/compute/IPods";

import _DataPod from "../_DataPod";

export class _AuthPod extends _DataPod {}

export class _User extends _AuthPod implements IUserPodModel {
  get user(): UserModel | null {
    if (this.content) {
      return new UserModel(this.content);
    }
    return null;
  }
}

export class _UserStash extends _AuthPod implements IUserPodModel {
  get user(): UserModel | null {
    if (this.content) {
      return new UserModel(this.content);
    }
    return null;
  }
}

export class _UserList extends _AuthPod implements IUserListPodModel {
  get list(): UserModel[] {
    if (this.content && this.content.data) {
      return this.content.data.map((d: any) => new UserModel(d));
    }
    return [];
  }
}

export class _Organization extends _AuthPod implements IOrganizationPodModel {
  get org(): OrganizationModel {
    return new OrganizationModel(this.content);
  }
}

export class _OrganizationList extends _AuthPod implements IOrganizationListPodModel {
  get list(): OrganizationModel[] {
    if (this.content && this.content.data) {
      return this.content.data.map((d: any) => new OrganizationModel(d));
    }
    return [];
  }
}

export class _OrganizationMemeberList extends _AuthPod implements IOrganizationMemberListPodModel {
  get org(): OrganizationModel | null {
    if (this.content && this.content.organization) {
      return new OrganizationModel(this.content.organization);
    }
    return null;
  }

  get members(): OrganizationUserModel[] {
    if (this.content && this.content.members) {
      return this.content.members.map((m: any) => new OrganizationUserModel(m));
    }
    return [];
  }
}

export class _Plan extends _AuthPod implements IPlanPodModel {
  get plan(): ProductPlanModel | null {
    if (this.content) {
      return new ProductPlanModel(this.content);
    }
    return null;
  }
}

export class _PlanList extends _AuthPod implements IPlanListPodModel {
  get list(): ProductPlanModel[] {
    if (this.content && this.content.data) {
      return this.content.data.map((d: any) => new ProductPlanModel(d));
    }
    return [];
  }
}

export class _Subscription extends _AuthPod implements ISubscriptionPodModel {
  get subscription(): SubscriptionModel | null {
    if (this.content) {
      return new SubscriptionModel(this.content);
    }
    return null;
  }
}

export class _SubscriptionList extends _AuthPod implements ISubscriptionListPodModel {
  get list(): SubscriptionModel[] {
    if (this.content && this.content.data) {
      return this.content.data.map((d: any) => new SubscriptionModel(d));
    }
    return [];
  }
}

export class _SubscriptionItem extends _AuthPod implements ISubscriptionItemPodModel {
  get item(): SubscriptionItemModel | null {
    if (this.content) {
      return new SubscriptionItemModel(this.content);
    }
    return null;
  }
}

export class _SubscriptionItemList extends _AuthPod implements ISubscriptionItemListPodModel {
  get list(): SubscriptionItemModel[] {
    if (this.content && this.content.data) {
      return this.content.data.map((d: any) => new SubscriptionItemModel(d));
    }
    return [];
  }
}

export class _AppList extends _AuthPod implements IApplicationListPodModel {
  get list(): ApplicationModel[] {
    if (this.content && this.content.data) {
      return this.content.data.map((d: any) => new ApplicationModel(d));
    }
    return [];
  }
}

export class _App extends _AuthPod implements IApplicationPodModel {
  get app(): ApplicationModel {
    return new ApplicationModel(this.content);
  }
}
